//site component
function NameDateAndAddress(props){
    if(props.type === '') return
    return(
        <div className="higherLine secColOutline">
            <h3 className="smallHeading">Grunddaten (z.T. aus Konto)</h3>
            <b>{(props.type==="naturalPerson")?'Zuname, Vorname':'Eingetragener Name'}</b>
            <span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">angelehnt an: angelehnt an: §14 (2) Nr. 1 / §14 (3) Nr. 1 PStTG</span><br/>
            </div>
            <br/>
            <input className="inputText" value={props.data.name} onChange={(e) => props.updateValue('name', e.target.value)} />
            {(props.type==="naturalPerson")?
            <>
            <b>Geburtsdatum</b>
            <span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">angelehnt an: §14 (2) Nr. 5 PStTG</span><br/>
            </div>
            <br/>
            <input className="inputText inputText_reallyNarrow" type='date' onChange={(e) => props.updateValue('personDateOfBirth', e.target.value)} />
            </>
            :''}<br/>
            <b>Anschrift</b>
            <span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">angelehnt an: §14 (2) Nr. 2 / §14 (3) Nr. 2 PStTG</span><br/>
            </div>
            <br/>
            Straße, Hausnummer <br/>
            <div className="parallel">
                <input className="inputText inputText_narrow" type='text' value={props.data.street} onChange={(e) => props.updateValue('street', e.target.value)}/>
                <div className="spacer"/>
                <input className="inputText number" type='text' value={props.data.number} onChange={(e) => props.updateValue('number', e.target.value)} />
            </div> 
            PLZ, Stadt<br/>
            <div className="parallel">
            <input className="inputText number" type='number' value={props.data.postal} onChange={(e) => props.updateValue('postal', e.target.value)}/>
            <div className="spacer"/>
            <input className="inputText inputText_narrow" type='text' value={props.data.city} onChange={(e) => props.updateValue('city', e.target.value)} />
            </div>
        </div>
    )
}

export default NameDateAndAddress