//functions
function compareDate(serverDate, gmtData){
    var date2 = convertToDate(gmtData)+'T'+convertToTime(gmtData)
    var tdy = new Date(serverDate).toUTCString()
    tdy = tdy.toString()
    tdy = convertToDate(tdy)+'T'+convertToTime(tdy)
    var dec = tdy>date2
    if (tdy===date2) return(true)
    return (dec)
}

function convertToDate(gmtData){
    var date = new Date(gmtData)
    return date.toISOString().substring(0, 10);
}

function convertToGMTDate(date, time){
    date = Date.parse(date+"T"+time)
    return (new Date(date).toISOString())
}

function convertToTime(gmtData){
    var date = new Date(gmtData)
    return date.toLocaleTimeString().slice(0,5)
}

function replaceDateNames(data){
    return [toNormalDateAndTime(data[0]), data[1]]
}

function replacePaymentNames(data){
    for(var i=0;i<data.length;i+=1){
        if(data[i]==='paypal') data[i] = 'PayPal'
        if(data[i]==='transfer') data[i] = 'Überweisung'
        if(data[i]==='cash') data[i] = 'Bar an der Abendkasse'
    }
    return data
}

function toMachineNumber(numberStr){
    numberStr = numberStr.replace('€','').replace(',','.')
    return parseFloat(numberStr).toFixed(2)
}

function toNormalDate(date){
    date = new Date(date)
    return date.toLocaleDateString()
}  

function toNormalDateAndTime(date){
    date = new Date(date)
    var dateStr = date.toLocaleDateString()+', '
    dateStr+= date.toLocaleTimeString().slice(0,5)+' Uhr'
    return dateStr
}  

function toNormalNumber(number){
    if (number == null) return '0,00'
    return parseFloat(number).toFixed(2).replace('.',',')
}

function toSalesPerDay(data){
    var tData = []
    var date = new Date(data[0])
    for(var i=0;i<data.length;i+=1){
        if(i===0) tData[i] = date.toLocaleDateString()
        else tData[i] = Number(data[i])
    }
    return tData
}

export {toMachineNumber, toNormalDateAndTime, toNormalNumber, toNormalDate, convertToGMTDate, convertToDate, convertToTime, compareDate, replacePaymentNames, replaceDateNames, toSalesPerDay}