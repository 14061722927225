import React from "react"
import Switch from '@mui/material/Switch';
import loginToken from "../functionality/loginToken"

//site components
const InfoFree = () => (
    <p>
        Dieses Event wird kostenlos angeboten, Sie können keinen Eintrittspreis verlangen.<br/> 
        Außerdem wird Werbung auf Ihren Veranstaltungsseiten angezeigt, um Systemkosten zu decken.
    </p>
)

const InfoPaid = (props) => (
    <>
    <b>Rabattschlüssel (optional, falls vorhanden)</b><br/><input type='text' className="inputText max20" maxLength='20' onChange={(e) => props.updateData('feeCode', e.target.value)}/>
    <p>
        Dieses Event hat einen Mindestpreis von 0,50€ pro Ticket.<br/>
        Es fallen Systemgebühren pro verkauftem Ticket an. Sie können detaillierte Verkaufsstatistiken einsehen, sowie mehrere Preisklassen anbieten.
    </p>
    </>
)

function FreeChooser(props){
    return(
        <div>
            <h3>
            {(props.paidAvailable === '0')?'':<PaidSwitch updateData={props.updateData} paid={props.paid}/>}
            Dieses Event ist {(props.paid)?'bezahlt':'kostenlos'}</h3>
            {(props.paid)?<InfoPaid updateData={props.updateData}/>:<InfoFree/>}
        </div>
    )
}

function ImageInput(props){
    return(
        <div>
            <h3>Coverbild</h3>
            <input id='imgInp' onChange={(e) => {if (setPreview(e)) props.updateData('coverImage',image)}} className='pictureInput' type='file' accept='image/*'/>
            <div className='eventPictureBox'>
                <div className='blurLeft'/>
                <div className='blurRight'/>
                <img id='picturePreview' className='picturePreview' src="./images/coverplaceholder.png" alt='preview' onChange={() => resizeImage()}/>
                <div className='previewBox'><span className='previewText'>Später sichtbarer Teil</span></div>
            </div>
        </div>
    )
}

function NextBtn(props){
    return(
        <div>
            <center>
                <br/>
                <div id='submiterror' className="errorInvisible"/><br/><br/>
                <a href='newEvent?step=2' onClick={(e) => {if(commitBaseData(e)) props.nextStep(1)}} className="btnClass1 normalPropotions">Nächster Schritt</a>
            </center>
            <br/>
        </div>
    )
}

function PaidSwitch(props){
    return(
        <Switch defaultChecked  onChange={(e) => props.updateData('paid')}/>
    )
}

//variables
var image = './images/coverplaceholder.png'

//functions called via user actions
function checkCompletion(element, element2){
    var e = false
    if(element.value === ''){
        e = true
        document.getElementById('titleError').classList.add('errorVisible')
        element.classList.add('redMark')
    } else {
        document.getElementById('titleError').classList.remove('errorVisible')
        element.classList.remove('redMark')
    }

    if(element2.value === ''){
        e = true
        document.getElementById('descriptionError').classList.add('errorVisible')
        element2.classList.add('redMark')
    } else {
        document.getElementById('descriptionError').classList.remove('errorVisible')
        element2.classList.remove('redMark')
    }
    return e
}

function commitBaseData(event){
    event.preventDefault()
    var element = document.getElementById('title')
    var element2 = document.getElementById('description')
    if (checkCompletion(element, element2)){return}

    writeImageCache()
    return true
}

function getWidthAndHeight(){
    var picPrev = document.getElementById('picturePreview')
    var originalWidth = picPrev.naturalWidth
    var originalHeight = picPrev.naturalHeight
    var aspectRatio = originalWidth/originalHeight
    var newHeight = 600
    var newWidth = newHeight * aspectRatio

    return [newHeight,newWidth]
}

function resizeImage(){
    var dimensions = getWidthAndHeight()
    var canvas = document.createElement("canvas");
    canvas.width = dimensions[1];
    canvas.height = dimensions[0];
    var ctx = canvas.getContext("2d");
    var img = document.getElementById('picturePreview')

    ctx.drawImage(img, 0, 0, dimensions[1], dimensions[0]);

    var dataurl = canvas.toDataURL(img.type);
    img.src = dataurl
}

function cropImage(){

    if(image === './images/coverplaceholder.png'){
        return image
    }

    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var img = document.getElementById('picturePreview')
    var sy = 0
    var sx = ((getWidthAndHeight()[1]/2)-200)
    var croppedWidth = 400
    var croppedHeight = 600
    canvas.width = croppedWidth;
    canvas.height = croppedHeight;

    ctx.drawImage(img, sx, sy, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);
    var dataurl = canvas.toDataURL(img.type);
    return dataurl
}

function setPreview(event){
    event.preventDefault();

    var picturePreview = document.getElementById('picturePreview')
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
        // convert image file to base64 string
        picturePreview.src = reader.result;
        image = reader.result;
      }, false);
    
      if (file) {
        reader.readAsDataURL(file)
      } else {
        picturePreview.src = './images/coverplaceholder.png'
        image = './images/coverplaceholder.png'
      }

      return true
}

async function writeImageCache(){
    try{
        await fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/addImageCache', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                cover: cropImage(),
                ticket: '',
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var element = document.getElementById('submiterror')
            if (text !== "True"){
                element.textContent = text
                element.classList.add('errorVisible')
                element.textContent = "Fehler bei der Datenübermittlung!"
            } else {
                element.classList.remove('errorVisible')
            }
        })
    } catch (err){
        var element = document.getElementById('submiterror')
        element.classList.add('errorVisible')
        element.classList.remove('errorInvisible')
    }
}

//class definition
class EventBaseData extends React.Component{
    super(){}
    render(){
        return(
            <>
                <h3>Eventname</h3>
                <input id='title' className='inputText' type='text' placeholder='Eventname hier eintragen (maximal 100 Zeichen)' maxLength='100' onChange={(e) => this.props.updateBaseData('title',e.target.value)}/>
                <div id='titleError' className="errorInvisible">Der Eventtitel darf nicht leer sein!</div>
                <h3>Beschreibung</h3>
                <textarea id='description' className='inputText' type='text' placeholder='Beschreiben Sie das Event in ein paar Sätzen (maximal 1000 Zeichen)' maxLength='1000' rows='7' onChange={(e) => this.props.updateBaseData('description',e.target.value)}/>
                <div id='descriptionError' className="errorInvisible">Die Beschreibung darf nicht leer sein!</div>
                <FreeChooser updateData={this.props.updateBaseData} paid={this.props.paid} paidAvailable={this.props.paidAvailable}/>
                <ImageInput updateData={this.props.updateData} />
                <NextBtn nextStep={this.props.nextStep}/>
            </>
        )
    }
}

export default EventBaseData