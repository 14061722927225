import { toNormalDateAndTime, toNormalNumber } from '../functionality/normalizer';
import { compareDate } from '../functionality/normalizer';

//functions
function paymentMethod(method){
    if(method === 'paypal') return "bezahlt über PayPal"
    if(method === 'cash') return "Bar an der Abendkasse zu zahlen"
}

//site components
const TicketItems= ({data, clicky}) =>(
    <>
    {data.map(data => (
        <>
        {(clicky)?
            <a className='paneText eventPane noUnderline' href={'/ticket?b='+data[10]}>
                <div>
                    <h3>{data[3]}</h3>
                    {(data[0]===0)? <><span className='errorVisible'>Noch nicht bezahlt.</span><br/> Preis: {toNormalNumber(data[2])} €<br/>{paymentMethod(data[1])}</>: toNormalNumber(data[2])+' € '+ paymentMethod(data[1])}<br/>
                    <div className="centerHeight"><p><span className="icon calenderIcon">Datum,Uhrzeit</span> {toNormalDateAndTime(data[4])} -- gekauft am: {toNormalDateAndTime(data[5])} </p></div><br/>
                    <div className="centerHeight"><span className="icon pointIcon">Ort </span> {data[8]+' '+data[9]+', '+data[6]+' '+data[7]}</div><br/><br/>
                </div>
            </a>
            :
            <div className='paneText eventPane noUnderline'>
                <div>
                    <h3>{data[3]}</h3>
                    {(data[0]===0)? <><span className='errorVisible'>Noch nicht bezahlt.</span><br/> Preis: {toNormalNumber(data[2])} €<br/>{paymentMethod(data[1])}</>: toNormalNumber(data[2])+' € '+ paymentMethod(data[1])}<br/>
                    <div className="centerHeight"><p><span className="icon calenderIcon">Datum,Uhrzeit</span> {toNormalDateAndTime(data[4])} -- gekauft am: {toNormalDateAndTime(data[5])} </p></div><br/>
                    <div className="centerHeight"><span className="icon pointIcon">Ort </span> {data[8]+' '+data[9]+', '+data[6]+' '+data[7]}</div><br/><br/>
                </div>
            </div>}
        <br/>
        </>
    ))}
    </>
)

function ProfileUserOverview(props){
    var bor = getPastBorder(props.data)

    return(
        <div className='positionOverlap' id='profileOverview'>
            <center><h3 id='ticketTitle'>Aktuelle Tickets</h3></center>
            <TicketItems clicky={true} data={props.data.slice(bor)}/>
            <center><h3 id='ticketTitle'>Ältere Tickets</h3></center>
            <TicketItems clicky={false} data={props.data.slice(0,bor).reverse()}/>
        </div>
    )
}

//functionality
function getPastBorder(data){
    for(var i = 0; i<data.length; i+=1){
        if(compareDate(data[i][4],new Date().toISOString())) return i
    }
}

export default ProfileUserOverview