import React from "react"
import loginToken from '../functionality/loginToken'
import {toNormalNumber} from '../functionality/normalizer';

//site components
const PricData = ({data, reloadState, paidAvailable, fees}) => (
    <>
    {data.map(data => (
        <VEventPrice fees={fees} reloadState={reloadState} data={data} key={data[1]} paidAvailable={paidAvailable}/>
    ))}
    </>
)

function EventPriceForm(props){
    if(!props.data.editable){
        return <button className="addEventPriceButton"><div className="button-viewEventBG plusStyle" onClick={() => props.edit()}/><span className="plusStyle">+</span></button>
    }else{
        return(
            <VEventPriceViewer data={props.data} toggle={props.edit} commit={props.commit} alterData={props.alter} paidAvailable={props.paidAvailable}/>
        )
    }
}

function PricDatEdit(props){
    
    return(
        <>  
        <div className="relPos">
            <input type='number' defaultValue={toNormalNumber(props.data.price).replace(',','.')} className="vEvInp numSmall" min={(Number(props.paidAvailable)===1)?'0.5':'0.0'} step='0.01' onBlur={(e) => {(Number(props.paidAvailable)===1)?checkPrice(e):console.log()}} onChange={(e) => props.alterData(e.target.value, 'price')} disabled={(Number(props.paidAvailable)===1)?false:true}/><br/>
            <PriceCalculator fees={props.fees} price={props.data.price}/>
        </div>
        <input type='number' defaultValue={props.data.capacity} className="vEvInp numSmall" min={props.data.sold} step='1'onChange={(e) => props.alterData(e.target.value, 'capacity')}/><br/>
        <span className="centerHeight">{props.data.sold}</span><br/>
        <span className="smallText errorVisible">Preisänderungen haben<br/>keine Auswirkungen auf<br/>bereits verkaufte Tickets!</span>
        </>
    )
}

function PricDatView(props){
    return(
        <>
        {toNormalNumber(props.data.price)} €<br/>
        {props.data.capacity}<br/>
        {props.data.sold}
        </>
    )
}

function PriceCalculator(props){
    return(
        <div className="absPos vEvAbs">
            Sie erhalten {toNormalNumber(calculatePrice(props.price, props.fees))}€ pro Ticket
        </div>
    )
}

function EditPriceClassNum(props){
    return(
        <input className="vEvInp" placeholder="Neue Preisklasse" defaultValue={(props.pc!=='')?props.pc:"Neue Preisklasse"} onChange={(e) => props.alterData(e.target.value, 'priceclassNumber')}/>
    )
}

function VEventPriceViewer(props){
   
    return(
        <>
            <b className="centerHeight">
                {(props.data.editable)?<EditPriceClassNum pc={props.data.priceclassNumber} alterData={props.alterData}/>:props.data.priceclassNumber}
                <span className="btnClass3 eventIconWrapper">{(props.data.editable)?<><span><span onClick={(e) => {props.toggle(); props.commit()}} className="icon tickIcon iconGreenCol">Speichern</span><span className="icon cancelIcon iconWarningCol" onClick={(e) => {props.toggle()}}>Abbrechen </span>{(props.data.sold === 0)?<span className="icon deleteIcon iconWarningCol" onClick={(e) => {props.deletePC(props.data.pcid)}}>Preisklasse_löschen</span>:''}</span></>:<span onClick={(e) => {props.toggle();}} className="icon editIcon iconAccentCol">Bearbeiten </span>}</span>
            </b>
            <div className="parallel">
                <div className={(props.data.editable)?'minTwoEm vEventMarg':'vEventMarg'}>
                    - Preis:<br/>
                    - Kapazität:<br/>
                    - Bereits verkauft:
                </div>
                <div className="vEventMarg">
                    {(props.data.editable)?<PricDatEdit fees={props.fees} data={props.data} alterData={props.alterData} paidAvailable={props.paidAvailable}/>:<PricDatView data={props.data}/>}
                </div>
            </div>
        </>
    )
}

//functions
function calculatePrice(pri, fees){
    if(!fees) return
    if(pri === '0') return 0
    if (pri <= fees[0]) return (((pri/1.025))-fees[1])
    else return (((pri/1.025))-fees[2])
}

function checkPrice(e){
    var val = e.target.value
    if (val<0.5){
        e.target.value = 0.50
    }
}

//class definitions
class EventPriceAdd extends React.Component{
    constructor(props){
        super()
        this.state = {
            pid: props.pid,
            priceclassNumber: '',
            price: 0.00,
            capacity: 1,
            sold: 0,
            editable: false,
        }
        this.alterData = this.alterData.bind(this)
        this.commitChanges = this.commitChanges.bind(this)
        this.toggleViewerEdit = this.toggleViewerEdit.bind(this)
    }

    alterData(e,tar){
        this.setState({
            [tar]: e,
        })
    }

    commitChanges(){
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/priceclasstoexistingevent', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                pid: this.state.pid,
                pcNum: this.state.priceclassNumber,
                price: this.state.price,
                capacity: this.state.capacity,
                hostReceives: calculatePrice(this.state.price,this.props.fees)
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {this.props.reloadState()})
    }

    toggleViewerEdit(){
        this.setState({
            editable: !this.state.editable
        })
    }

    render(){
        return <EventPriceForm data={this.state} alter={this.alterData} edit={this.toggleViewerEdit} commit={this.commitChanges} paidAvailable={this.props.paidAvailable}/>
    }
}

class VEventPrice extends React.Component{
    constructor(props){
        super()
        this.state = {
            pcid: props.data[1],
            priceclassNumber: props.data[2],
            price: props.data[3],
            capacity: props.data[4], 
            sold: props.data[5],
            changesMade: false,
            fees: props.fees,
        }
        this.alterData = this.alterData.bind(this)
        this.commitChanges = this.commitChanges.bind(this)
        this.deletePriceclass = this.deletePriceclass.bind(this)
        this.toggleViewerEdit = this.toggleViewerEdit.bind(this)
    }

    alterData(e,tar){
        this.setState({
            [tar]: e,
            changesMade: true,
        })
    }

    commitChanges(){
        if (!this.state.changesMade) return
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/updateeventpricingdata', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                PCID: this.state.pcid,
                capacity: this.state.capacity,
                price: this.state.price,
                priceclassNumber: this.state.priceclassNumber,
                hostReceives: calculatePrice(this.state.price,this.props.fees),
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {
            this.setState({
                changesMade: false
            })
        })
        .then(() => {this.props.reloadState()})
    }

    deletePriceclass(pcid){
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/deleteeventpricingdata', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                PCID: pcid,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {this.props.reloadState()})
    }

    toggleViewerEdit(){
        this.setState({
            editable: !this.state.editable
        })
    }

    render(){
        return <VEventPriceViewer fees={this.props.fees} paidAvailable={this.props.paidAvailable} data={this.state} alterData={this.alterData} commit={this.commitChanges} deletePC={this.deletePriceclass} toggle={this.toggleViewerEdit}/>
    }
}

export {PricData, EventPriceAdd}