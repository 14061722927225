import React from 'react'
import loginToken from '../functionality/loginToken'
import './Profile.css'
import { useSearchParams } from 'react-router-dom';
import ProfileUserOverview from './UserProfile';
import ProfileHostOverview from './HostProfile';
import BottomBar from './BottomBar';
import MiniHeader from './MiniHeader';
import Address from './Address';
import EventStatistics from './EventStatistics';
import LoadingAnimation from '../loading_components/LoadingAnimation';

//starting function
function Profile(){
    const [searchParams] = useSearchParams();
    return(
        <div>
            <Prof search={searchParams.get("c")}/>
        </div>
    ) 
}

//site components
function ContentContainer(props){
    return(
        <div>
            <BottomBar type={props.type} LoadAddress={LoadAddress} LoadTickets={LoadTickets} />
            {(props.type === 'hid')?(props.baseDat[8]===1)?'':<PaidAvailableNotification/>:''}
            <MiniHeader type={props.type} baseDat={props.baseDat} />
            <center><h2 id='title'>Mein Konto</h2></center>
            <div id='profileContent' className='content centered'>
                {(props.type === 'uid')?<ProfileUserOverview data={props.data}/>:<ProfileHostOverview data={props.data} toggleStatistics={props.toggleStatistics}/>}
                {(props.showStatistics)?<EventStatistics eid={props.statisticsEID} title={props.title} toggleStatistics={props.toggleStatistics}/>:''}
                <Address baseDat={props.baseDat}/>
            </div>
        </div>
    )
}

function PaidAvailableNotification(){
    return(
        <div className='profileNotPaid'>
            <center>
                Sie sind noch nicht für bezahlte Events freigeschaltet.<br/>
                <a href='/registerForPaid' className='btnClass3'>Hier freischalten</a> 
            </center>
        </div>
    )
}

//class definition
class Prof extends React.Component{
    constructor(){
        super()
        this.state={
            type: '',
            baseData: [],
            data: [],
            showStatistics: false,
            statisticsEID: 0,
            title: '',
        }
        this.toggleStatistics = this.toggleStatistics.bind(this)
    }

    async componentDidMount(){
        await fetch(process.env.REACT_APP_PROFILEHANDLER_API+process.env.REACT_APP_STAGE+'/profile', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken()
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            var obj = JSON.parse(text);
            this.setState({
                type: obj[0],
                baseData: obj[1],
                data: obj.slice(2)
            })
        })
        .then (() => {
            if (this.props.search === 'events') LoadTickets('',this.state.type)
        })
    }

    toggleStatistics(eid, title){
        this.setState({
            showStatistics: !this.state.showStatistics,
            statisticsEID: eid,
            title: title,
        })
    }

    render(){
        if(this.state.type !== '') return <ContentContainer toggleStatistics={this.toggleStatistics} title={this.state.title} showStatistics={this.state.showStatistics} statisticsEID={this.state.statisticsEID} baseDat={this.state.baseData} data={this.state.data} type={this.state.type}/>
        else return <LoadingAnimation/>
    }
}

//functions called via user actions
function LoadAddress(e){
    e.preventDefault()
    document.getElementById('title').textContent = "Anschrift"
    var profElem = document.getElementById('profileOverview')
    profElem.classList.add('profileInvisible')
    profElem.classList.remove('profileVisible')
    profElem.style.zIndex = (1)
    var addrElem =  document.getElementById('address')
    addrElem.classList.remove('profileInvisible')
    addrElem.classList.add('profileVisible')
    addrElem.style.zIndex = (10)
}

function LoadTickets(e, type){
    e.preventDefault()
    try {
        if (type === 'uid')
            document.getElementById('title').textContent = "Meine Tickets"
        else
            document.getElementById('title').textContent = "Meine Events"
        var profElem = document.getElementById('profileOverview')
        profElem.classList.add('profileVisible')
        profElem.classList.remove('profileInvisible')
        profElem.style.zIndex = (10)
        var addrElem =  document.getElementById('address')
        addrElem.classList.add('profileInvisible')
        addrElem.classList.remove('profileVisible')
        addrElem.style.zIndex = (1)
    } catch (error) {}  
}

export default Profile