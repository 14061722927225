import React from 'react';
import './Nav.css';
import loginToken from '../functionality/loginToken'

//starting function
function Navigation(){
    return(
        <nav>
            <div className="navbar">
                <div className="navSlave">
                    <Logo/>
                    <NavElements topClassName="navElements"/>
                </div>
                <div className="navSlave">
                    <SearchContainer/>
                    <MenuToggle/>
                    <Login />
                </div>
            </div>
        </nav>
    )
}

//site components
function AdminPopUp(){
    return(
        <div className="profilePopUp">
            <NavBtn target="/admfunctions" text="Admin Dashboard"/>
            <LogOffBtn/>
        </div>
    )
}

function HostPopUp(){
    return(
    <div className="profilePopUp">
        <NavBtn target="/myProfile" text="Mein Konto"/>
        <NavBtn target="/myProfile?c=events" text="Meine Events"/>
        <NavBtn target="/newEvent?step=1" text="Neues Event"/>
        <LogOffBtn/>
    </div>
    )
}

function Login(){
    return(
        <div className="navItem collapsable">
            <input type="checkbox" className="profileToggle"/>
            <ProfileIcon/>
            <ProfilePopUp/>
        </div>
    )
}

function Logo(){
    return(
        <a href="/">
            <div className="logoBox">
                <picture>
                    <source srcSet="images/skalar_light.svg" media="(prefers-color-scheme:dark)"/>
                    <img src="images/skalar_dark.svg" alt="Skalar-Startseite" className="logo" id="logo" draggable={false}/>
                </picture>
            </div>
        </a>
    )
}

function LogOffBtn(){
    return(
        <a className='centered silentLink' onClick={logOff}>
            <div className="navItem smallText">
                Ausloggen
            </div>
        </a>
    )
}

function MenuToggle(){
    return(
        <div className="navItem menu">
            <input type="checkbox" className="menuToggle"/>
            <span className="material-symbols-outlined">menu</span>
            <NavElements topClassName="navElements2"/>
        </div>
    )
}

function NavBtn(props){
    return(
        <a href={props.target} className="silentLink">
            <div className="navItem">
                {props.text}
            </div>
        </a>
    )
}
function NavElements(props){
    return(
        <div className={props.topClassName}>
            <NavBtn target="/allEvents?s=1" text="Alle Veranstaltungen"/>
            <NavBtn target="/allEvents?s=1&ord=near" text="In meiner Nähe"/>
        </div>
    )
}

function ProfileIcon(){
    return(
        <span className="material-symbols-outlined">
            person
        </span>
    )
}

function ProfilePopUp(){
    const token = loginToken.GetToken()
    if(!token){
        return(
            <div className="profilePopUp">
                <center><h4>Anmelden</h4></center>
                <LogInForm/>
                <hr className="accentCol" />
                <center>
                    <p>Noch keinen Account?</p>
                </center>
                <a href="/register">
                    <button className="btnClass3">Hier registrieren</button>
                </a><br/>
                <hr className="accentCol"/>
                <a href="/requestpwreset">
                    <button className="btnClass3">Passwort vergessen</button>
                </a>
            </div>
        )
    } else {
        if (token.toLowerCase().indexOf("1s4df1e4suid" )!== -1){
            return (
                <UserPopUp/>
            )
        } else if (token.toLowerCase().indexOf("3s56df52whid" )!== -1){
            return (
                <HostPopUp/>
            )
        } else if (token.toLowerCase().indexOf("smdjs52d8sdwaid" )!== -1){
            return (
                <AdminPopUp/>
            )
        }
    }
}

function SearchContainer(){
    return(
        <div className="searchContainer">
            <input id='searchBox' type="text" placeholder="Suche nach Veranstaltungen" className="searchBox" onKeyUp={(e) => {if (e.key === 'Enter'){search()}}}/>
            <button className="searchBtn"><span className="material-symbols-outlined" onClick={() => search()}>search</span></button>
        </div>
    )
}

function UserPopUp(){
    return (
        <div className="profilePopUp">
            <NavBtn target="/myProfile" text="Mein Konto"/>
            <NavBtn target="/myProfile?c=tickets" text="Meine Tickets"/>
            <LogOffBtn/>
        </div>
    )
}

//class definitions
class LogInForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            pw: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });

    }

    async handleSubmit(event) {
        event.preventDefault();
        var element = document.getElementById('error')
        if (this.state.email!=='' && this.state.pw!==''){
            try{
                await fetch(process.env.REACT_APP_LOGINHANDLER_API+process.env.REACT_APP_STAGE+'/authenticate', {
                    method: 'POST',
                    body: JSON.stringify({
                        mail: this.state.email,
                        pw: this.state.pw
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then (resp => resp.text())
                .then(text => {   
                    if (text.toLowerCase().indexOf("uid" )=== -1 && text.toLowerCase().indexOf("hid") === -1 && text.toLowerCase().indexOf("aid") === -1){
                        element.textContent = "E-Mail oder Passwort falsch!"
                    } else {
                        element.textContent = ""
                        loginToken.SetToken(text)
                        window.location.reload(false)
                    }
                })
            } catch (err){
            }
        } else {
            element.textContent = "Alle Felder müssen befüllt werden!"
        }
            
    }

    render (){
        return(
            <form onSubmit={this.handleSubmit}>
                <input type="text" placeholder="E-Mail" name="email" className="signInField" value={this.state.value} onChange={this.handleInputChange}/><br/><br/>
                <input type="password" placeholder="Passwort" name="pw" className="signInField" value={this.state.value} onChange={this.handleInputChange}/><br/><br/>
                <input type="submit" value="Anmelden" className="btnClass1"/>
                <p id='error' className='errorVisible'></p>
            </form>
        )
    }
}

//functions called via user actions
function logOff(){
    loginToken.DeleteToken()
    window.location.reload(false)
}

function search(){
    var elem = document.getElementById('searchBox')
    window.location = 'allEvents?s=1&search='+elem.value
}

export default Navigation;