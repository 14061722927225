import React from "react"
import loginToken from "../functionality/loginToken"

//site components
function MiniHeader(props){
    return(
        <div className='content'>
            <div className="parallel leftAndRight">
                <div>
                    Name: {props.baseDat[1]+((props.type==='uid')?' ':' - ')+props.baseDat[2]}<br/>
                    <div className='parallel'>
                        E-Mail: {props.baseDat[0]}
                        <a href='/editOwnData'>
                            <button className='btnClass3'>bearbeiten</button>
                        </a>
                    </div>
                </div>
                {(props.type==='uid')?'':<ValidatorController hostName={props.baseDat[0]} valName={props.baseDat[7]} />}
            </div>
        </div>
    )
}

function NewValBtn(props){
    return <button className="btnClass2 smallBtn" onClick={() => props.setEditable()}>Validierer anlegen</button>
}

function Validator(props){
    return(
        <>
        <div>
            {props.valName}_iAv:{(props.hostName).replaceAll(' ','')}<br/>
            versteckt <br/><span className="smallText"> Sie können es aber jederzeit<br/>(ohne Eingabe des Alten) neu setzen</span>
        </div>
        <div>
            <span onClick={(e) => {props.toggleEditable();}} className="icon editIcon iconAccentCol">Bearbeiten </span>
        </div>
        </>
    )
}

function ValidatorEdit(props){
    return(
        <div>
            <span><input type='text' value={props.valName} maxLength={60} placeholder='Musteranmeldung' className="inputText" onChange={(e) => props.updateState('valName', (e.target.value).replace('_iAv:',''))}/>_iAv:{(props.hostName).replaceAll(' ','')}</span><br/>
            <input type='text' value={props.pw} placeholder='Passwort' className="inputText" onChange={(e) => props.updateState('password', e.target.value)}/><br/>
            {(props.pw!=='')?<span className="btnClass3 eventIconWrapper"><><span><span onClick={(e) => {props.toggle(); props.commit()}} className="icon tickIcon iconGreenCol">Speichern</span></span></></span>:''}
        </div>
    )
}

//class definition
class ValidatorController extends React.Component{
    constructor(props){
        super()
        this.state={
            valName:props.valName,
            password: '',
            editable: false,
            hostName: props.hostName,
        }
        this.commit = this.commit.bind(this)
        this.updateState = this.updateState.bind(this)
        this.toggleEditable = this.toggleEditable.bind(this)
    }

    commit(){
        fetch(process.env.REACT_APP_PROFILEHANDLER_API+process.env.REACT_APP_STAGE+'/createvalidator', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                password: this.state.password,
                name:this.state.valName,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
    }

    updateState(tar,val){
        this.setState({
            [tar]:val
        })
    }

    toggleEditable(){
        this.setState({
            editable: !this.state.editable
        })
    }

    render(){
        return(
            <>
            <div className="parallel">
            <b>Validierer Account:</b>
            <div className="spacer"/>
                <div>
                    {(this.state.editable)?'':(this.state.valName !== null)?
                            <span>Anmeldename:<br/>
                            Passwort:</span>
                        :
                            <span>Sie haben noch keinen<br/>
                            Account angelegt</span>
                    }
                </div>
                <div className="spacer"/>
                {(this.state.valName !== null || this.state.editable)?'':<NewValBtn setEditable={this.toggleEditable} />}
                {(this.state.editable)?<ValidatorEdit hostName={this.state.hostName} toggle={this.toggleEditable} commit={this.commit} updateState={this.updateState} valName={this.state.valName} pw={this.state.password} />:(this.state.valName !== null)?<Validator hostName={this.state.hostName} toggleEditable={this.toggleEditable} valName={this.state.valName}/>:''}
            </div>
            <div/>
            </>
        )
    }
}

export default MiniHeader