//site component
function TypeSelector(props){
    return(
        <div className="higherLine secColOutline" onChange={(e) => props.updateValue('type',e.target.value)}>
            <h3 className="smallHeading">Ich bin/vertrete:</h3>
            <input type='radio' value='naturalPerson' name="type"/>eine natürliche Person.<span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">angelehnt an: §1 BGB</span><br/>
                Natürliche Person ist jeder Mensch.
            </div>
            <br/>
            <input type='radio' value='rightOwner' name="type"/>einen Rechtsträger.<span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">aus: §6 (1) PStTG</span><br/>
                Rechtsträger ist eine juristische Person, eine Personenvereinigung oder eine Vermögensmasse. 
            </div>
            <br/>
            <input type='radio' value='freedProvider' name="type"/>einen freigestellten Anbieter im Sinne von §4 (5) PStTG. (außer: §4 (5) Nr. 4 PStTG)<span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">angeleht an: §4 (5) PStTG</span><br/>
                Freigestellter Anbieter ist jeder, der:
                <ul>
                    <li>Nr.1: staatlicher Rechtsträger ist</li>
                    <li>Nr.2: Rechtsträger ist, dessen Aktien regelmäßig an einer anerkannten Wertpapierbörse gehandelt werden,<br/> oder ein verbundener Rechtsträger eines Rechtsträgers ist, dessen Aktien<br/> regelmäßig an einer anerkannten Wertpapierbörse gehandelt werden</li>
                    <li>[Nr.3: bezieht sich nur auf Immobilien und entfällt daher]</li>
                    <li>[Nr.4: weniger als 30 Ticktes pro Jahr verkauft und mit diesen weniger als 2.000,00€ umsetzt]</li>
                </ul>
                <i>§4 (5) Nr. 4 PStTG wird automatisch durch unsere Systeme geprüft</i>
            </div>
            <br/> 
        </div>
    )
}

export default TypeSelector