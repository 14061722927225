import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalButton = ({ amount, onSuccess }) => {
    const paypalOptions = {
        'client-id': process.env.REACT_APP_PAYPAL_CLIENTID,
        currency: "EUR", // Set currency to Euros
    };

    const buttonStyles = {
        layout: "vertical",
        color: "gold",
        shape: "rect",
        label: "paypal",
    };

    const handleSuccess = (details, data) => {
        onSuccess(details, data);
    };

    return (
        <PayPalScriptProvider options={paypalOptions}>
            <PayPalButtons style={buttonStyles} createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: "EUR",
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }} onApprove={handleSuccess} />
        </PayPalScriptProvider>
    );
};

export default PayPalButton;
