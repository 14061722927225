import React from "react";
import { useSearchParams } from "react-router-dom";

//starting function
function ResetPassword(){
    const [searchParams] = useSearchParams();

    return(
        <div className="content centered">
            <ResetPasswordClass code={searchParams.get('c')} mail={searchParams.get('mail')} type={searchParams.get('type')}/>
        </div>
    )
}

//class definition
class ResetPasswordClass extends React.Component{
    constructor(props){
        super()
        this.state = {
            pw1: '',
            pw2: '',
            error1: true,
            error2: true,
            code: props.code,
            mail: props.mail,
            type: props.type,
            error: ''
        }
        this.checkPW = this.checkPW.bind(this)
        this.checkPwCompletion = this.checkPwCompletion.bind(this)
        this.send = this.send.bind(this)
        this.updateState = this.updateState.bind(this)
    }

    checkPW(){
        var elemnt = document.getElementById('pwErr')
        var elemnt2 = document.getElementById('pws')
        if (this.state.pw1 !== this.state.pw2){
            elemnt.classList.add("errorVisible")
            elemnt.classList.remove("errorInvisible")
            elemnt2.style.color = 'red'
            this.setState({
                error1: true,
            })
        } else {
            elemnt.classList.remove("errorVisible")
            elemnt.classList.add("errorInvisible")
            elemnt2.style.color = ''
            this.setState({
                error1: false,
            })
        }
    }

    checkPwCompletion(){
        if (this.state.pw1 !== ''){
            const re = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*'§/=+-?_:.,<>])(?=.{8,})")
            var elemnt = document.getElementById('PwRequirements')
            var elemnt2 = document.getElementById('pws')
            if (!re.test(this.state.pw1)){
                elemnt.classList.add("errorVisible")
                elemnt.classList.remove("errorInvisible")
                elemnt2.style.color = 'red'
                this.setState({
                    error2: true,
                })
            } else {
                elemnt.classList.remove("errorVisible")
                elemnt.classList.add("errorInvisible")
                elemnt2.style.color = ''
                this.setState({
                    error2: false,
                })
            }    
        } else {
            this.setState({
                error2: true,
            })
        }
    }

    send(){
        fetch(process.env.REACT_APP_PASSWORDHANDLER_API+process.env.REACT_APP_STAGE+'/changepw', {
            method: 'POST',
            body: JSON.stringify({
                pw: this.state.pw1,
                code: this.state.code,
                mail: this.state.mail,
                type: this.state.type
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            this.setState({
                error: text
            })
        })
    }

    updateState(tar, val){
        this.setState({
            [tar]: val
        })
    }

    render(){
        return(
            <>
            <h1>Passwort neu setzen</h1>
            <div id='pws' className='pwContainer'>
                Passwort <br/><input className='inputText pw1' name="pw" type="password" value={this.state.value} onChange={(e) => this.updateState('pw1', e.target.value)} onBlur={() => this.checkPwCompletion()}></input>
                <div className="requirementsPopUp">Passwörter müssen mindestens folgende Anforderungen erfüllen:
                    <ul>
                        <li>min. 8 Zeichen</li>
                        <li>min. 1 Großbuchstabe</li>
                        <li>min. 1 Kleinbuchstabe</li>
                        <li>min. 1 Zahl</li>
                        <li>min. 1 Sonderzeichen</li>
                    </ul>
                </div>
                <p id='PwRequirements' className='errorInvisible'>Das Passwort erfüllt nicht die Anforderungen!</p>
                <br/><br/> 
                Passwort bestätigen <br/><input className='inputText' name="pw2" type="password" value={this.state.value} onChange={(e) => this.updateState('pw2', e.target.value)} onBlur={() =>  this.checkPW()}></input>
                <p id='pwErr' className='errorInvisible'>Die Passwörter müssen übereinstimmen!</p>
                <br/><br/>
                <div className="errorVisible">{this.state.error}</div>
                <center>
                    <button className="btnClass1 normalPropotions" disabled={(this.state.pw1 && this.state.pw2)?false:true} onClick={() => this.send()} >Passwort ändern</button>
                </center>
            </div>
            </>
        )
    }
}

export default ResetPassword