import React from "react"

//site Components
function ProgressIndicator(props){
    return(
        <div className='progressIndicator'>
            <div className='progressIndicatorBG'>
                <ProgItems title="1. Grunddaten" id={0} step={props.step} allStep={props.allStep} setStep={props.setStep}/>
                <ProgItems title="2. Eintrittskarten" id={1} step={props.step} allStep={props.allStep} setStep={props.setStep}/>
                <ProgItems title="3. Überprüfen" id={2} step={props.step} allStep={props.allStep} setStep={props.setStep}/>
            </div>
        </div>
    )
}

function ProgItems(props){
    return(
        <a className={((props.step).toString() === props.id.toString())?'progressItem btnClass3 noUnderline progressIndicatorYellow': ((props.allStep[Number(props.id)]).toString() === 't')?'progressItem btnClass3 noUnderline progressIndicatorGreen':'progressItem btnClass3 noUnderline'} href='*' onClick={(e) => props.setStep(e, props.id)} id={'step'+props.id} disabled = {"disabled"}>
            {props.title}
        </a>
    )
}

//class definition
class Indicator extends React.Component{
    render(){
        return(
            <ProgressIndicator step={this.props.step} allStep={this.props.allStep} setStep={this.props.setStep}/>
        )
    }
}

export default Indicator