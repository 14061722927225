import React from "react"

//starting function
function NotFound(){
    return(
        <div className="content centered">
        <h1>Seite nicht gefunden</h1>
        <h2>Leider konnten wir die aufgerufene Seite nicht finden.</h2><br/><br/>
        <a href="/">
            <button className="btnClass1 normalBtnWidth">Zurück zur Startseite</button>
        </a>
        </div>
    )
}

export default NotFound