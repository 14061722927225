function getDuration(start,end){
    var delta = (((new Date(end)) - (new Date(start)))/1000)
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    
    var dur = ''
    if (days===1) dur += days +' Tag '
    if (days>1) dur += days +' Tage '
    if (hours===1) dur += hours +' Stunde '
    if (hours>1) dur += hours +' Stunden '
    if (minutes===1) dur += minutes +' Minute '
    if (minutes>1) dur += minutes +' Minuten '

    return dur
}

export { getDuration}