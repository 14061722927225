import React from "react"
import Check from "../addEvent_components/Check";
import EventBaseData from "../addEvent_components/EventBaseData";
import Indicator from "../addEvent_components/ProgeressIndicator";
import Tickets from "../addEvent_components/Tickets";
import './NewEvent.css'
import loginToken from "../functionality/loginToken";

//starting function
function AddEvent(){
    return(
        <AddEventController/>
    )
}

//site components
function MobileWarning(){
    return(
        <div className='tooNarrowWarning centered'>
            Diese Seite ist nicht für schmale Geräte geeignet!<br/>
            Bitte benutzen Sie ein breiteres Gerät (z.B. ein Tablet im Querformat, Laptop oder PC)<br/><br/>
            Sie können Ihr Gerät trotzdem verwenden, aber es kann zu Fehlern bei der Darstellung kommen.
        </div>
    )
}

//class definition
class AddEventController extends React.Component{
    constructor(){
        super();
        this.state = {
            paidAvailable: false,
            step: 0,
            prog: ['t','f','f'],
            baseData:{
                title:'',
                description:'',
                paid:false,
                feeCode:''
            },
            coverImage: './images/coverplaceholder.png',
            paymentInfo:{
                cash: true,
                paypal: true,
                transfer: true,
            },
            ticketInfo: null,
            ticketImage: './images/ticket-background-placeholder.png'
        }
        this.increaseStep = this.increaseStep.bind(this)
        this.setStep = this.setStep.bind(this)
        this.updateBaseData = this.updateBaseData.bind(this)
        this.updateData = this.updateData.bind(this)
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/getpaidavailable', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            if (text==='1'){
                this.setState({
                    paidAvailable: true
                })
            }
        })
    }

    increaseStep(nextStep){
        var tempProg = this.state.prog
        tempProg[nextStep] = 't'
        this.setState({
            step: this.state.step+1,
            prog: tempProg,
        })
    }

    setStep(event, stepNum){
        event.preventDefault()
        var tempTransform = [0,0,0]
        if(this.state.prog[stepNum]==='t'){
            tempTransform[stepNum] = 1
            this.setState({
                step: parseInt(stepNum),
                transform: tempTransform
            })
        }else if(stepNum !== 1){
            this.setStep(event, stepNum-1)
        }
    }

    updateBaseData(tar, val){
        if (tar==='title'){
            this.setState({
                baseData:{
                    title: val ,
                    description: this.state.baseData.description,
                    paid: this.state.baseData.paid,
                    feeCode: this.state.baseData.feeCode
                }
            })
        } else if (tar==='description'){
            this.setState({
                baseData:{
                    title: this.state.baseData.title ,
                    description: val,
                    paid: this.state.baseData.paid,
                    feeCode: this.state.baseData.feeCode
                }
            })
        } else if (tar==='paid'){
            this.setState({
                baseData:{
                    title: this.state.baseData.title ,
                    description: this.state.baseData.description,
                    paid: !this.state.baseData.paid,
                    feeCode: this.state.baseData.feeCode
                }
            })
        } else if (tar==='feeCode'){
            this.setState({
                baseData:{
                    title: this.state.baseData.title ,
                    description: this.state.baseData.description,
                    paid: this.state.baseData.paid,
                    feeCode: val
                }
            })
        }
    }

    updateData(tar, val){
        this.setState({
            [tar]: val
        })
    }

    render(){
        return(
            <>
            <Indicator step={this.state.step} allStep={this.state.prog} setStep={this.setStep}/>
            <MobileWarning/>
            <div className="content">
                <div className={(this.state.step===0)?'NewEvVisible':'NewEvInvisible'}> 
                    <EventBaseData nextStep={this.increaseStep} updateBaseData={this.updateBaseData} updateData={this.updateData} paid={this.state.baseData.paid} paidAvailable={this.state.paidAvailable}/>
                </div>
                <div className={(this.state.step===1)?'NewEvVisible':'NewEvInvisible'}> 
                    <Tickets nextStep={this.increaseStep} eventname={this.state.baseData.title} updateData={this.updateData}/>
                </div>
                <div className={(this.state.step===2)?'NewEvVisible':'NewEvInvisible'}> 
                    <Check baseData={this.state.baseData} paymentInfo={this.state.paymentInfo} ticketData={this.state.ticketInfo} coverImage={this.state.coverImage} bgImage={this.state.ticketImage} />
                </div>
            </div>
            </>
        )
    }

}


export default AddEvent