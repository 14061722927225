import './allEvents.css'
import React from "react"
import loginToken from "../functionality/loginToken"
import { getDuration } from "../functionality/duration";
import { toNormalNumber, toNormalDateAndTime } from "../functionality/normalizer";
import { useSearchParams } from 'react-router-dom';
import Pagy from './Pagy';
import LoadingAnimation from '../loading_components/LoadingAnimation';

//starting function
function AllEvents(){
    const [searchParams] = useSearchParams();
    return(
        <div className="content centered">
            <All order={searchParams.get('ord')} site={searchParams.get('s')} searchVal={searchParams.get('search')} />
        </div>
    )
}

//site components
const EventPanes = ({i}) => (
    <>
    {i.map(i => (
        <a className="allEventPane noUnderline" href={(i[0]!==0)?'event?pid='+i[0]+'&eid='+i[6]:''}>
            <div className='allEventPane_left'>
                <img className="imgContainerAllEvents" src={i[4]} alt='CoverImage'/>
                <div className='allPaneText'>
                    <h3>{i[1]}</h3>
                    <div className="allEventsParallel">
                        <div className="allEventsText wideAllEventsText">
                            <div className="centerHeight"><span className="icon calenderIcon">Datum,Uhrzeit </span> {toNormalDateAndTime(i[5])}</div>
                            <div className="centerHeight"><span className="icon timelapseIcon">Dauer </span> {getDuration(i[5], i[2])}</div>
                        </div>
                        <div className='allEventsText '>
                            <div className="centerHeight"><span className="icon pointIcon">Ort </span> {i[7]+' '+i[8]}</div>
                            {(i.length!==10)?'':<Dist num={i[9].toFixed()}/>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='allEventPane_right'>
                {(i[1]!=='')?<TickFrom i={i[3]}/>:''}
            </div>
        </a>
    ))}
    </>
); 

function Dist (props) {
    return (<div className="centerHeight"><span className="icon distanceIcon">Entfernung </span>{props.num} km</div>)
}

function TickFrom(props){
    return(
        <div className='allEventsCenterHeight'>
            <span>Tickets ab</span> <br/>
            <span className='green'>{toNormalNumber(props.i)} €</span>
        </div>
    )
}

//class definitions
class All extends React.Component{

    constructor(props){
        super();
        this.state = {
            data: [],
            site: props.site,
            order: props.order,
            searchVal: props.searchVal,
            totalNumber: 0,
            elementPerPage : 10,
        }
        this.getItems = this.getItems.bind(this)
        this.setPage = this.setPage.bind(this)
    }

    async componentDidMount(){
        await this.getItems(this.state.site)
    }

    async getItems(site){
        if(this.state.searchVal === null){
            await fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/getnearevents', {
                method: 'POST',
                body: JSON.stringify({
                    key: ((this.state.order === 'near')?loginToken.GetToken():null),
                    distance: loginToken.GetToken(),
                    start: (0 + ((parseInt(site)-1) * this.state.elementPerPage)),
                    end: this.state.elementPerPage,
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then (resp => resp.text())
            .then(text => {
                var data = JSON.parse(text)
                this.setState({
                    data: data,
                });
            })
        } else {
            await fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/searchevent', {
                method: 'POST',
                body: JSON.stringify({
                    start: (0 + ((parseInt(site)-1) * this.state.elementPerPage)),
                    end: this.state.elementPerPage,
                    searchStr:this.state.searchVal,
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then (resp => resp.text())
            .then(text => {
                var data = JSON.parse(text)
                this.setState({
                    data: data,
                });
            })
        }

        if(this.state.totalNumber===0){
            await fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/geteventnum', {
                method: 'POST',
                body: JSON.stringify({
                    key: ((this.state.order === 'near')?loginToken.GetToken():null),
                    distance: loginToken.GetToken(),
                    searchStr: this.state.searchVal,
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then (resp => resp.text())
            .then(text => {
                var data = JSON.parse(text)
                this.setState({
                    totalNumber: data[0][0],
                });
            })
        }
    }

    async setPage(number){
        await this.getItems(number)
        this.setState({
            site: number,
        })
        let stateObj = { id: "100" };
        window.history.replaceState(stateObj,'Skalar','/allEvents?s='+number)
    }

    render(){
        if(!this.state.data.length || this.state.totalNumber === 0) return <LoadingAnimation/>
        return(
            <div>
                <Pagy currentPage={Number(this.state.site)} elementCount={Number(this.state.totalNumber)} elementPerPage={this.state.elementPerPage} setPage={this.setPage} />
                <EventPanes i={this.state.data} key={this.state.site}/>
                <Pagy currentPage={Number(this.state.site)} elementCount={Number(this.state.totalNumber)} elementPerPage={this.state.elementPerPage} setPage={this.setPage} />
            </div>
        )
    }
}

export default AllEvents