import React from 'react'
import './TicketBuy.css'
import { toNormalNumber,toMachineNumber } from '../functionality/normalizer'
import loginToken from '../functionality/loginToken'
import PayPalButton from '../monetization/PayPal'

//starting function
function TicketBuy(props){
    return(
        <div id='ticketBuyOverlay' className='ticketBuyOverlay'>
            <BuyPopUp l={props.l} data={props.data}/>
        </div>
    )
}

//functions
function abortBuy(){
    var elem = document.getElementById('ticketBuyOverlay')
    elem.style.zIndex = -3
    elem.style.opacity = 0
}

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

//site components
const OrderElement = ({i}) =>(
    <>
    {i.map(i => (
        <div key={i['pcid']} className='pricC_parallel'>
            {(i['reqQua']!=='0')?<OrderElementPrice i={i}/>:''}
        </div>
    ))}
    </>
)

const OrderElementPrice = ({i}) => (
    <>
    Preisklasse {i['pcNum']}: <span> {i['reqQua']} * {toNormalNumber(i['price'])+' €'}</span>
    </>
)

const OrderSum = ({i}) => (
    <div key={i['pcid']} className='pricC_parallel'>
        Summe : 
        <span>{i[0]['sum']}</span>
    </div>
)

function AbortBtn(){
    return(
        <button className='btnClass3' onClick={() => abortBuy()}>abbrechen</button>
    )
}

function BuyBtn(props){
    return(
        <center>
            <div id='buyError' className='errorVisible'></div>
            <button className='buyBtn' id='buyBtn2' disabled={props.disabled} onClick={() => props.buy(props.option, '')} style={{opacity: props.opacity}}>{props.text}</button>
        </center>
    )
}

function OrderList(data){
    return(
        <div>
            <h2 className='event_compensateH3'>Zusammenfassung</h2>
            <OrderElement i={data['data'][1]}/>
            <br/>
            <OrderSum i={data['data']}/>
        </div>
    )
}


//class definitions
class BuyPopUp extends React.Component{
    constructor(){
        super();
        this.state = {
            disabledBuyBtn: false,
            opacity: 1,
            buyInProgress: false,
            buyComplete: false,
        }
        this.buyTickets = this.buyTickets.bind(this)
        this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this)
    }

    buyTickets(option, details){
        this.setState({
            disabledBuyBtn: true,
            opacity: 0.6,
            buyInProgress: true,
        })

        fetch(process.env.REACT_APP_PURCHASE_API+process.env.REACT_APP_STAGE+'/buytickets', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                buyData: this.props.data,
                paymentOpt: option,
                paymentDetails: details,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            if (text!=='True'){
                document.getElementById('buyError').textContent = text
                this.setState({
                    buyInProgress: false,
                })
            } else {
                this.setState({
                    buyComplete: true,
                })
                var elem = document.getElementById('buyBtn2')
                elem.textContent = "Kauf erfolgreich!"
                delay(2500).then(
                    () => window.location.reload()
                )
            }
        })
    }   

    handlePaymentSuccess(data, actions){
        return actions.order.capture().then((details) => {
            this.buyTickets('paypal', JSON.stringify(details))
        });
    };

    render(){
        if (this.props.l === 0){
            return <></>
        }

        return(
            <div className='ticketBuyCenter secColOutline ticketBuyheavyBG'>
                <div className='loaderWrapper' style={{display: (this.state.buyInProgress)?'block':'none'}}> 
                    <div className='loaderContainer'>
                        <div className={(this.state.buyComplete)?"circle-loader load-complete":'circle-loader'}>
                            <div className="checkmark draw" style={{display: (this.state.buyComplete)?'block':'none'}}></div>
                        </div>
                    </div>
                </div>
                <OrderList data={this.props.data}/>
                <hr/>
                {(this.props.data[0]['sum']!=='0,00 €')?
                <>
                <h3>Zahlungsart</h3>
                {(this.props.data[0]['cash'] === 'True')?<BuyBtn text='Barzahlung' disabled={this.state.disabledBuyBtn} buy={this.buyTickets} opacity={this.state.opacity} option={'cash'}/>:''}
                {(this.props.data[0]['paypal'] === 'True')?
                <PayPalButton amount={toMachineNumber(this.props.data[0]['sum'])} onSuccess={this.handlePaymentSuccess} />:''
                }
                <center>
                    <p className='smallFont'>Mit dem Kauf erklären Sie sich mit den<br/><a className='noUnderline' href='/AGB' target='_blank'>allgemeinen Geschäftsbedingungen</a> einverstanden.</p>
                </center>
                </>
                :
                <>
                <BuyBtn text='Karten anfordern' disabled={false} buy={this.buyTickets} option={'free'} opacity={1}/>
                </>
                }
                <AbortBtn/>
            </div>
        )
    }
}

export default TicketBuy