import React from "react"
import loginToken from "../functionality/loginToken"
import FeeClasses from "./FeeClasses"

//site components 
function AdmManagement(props){
    return(
        <b>Adminverwaltung</b>
    )
}

function PictureData(){
    return(<b>Bilder in der DB</b>)
}

function SanitizeBtns(){
    return(
        <div>
            <button className="btnClass1 normalPropotions">Datenbank aufräumen</button>
            <button className="btnClass1 normalPropotions">Events säubern</button>
        </div>
    )
}

function UserManagement(){
    return(
        <>
        <b>Nutzerverwaltung</b>
        <div className="parallel">
            <div>
                <label>Kunden: </label>
                <input type='text' className="inputText"></input>
            </div>
            <div className="spacer"/>
            <div>
                <label>Veranstalter: </label>
                <input type='text' className="inputText"></input>
            </div>
        </div>
        </>
    )
}

//class definition
class Admin extends React.Component{
    constructor(){
        super()
        this.state = {
            data: "",
        }
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_ADMINTOOLS_API+process.env.REACT_APP_STAGE+'/getadmindashboard', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            var obj = JSON.parse(text);
            this.setState({
                data: obj
            })
        })
    }

    render(){
        if (this.state.data==="") return <div className="content"><center>Lade Daten...</center></div>
        return(
            <div className="content">
                <SanitizeBtns/>
                <FeeClasses fees={this.state.data[0]} codes={this.state.data[1]}/>
                <PictureData/>
                <UserManagement/>
                <AdmManagement/>
            </div>
        )
    }
}

export default Admin