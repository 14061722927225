import React from "react"
import loginToken from '../functionality/loginToken'
import { Cash, PayPal} from "./Payment";

//site components
function BaseDatEdit(props){
    return(
        <div className="viewEventMinWidth">
            <h1>Titel (max. 100 Zeichen)</h1>
            <input defaultValue={props.title} type='text' className="inputText"  maxLength='100' onChange={(e) => props.alterData(e.target.value, 'title')}/>
            <div className='viewEventFitWidth'>
                <h2>Beschreibung (max. 1000 Zeichen)</h2>
                <textarea defaultValue={props.description} type='text' className="inputText"  maxLength='1000' rows='7' onChange={(e) => props.alterData(e.target.value, 'description')}/>
                <h2>Akzeptierte Zahlungsarten:</h2>
                <PayPal checked={props.data[5]}/>
                <Cash checked={props.data[4]}/>
            </div>
        </div>
    )
}

function BaseDatView(props){
    return(
        <>
        <h1>{props.title}</h1>
        <div className='eventFitWidth'>
            <h2>Beschreibung:</h2>
            <p>{props.description}</p>
            <h2>Akzeptierte Zahlungsarten:</h2>
            <PaymentOption type='cash' bool={props.data[4]}/>
            <PaymentOption type='paypal' bool={props.data[5]}/>
        </div>
        </>
    )
}

function PaymentOption(props){
    var opt = ['cash', 'paypal']
    var text= ['Bar an der Abendkasse', 'PayPal-Zahlungsarten']
    for (var i = 0; i<opt.length; i+=1){
        if(props.type === opt[i] && props.bool === 'True'){
            return(
                <>
                <div className="centerHeight">{text[i]}</div><br/>
                </>
            )
        }
    }
}

function VEventBoxViewer(props){
    return(
        <div className='event_parallel secColOutline'>
            <div>
                <center>
                    <img className="event_card event_parallel_space" id="coverImage" src={props.data[3]} draggable='false' alt='Eventcoverbild' onLoad={() => props.resizeImage(props.loaded)}/>
                </center>
                {(!props.coverEdit)?'':<input id='imgInp' onChange={(e) => {props.coverChanged(); props.setCoverImg(e)}} className='pictureInput' type='file' accept='image/*'/>}
                <button className="btnClass2 normalPropotions" onClick={(e) => {props.toggleCover(); if(props.coverEdit && props.coverHasChanged){props.saveCover()}}}>{(props.coverEdit)?'Speichern':'Coverbild ändern'}</button>
                {(!props.coverEdit)?'':<button className="btnClass3 normalPropotions" onClick={(e) => {props.toggleCover(); if(props.coverHasChanged) window.location.reload()}}>Abbrechen</button>}
            </div>
            <div className='event_centered'>
                {(props.editable)?<BaseDatEdit title={props.title} description={props.description} data={props.data} alterData={props.alterData}/>:<BaseDatView title={props.title} description={props.description} data={props.data}/>}
                <button className="btnClass2 normalPropotions" onClick={(e) => {props.toggle(); if(props.editable){props.commit()}}}>{(props.editable)?'Speichern':'Bearbeiten'}</button>
            </div>
        </div>
    )
}

//functions
function cropImage(){
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var img = document.getElementById('coverImage')
    var sy = 0
    var sx = ((getWidthAndHeight()[1]/2)-200)
    var croppedWidth = 400
    var croppedHeight = 600
    canvas.width = croppedWidth;
    canvas.height = croppedHeight;

    ctx.drawImage(img, sx, sy, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);
    var dataurl = canvas.toDataURL(img.type);
    return dataurl
}

function getWidthAndHeight(){
    var picPrev = document.getElementById('coverImage')
    var originalWidth = picPrev.naturalWidth
    var originalHeight = picPrev.naturalHeight
    var aspectRatio = originalWidth/originalHeight
    var newHeight = 600
    var newWidth = newHeight * aspectRatio

    return [newHeight,newWidth]
}

//class definition
class VEventBox extends React.Component{
    constructor(props){
        super()
        this.state = {
            data: props.baseData[0],
            editable: false,
            title: props.baseData[0][1],
            description: props.baseData[0][2],
            coverEdit: false,
            changed: false,
            loaded: true,
        }
        this.alterData = this.alterData.bind(this)
        this.commitChanges = this.commitChanges.bind(this)
        this.coverChanged = this.coverChanged.bind(this)
        this.resizeImage = this.resizeImage.bind(this)
        this.saveCover = this.saveCover.bind(this)
        this.setCoverImg = this.setCoverImg.bind(this)
        this.toggleCover = this.toggleCover.bind(this)
        this.toggleViewerEdit = this.toggleViewerEdit.bind(this)
    }

    alterData(e,tar){
        this.setState({
            [tar]: e,
        })
    }

    commitChanges(){
        var pp = document.getElementById('paypal_box').checked 
        var ca = document.getElementById('cash_box').checked 

        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/updateeventbasedata', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                title: this.state.title,
                description: this.state.description,
                EID: this.state.data[0],
                payment:{
                    cash: ca,
                    paypal: pp,
                    transfer: false,
                }
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
    }

    coverChanged(){
        this.setState({
            changed: true
        })
    }

    resizeImage(){
        if (this.state.loaded) return

        var img = document.getElementById('coverImage')
        var dimensions = getWidthAndHeight()
        var canvas = document.createElement("canvas");
        canvas.width = dimensions[1];
        canvas.height = dimensions[0];
        var ctx = canvas.getContext("2d");
    
        ctx.drawImage(img, 0, 0, dimensions[1], dimensions[0]);
    
        var dataurl = canvas.toDataURL(img.type);
        img.src = dataurl
        this.setState({
            loaded: true,
        })
    }

    saveCover(){
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/updateeventcover', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                EID: this.state.data[0],
                newCover: cropImage()
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
    }

    setCoverImg(e){
        this.setState({
            loaded: false,
        })
        var picturePreview = document.getElementById('coverImage')
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.addEventListener("load", () => {
            // convert image file to base64 string
            picturePreview.src = reader.result;
        }, false);
        
        if (file) {
            reader.readAsDataURL(file)
        } else {
            picturePreview.src = './images/coverplaceholder.png'
        }

        return true
    }

    toggleCover(){
        this.setState({
            coverEdit: !this.state.coverEdit
        })
    }

    toggleViewerEdit(){
        this.setState({
            editable: !this.state.editable
        })
    }

    render(){
        return(
            <VEventBoxViewer resizeImage={this.resizeImage} setCoverImg={this.setCoverImg} coverChanged={this.coverChanged} coverHasChanged={this.state.changed} saveCover={this.saveCover} toggleCover={this.toggleCover} coverEdit={this.state.coverEdit} title={this.state.title} description={this.state.description} data={this.state.data} toggle={this.toggleViewerEdit} editable={this.state.editable} commit={this.commitChanges} alterData={this.alterData}/>
        )
    }
}

export default VEventBox