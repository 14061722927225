import Switch from '@mui/material/Switch';
import React from 'react';
import Draggable from 'react-draggable';
import loginToken from "../functionality/loginToken"

//starting function
function Tickets(props){  
    return(
        <div>
            <h3>Ticketdesigner</h3>
            <TicketFrame eventname={props.eventname}/>
            <NextBtn updateData={props.updateData} nextStep={props.nextStep}/>
        </div>
    )
}

//site Components
function NextBtn(props){
  return(
    <div>
      <center>    
          <br/><br/>
          Hinweis: Das nachträgliche Anpassen des Ticketdesigns erfordert eine komplette Neukonfiguration des Tickets.
          <div id='submiterror' className="errorInvisible"/><br/><br/>
          <a href='*' onClick={(e) => { props.updateData('ticketInfo', commitTicket(e)); props.updateData('ticketImage', image); props.nextStep()}} className="btnClass1 normalPropotions">Nächster Schritt</a>
      </center>
      <br/>
    </div>
  )
}

function TicketFrame(props){
    return(
        <div className='ticketDesigner'>
            <div className='ticketDesignerConfigList'>
            Eigenes Bild hochladen <br/>
            <input id='bgInp' onChange={setBGPic} className='pictureInput' type='file' accept='image/*' name='Eigenes Bild hochladen'/><br/>
            <div>
              <div className='parallel'> 
                <div className='ticketConfigContainer'>
                  <details>
                    <summary>
                      <Switch defaultChecked  onChange={switchEventName}/>Eventname<br/>
                    </summary>
                    <TicketInputSet tar={targets[0]}/>
                  </details>
                  <details>
                    <summary>
                      <Switch defaultChecked  onChange={switchEventTime}/>Startzeit<br/>
                    </summary>
                      <TicketInputSet tar={targets[1]}/>
                  </details>
                </div>
                <div className='ticketConfigContainer'>
                  <details>
                    <summary>
                      <Switch defaultChecked  onChange={switchEventPlace}/>Ort<br/>
                    </summary>
                    <TicketInputSet tar={targets[2]}/>
                  </details>
                  <details>
                    <summary>
                      <Switch defaultChecked  onChange={switchEventPrice}/>Preis<br/>
                    </summary>
                    <TicketInputSet tar={targets[3]}/>
                  </details>
                </div>
              </div>
            </div>
            </div>
            <div>
              <span className='higherLine'>Interaktive Vorschau - Elemente zum Repositionieren mit der Maus ziehen</span>
              <Designer eventname={props.eventname} eventprice={price} eventplace={place} eventtime={time} />
            </div>
        </div>
    )
}

const TicketInputSet = (props) =>(
    <div className='ticketInputSet'>
      <input type='checkbox' id={props.tar+'_bold'} onClick={(e) => setBold(props.tar, e)}/>Fett<br/>
      <input type='checkbox' id={props.tar+'_italic'} onClick={(e) => setItalic(props.tar, e)}/>Kursiv<br/>
      Schriftgröße<br/>
      <input type='number' id={props.tar+'_size'} defaultValue='16' className='presentationInp smallNumber' onChange={(e) => setSize(props.tar, e)}/><br/>
      Schriftfarbe<br/>
      <input type='color' id={props.tar+'_color'} className='presentationInp' onChange={(e) => setColor(props.tar, e)}/>
    </div>
)

//variables
var targets=['ticketEventTitle', 'ticketEventTime', 'ticketEventPlace', 'ticketEventPrice']
var time = true
var title = true
var place = true
var price = true
var image = './images/ticket-background-placeholder.png'

var imgPos="top:0px; left:0px;"
var qrPos ="top:0px; left:676px;"
var timePos = "top:255px; left:5px;"
var titlePos ="top:5px; left:5px;"
var placePos ="top:275px; left:5px;"
var pricePos ="top:45px; left:5px;"

//functions called via user actions
function commitTicket(event){
  try {
    event.preventDefault()
  } catch (error) {
  }
  if (writeImageCache()) return packTicketData()
}
  

function getDisplays(){
  var displayTime='block'
  var displayTitle='block'
  var displayPlace='block'
  var displayPrice='block'
  if(!time) displayTime='none'
  if(!title) displayTitle='none'
  if(!place) displayPlace='none'
  if(!price) displayPrice='none'

  return [displayTitle,displayTime,displayPlace,displayPrice]
}

function getPositions(){
  return [titlePos, timePos, placePos, pricePos, imgPos, qrPos]
}

//functions
function getWidthAndHeight(){
  var picPrev = document.getElementById('ticketPicture')
  var originalWidth = picPrev.naturalWidth
  var originalHeight = picPrev.naturalHeight
  var aspectRatio = originalHeight/originalWidth
  var newWidth = 1800
  var newHeight = newWidth * aspectRatio

  return [newHeight,newWidth]
}

function packTicketData(){
  var displays = getDisplays()
  var positions = getPositions()
  var keys = ['eventTitle', 'eventTime','eventPlace','eventPrice']
  var data= []

  for (var i = 0; i<targets.length; i+=1){
    var col = document.getElementById(targets[i]+"_color").value
    var size = document.getElementById(targets[i]+"_size").value
    var bold = 'normal'
    var italic = 'normal'

    if(document.getElementById(targets[i]+"_bold").checked){bold = 'bold'}
    if(document.getElementById(targets[i]+"_italic").checked){italic = 'italic'}

    data.push({
      [keys[i]+'_style']: "position: absolute; "+positions[i]+" display:"+displays[i]+"; color:"+col+"; font-weight:"+bold+"; font-size:"+size+"px ; font-style:"+italic+";",
      [keys[i]]:document.getElementById(targets[i]).textContent
    })
  }
  
  data.push({
    imgPos: 'position: absolute; '+positions[4],
    qrPos: positions[5]
  })
  return data
}

function resizeImage(){
  var dimensions = getWidthAndHeight()
  var canvas = document.createElement("canvas");
  canvas.width = dimensions[1];
  canvas.height = dimensions[0];
  var ctx = canvas.getContext("2d");
  var img = document.getElementById('ticketPicture')

  ctx.drawImage(img, 0, 0, dimensions[1], dimensions[0]);

  var dataurl = canvas.toDataURL(img.type);
  img.src = dataurl
  image = dataurl
}

function setBGPic(){
  var imgInp = document.getElementById('bgInp')
  var target = document.getElementById('ticketPicture')

  const file = imgInp.files[0];
  const reader = new FileReader();

  reader.addEventListener("load", () => {
      // convert image file to base64 string
      target.src = reader.result;
      image = reader.result;
    }, false);
  
    if (file) {
      reader.readAsDataURL(file)
    } else {
      target.src = './images/ticket-background-placeholder.png'
      image = './images/ticket-background-placeholder.png'
    }
}

function setBold(target, cb){
  var element=document.getElementById(target)
  try {
    if(cb.target.checked){element.style.fontWeight = "bold"}
    else{element.style.fontWeight = ''}
  } catch (error) { }
}

function setColor(target, cb){
  var element=document.getElementById(target)
  try {
    element.style.color = cb.target.value
  } catch (error) { }
}

function setItalic(target, cb){
  var element=document.getElementById(target)
  try {
    if(cb.target.checked){element.style.fontStyle = "italic"}
    else{element.style.fontStyle = ''}
  } catch (error) { }
}

function setSize(target, cb){
  var element=document.getElementById(target)
  try {
    element.style.fontSize = cb.target.value+'px'
  } catch (error) { }
}

function switchEventName(){
    var element = document.getElementById('ticketEventTitle')
    title = !title
    if (title){
        element.style.display='block'
    } else {
        element.style.display='none'
    }
}

function switchEventPlace(){
    var element = document.getElementById('ticketEventPlace')
    place = !place
    if (place){
        element.style.display='block'
    } else {
        element.style.display='none'
    }
}

function switchEventPrice(){
    var element = document.getElementById('ticketEventPrice')
    price = !price
    if (price){
        element.style.display='block'
    } else {
        element.style.display='none'
    }
}

function switchEventTime(){
    var element = document.getElementById('ticketEventTime')
    time = !time
    if (time){
        element.style.display='block'
    } else {
        element.style.display='none'
    }
}

//API calls
async function writeImageCache(){
  try{
      await fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/addImageCache', {
          method: 'POST',
          body: JSON.stringify({
              key: loginToken.GetToken(),
              cover: '',
              ticket: image,
          }),
          headers: {
              'Content-Type': 'application/json'
          },
      }).then (resp => resp.text())
      .then(text => {
          var element = document.getElementById('submiterror')
          
          if (text !== "True"){
              element.textContent = text
              element.classList.add('errorVisible')
              element.textContent = "Fehler bei der Datenübermittlung!"
              return false
          } else {
              element.classList.remove('errorVisible')
              return true
          }
      })
  } catch (err){
      var element = document.getElementById('submiterror')
      element.classList.add('errorVisible')
      element.classList.remove('errorInvisible')
      return false
  }
}

//class definition
class Designer extends React.Component{
    state = {
        activeDrags: 0,
        imgPosition: {
            x: 0, y: 0
          },
        
        qrPosition: {
          x: 676, y: 0
        },
        titlePosition:{
            x: 5, y: 5
        },
        pricePosition:{
            x: 5, y: 45
        },
        addressPosition:{
            x: 5, y: 275
        },
        timePosition:{
            x: 5, y: 255
        },
        controlledPosition: {
          x: 0, y: 0
        }
      };

      handleDragImg = (e, ui) => {
        const {x, y} = this.state.imgPosition;
        this.setState({
          imgPosition: {
            x: x + ui.deltaX,
            y: y + ui.deltaY,
          }
        })
        imgPos="top:"+(y + ui.deltaY)+"px ;left:"+(x + ui.deltaX)+"px ;" 
      };
    
      handleDragQr = (e, ui) => {
        const {x, y} = this.state.qrPosition;
        this.setState({
          qrPosition: {
            x: x + ui.deltaX,
            y: y + ui.deltaY,
          }
        })
        qrPos="top:"+(y + ui.deltaY)+"px ;left:"+(x + ui.deltaX)+"px ;" 
      };

      handleDragTitle = (e, ui) => {
        const {x, y} = this.state.titlePosition;
        this.setState({
            titlePosition: {
            x: x + ui.deltaX,
            y: y + ui.deltaY,
          }
        })
        titlePos="top:"+(y + ui.deltaY)+"px ;left:"+(x + ui.deltaX)+"px ;" 
      };

      handleDragPrice = (e, ui) => {
        const {x, y} = this.state.pricePosition;
        this.setState({
            pricePosition: {
            x: x + ui.deltaX,
            y: y + ui.deltaY,
          }
        })
        pricePos="top:"+(y + ui.deltaY)+"px ;left:"+(x + ui.deltaX)+"px ;" 
      };

      handleDragAddress = (e, ui) => {
        const {x, y} = this.state.addressPosition;
        this.setState({
            addressPosition: {
            x: x + ui.deltaX,
            y: y + ui.deltaY ,
          }
        })
        placePos="top:"+(y + ui.deltaY)+"px ;left:"+(x + ui.deltaX)+"px ;" 
      };

      handleDragTime = (e, ui) => {
        const {x, y} = this.state.timePosition;
        this.setState({
            timePosition: {
            x: x + ui.deltaX,
            y: y + ui.deltaY,
          }
        })
        timePos="top:"+(y + ui.deltaY)+"px ;left:"+(x + ui.deltaX)+"px ;" 
      };
    
      onStart = () => {
        this.setState({activeDrags: ++this.state.activeDrags});
      };
    
      onStop = () => {
        this.setState({activeDrags: --this.state.activeDrags});
      }

    render (){
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
 
        return(
            <div id='ticketBG'className="ticketOutline">
                <Draggable axis="x" bounds="parent" grid={[5, 5]} onDrag={this.handleDragQr} onDrop={this.handleDragQr} {...dragHandlers}>
                    <div className='qrBox'>
                        <img src='./images/jne-events-ticket.png' className='qrCodeJNETitle' draggable="false"/>
                        <center>
                        <img src='./images/qrcode_placeholder.png' className='qrCodePlaceholder' draggable="false"/>
                        </center>
                    </div>
                </Draggable>
                <Draggable onDrag={this.handleDragImg} onDrop={this.handleDragImg} {...dragHandlers}>
                    <div>
                        <img id='ticketPicture' className='ticketPicture' src='./images/ticket-background-placeholder.png' draggable="false" onChange={(e) => resizeImage()}/>
                    </div>
                </Draggable>
                <Draggable bounds="parent" grid={[5, 5]} onDrag={this.handleDragTitle}  onDrop={this.handleDragTitle} defaultPosition={{x: 0, y: 0}} {...dragHandlers}>
                    <div className='ticketEventText ticketEventMargin' >
                        <span className='ticketStrongDark' id='ticketEventTitle'>{this.props.eventname}</span>
                    </div>
                </Draggable>
                <Draggable bounds="parent" grid={[5, 5]} onDrag={this.handleDragAddress}  onDrop={this.handleDragAddress} defaultPosition={{x: 0, y: 270}} {...dragHandlers}>
                    <div className='ticketEventText ticketEventMargin' >
                        <span className='ticketStrongDark' id='ticketEventPlace'>Musterstraße 1, 12345 Musterstadt</span>
                    </div>
                </Draggable>
                <Draggable bounds="parent" grid={[5, 5]} onDrag={this.handleDragPrice}  onDrop={this.handleDragPrice} defaultPosition={{x: 0, y: 40}} {...dragHandlers}>
                    <div className='ticketEventText ticketEventMargin' >
                        <span className='ticketStrongDark' id='ticketEventPrice'>Preis: <span style={{color:'transparent'}}>00</span>5,00 €</span>
                    </div>
                </Draggable>
                <Draggable bounds="parent" grid={[5, 5]} onDrag={this.handleDragTime}  onDrop={this.handleDragTime} defaultPosition={{x: 0, y: 250}} {...dragHandlers}>
                    <div className='ticketEventText ticketEventMargin' >
                        <span className='ticketStrongDark' id='ticketEventTime'>am 21.01.2023 um 16:09 Uhr</span>
                    </div>
                </Draggable>
            </div>
        )
    }
}

export default Tickets