import './Landing.css'
import loginToken from '../functionality/loginToken'
import { toNormalNumber } from '../functionality/normalizer'
import TopSlider from '../landing_components/TopSlider'
import React from 'react'

//starting function
function Landing(){
    return(
        <div className="content">
                <h2>Für dich empfohlen</h2>
                <TopSlider/>
                <Upcoming/>
                <SaleSoon/>
                <Further/>
                <AllEvents/>
        </div>
    )
}


//site components
const EventPanes = ({i}) => (
    <>
    {i.map(i => (
        <a className="eventPane noUnderline" href={(i[0]!==0)?'event?pid='+i[0]+'&eid='+i[6]:''}>
            <img className="imgContainer" src={i[4]} alt='coverbild'/>
            <div className='paneText'>
                <h3 className='max3Lines'>{i[1]}</h3>
                <p>{(i[1]!=='')?'Karten ab '+ toNormalNumber(i[3])+' €':''}</p>
            </div>
        </a>
    ))}
    </>
); 

function AllEvents() {
    return(
        <center>
            <a href='/allEvents?s=1'><button className="btnClass1 normalPropotions">Alle Veranstaltungen</button></a>
        </center>
    )
}

//class definitions
class Further extends React.Component{
    constructor(){
        super();
        this.state = {
            data: [],
        }
    }

    async componentDidMount(){
        await fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/getnearevents', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                start: 5,
                end: 9,
                distance: null,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var data = JSON.parse(text)
            this.setState({
                data: data,
            });
        })
    }   

    render(){
        if(!this.state.data.length) return;
        return(
            <div >
                <h2>Weitere Veranstaltungen</h2>
                <div className="eventPaneGrid more">
                    <EventPanes i={this.state.data} />
                </div>
            </div>
        )
    }
}

class SaleSoon extends React.Component{
    constructor(){
        super();
        this.state = {
            data: [],
        }
    }

    async componentDidMount(){
        await fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/getsalestartsoon', {
            method: 'POST',
            body: JSON.stringify({

            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var data = JSON.parse(text)
            this.setState({
                data: data,
            });
        })
    }   

    render(){
        if(!this.state.data.length) return;
        return(
            <div >
                <h2>Vorverkauf startet demnächst</h2>
                <div className="eventPaneGrid more">
                    <EventPanes i={this.state.data} />
                </div>
            </div>
        )
    }
}

class Upcoming extends React.Component{
    constructor(){
        super();
        this.state = {
            data: [],
            dummyData: [['0','','',0,'./images/coverplaceholder.png'],
                        ['0','','',0,'./images/coverplaceholder.png'],
                        ['0','','',0,'./images/coverplaceholder.png'],
                        ['0','','',0,'./images/coverplaceholder.png'],
                        ['0','','',0,'./images/coverplaceholder.png']]
        }
    }
   
    async componentDidMount(){
        await fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/getupcomingevents', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                start: 0,
                end: 5,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var data = JSON.parse(text)
            this.setState({
                data: data,
            });
        })
    }

    render(){
        
        if(!this.state.data.length){
            return (
                <div>
                    <h2>Demnächst</h2>
                    <div className="eventPaneGrid upcoming">
                        <EventPanes i={this.state.dummyData} />
                    </div>
                </div>
            )
        }

        return(
            <div>
                <h2>Demnächst</h2>
                <div className="eventPaneGrid upcoming">
                    <EventPanes i={this.state.data} />
                </div>
            </div>
        )
    }
}

export default Landing;