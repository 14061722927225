import React from 'react';
import { toNormalNumber } from '../functionality/normalizer';

//starting component
const TMapper = ({data, update}) => (
    <>
    {data.map(data => (
        <TicketController key={data[0]} data={data} update={update} />
    ))}
    </>
)

//class definitions
class TicketController extends React.Component{
    constructor(props){
        super();
        this.state = {
            ticketID: props.data[0],
            pricePerTicket: props.data[1],
            ticketCount: props.data[2],
            priceClassNumber: props.data[3],
            ticketsToReturn: 0,
            ticketReturnVisible: false, 
        }
        this.toggleTicketReturn = this.toggleTicketReturn.bind(this)
        this.changeTicketsToReturn = this.changeTicketsToReturn.bind(this)
    }

    changeTicketsToReturn(val){
        if(val < 0) return
        else if(val > this.state.ticketCount) return
        this.setState({
            ticketsToReturn: val
        }, () => this.props.update(this.state.ticketID, this.state.ticketsToReturn, this.state.pricePerTicket, this.state.priceClassNumber))
    }

    toggleTicketReturn(){
        this.setState({
            ticketReturnVisible: !this.state.ticketReturnVisible,
        })
    }

    render(){
        return(
            <div className='secColOutline centered defaultPropotions higherLine'>
                <b>Preisklasse: {this.state.priceClassNumber}</b>
                Preis Pro Ticket: {toNormalNumber(this.state.pricePerTicket)} €<br/>
                <span className='oneLine'>Anzahl {(parseInt(this.state.ticketsToReturn)===0)?'':'/ nach Rückgabe'}: {this.state.ticketCount} {(parseInt(this.state.ticketsToReturn)!==0)?<span className='errorColor'><span className='icon iconWarningCol arrowRightIcon smallIcon'>nach_Rückgabe</span> {(parseInt(this.state.ticketCount) - this.state.ticketsToReturn)}</span>:''}</span>
                {(!this.state.ticketReturnVisible)?'':<>
                    <input className='inputText numSmall' type='number' step='1' value={this.state.ticketsToReturn} onChange={(e) => this.changeTicketsToReturn(e.target.value)}/>
                </>}
                
                <br/>
                <div className='btnClass3 smallText autoWidth' onClick={() => this.toggleTicketReturn()}>{(this.state.ticketReturnVisible)?'Anzahl festlegen':'Ticket(s) zurückgeben'}</div>
            </div>
        )
    }
}

export default TMapper