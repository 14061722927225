import "./AGB.css"

//starting function
function AGB(){
    return(
        <div className="content">
            <h1>Allgemeine Geschäftsbedingungen für Skalar von jne-solutions UG (haftungsbeschränkt) </h1>
            
            <p className='paragraphHeader'>§1 Geltung gegenüber Unternehmern und Begriffsdefinitionen</p>
            (1) Die nachfolgenden Allgemeinen Geschäftbedingungen gelten für alle Dienstleistungen zwischen uns und einem Nutzenden
            in ihrer zum Zeitpunkt der Auftragsstellung gültigen Fassung.<br/><br/>

            Nutzende*r ist jeder Verbrauchende und Unternehmer. <br/>

            Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
            ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 BGB).<br/><br/>

            Unternehmer ist jede natürliche und juristische Person, sowie Rechtsträger, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen 
            oder selbstständigen beruflichen Tätigkeit handelt (§ 14 BGB).

            
            <p className='paragraphHeader'>§2 Zustandekommen eines Vertrages, Speicherung des Vertragstextes</p>
            (1) Die folgenden Regelungen über den Vertragsabschluss gelten für Bestellungen über unseren Internetshop http://www.skalar-jne.de .<br />
            <br />
            <b>(2) Im Falle des Vertragsschlusses kommt der Vertrag mit<br />

            <div className='twoEM' > <br/>
            jne-solutions UG (haftungsbeschränkt)<br/>
            Jan Jeschinski<br/>
            Havemannstraße 38<br/>
            D-12689 Berlin<br/>
            Registernummer 12131415<br/>
            Registergericht Amtsgericht Musterstadt<br/><br/>
            </div>
            zustande.<br/></b><br/>

            (3) Die Präsentation der Waren in unserem Internetshop stellen kein rechtlich bindendes Vertragsangebot unsererseits dar,
            sondern sind nur eine unverbindliche Aufforderungen an den Verbrauchende, Waren zu bestellen. Mit der Bestellung der gewünschten Ware gibt der Verbrauchende ein für
            ihn verbindliches Angebot auf Abschluss eines Kaufvertrages ab.<br/>

            (4) Bei Eingang einer Bestellung in unserem Internetshop gelten folgende Regelungen:
            Der Verbrauchende gibt ein bindendes Vertragsangebot ab, indem er die in unserem Internetshop vorgesehene Bestellprozedur erfolgreich durchläuft.<br/><br/>

            Die Bestellung erfolgt in folgenden Schritten:<br/><br/>

            <div className='twoEM'>
                
                1 - Anmeldung im Internetshop nach Registrierung und Eingabe der Anmelderangaben (E-Mail-Adresse und Passwort). <br /> 
                2 - Auswahl der Tickets<br /> 
                3 - Klick auf "Kaufen"<br /> 
                4 - Überprüfung der Waren im Warenkorb<br /> 
                5 - Bestätigung des Kaufs durch Wahl der Zahlungsart<br/>     
            <br/>

            </div>
            Der Verbrauchende kann vor dem verbindlichen Absenden der Bestellung durch Betätigen der in dem von ihm verwendeten Internet-Browser enthaltenen „Zurück“-Taste nach
            Kontrolle seiner Angaben wieder zu der Internetseite gelangen, auf der die Angaben des Kunden erfasst werden und Eingabefehler berichtigen bzw. durch Schließen des
            Internetbrowsers den Bestellvorgang abbrechen.
            Wir bestätigen den Eingang der Bestellung unmittelbar durch eine automatisch generierte E-Mail. Mit dieser nehmen wir Ihr Angebot an.
            Diese E-Mail beinhaltet das automatisch generierte Ticket.<br/><br/>

            (5) Speicherung des Vertragstextes bei Bestellungen über unseren Internetshop : Wir speichern den Vertragstext und senden Ihnen die Bestelldaten per E-Mail zu. 
            Die AGB können Sie jederzeit auch unter http://www.skalar-jne.de/AGB  einsehen.

                Ihre Tickets können Sie in ihrem Konto {'-->'} Meine Tickets einsehen.<br />

                <br/>

            (6) Für Unternehmende kommt der Vertrag bei Erstellung ihres Events und dazugehöriger Vorstellungen und Preisklassen zustande.<br/><br/>

            <p className='paragraphHeader'>§3 Preise, Versandkosten, Zahlung, Fälligkeit</p>
            (1) Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer und sonstige Preisbestandteile. Hinzu kommen etwaige Versandkosten.<br/> <br/>

            (2) Der Verbrauchende hat die Möglichkeit der Zahlung per 
                        Barzahlung an der Abendkasse,<br/>
                        PayPal,<br/>
                        sowie weitere durch PayPal angebotene Zahlungsarten falls nicht anders durch den Unternehmenden festgelegt.<br/><br/>

            (3) Soweit Barzahlung an der Abendkasse als Zahlungsart gewählt wird, tritt die Fälligkeit der Kaufpreisforderung mit Beginn der Veranstaltung ein.<br/><br/>

            <p className='paragraphHeader'>§4 Lieferung</p>
            (1) Sofern wir dies in der Produktbeschreibung nicht deutlich anders angegeben haben, sind alle von uns angebotenen Artikel sofort versandfertig.
            Die Lieferung erfolgt hier spätestens innerhalb von 1 Werktagen.<br/><br/>

            <br/>
            <p className='paragraphHeader'>§5 Eigentumsvorbehalt</p>
            Wir behalten uns das Eigentum an der Ware bis zur vollständigen Bezahlung des Kaufpreises vor. <br/><br/>

            <br />
            <p className='paragraphHeader'>§6 Widerrufsrecht des Kunden als Verbrauchende
            </p>

            <b>Widerrufsrecht für Verbrauchende<br/></b>
            <br/>
            Verbrauchenden steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei Verbrauchende jede natürliche Person ist,
            die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen
            beruflichen Tätigkeit zugerechnet werden können:<br/><br/>

            <p className='paragraphHeader'>Widerrufsbelehrung</p><br />

            <b>Widerrufsrecht</b><br/><br/>

            Sie haben das Recht, bis zum Beginn der Gültigkeit der Tickets ohne Angabe von Gründen diesen Vertrag zu widerrufen.
            <br/><br/>


            Um Ihr Widerrufsrecht auszuüben, müssen Sie uns<br />
            <div className='twoEM'>
                jne-solutions UG (haftungsbeschränkt)<br />
                Jan Jeschinski<br />
                Havemannstraße<br />
                D-12689 Berlin<br />
                E-Mail jeschinski.jan@t-online.de<br />
                    </div>
            mittels einer eindeutigen Erklärung (ausschließlich: Formular in Ihrem Nutzerprofil unter "Mein Konto" {'-->'} "Meine Tickets" {'-->'} "Ticket anklicken") über Ihren Entschluss,
            diesen Vertrag zu widerrufen, informieren. Widerrufe die auf anderen Wegen eingehen, können nicht berücksichtigt werden.<br/><br/>

            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Beginn der Gültigkeit der Tickets absenden.<br/><br/>


            <b>Widerrufsfolgen</b><br /><br />
            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
            einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben,
            dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
            unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
            Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel,
            das Sie bei der ursprünglichen Transaktion eingesetzt haben;
            Für den Widerruf fallen in einem Zeitraum von 72 Stunden (3 Tagen) ab Bestellung, aber 48 Stunden (2 Tage) vor Beginn der Gültigkeit der Tickets keine Gebühren an.
            Andernfalls entstehen Gebühren in Höhe von 1,00€, aber maximal dem gezahlten Betrag pro technischem Ticket.<br/>
            Bitte beachten Sie, dass Ihr Code mehrere Tickets enthalten kann.<br/><br/>

            
            <b>Ende der Widerrufsbelehrung</b><br/><br/>

            <p className='paragraphHeader'>§7 Widerrufsrecht des Kunden als Unternehmende
            </p>

            <b>Widerrufsrecht für Unternehmende<br/></b>
            <br/>
            Unternehmende steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei Unternehmende natürliche und juristische Person, sowie Rechtsträger, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen 
            oder selbstständigen beruflichen Tätigkeit handelt:<br/><br/>

            <p className='paragraphHeader'>Widerrufsbelehrung</p><br />

            <b>Widerrufsrecht</b><br/><br/>

            Sie haben das Recht, bis zum Beginn der Einzelvorstellungen eines Events ohne Angabe von Gründen diesen Vertrag zu widerrufen.
            <br/><br/>


            Um Ihr Widerrufsrecht auszuüben, müssen Sie uns<br />
            <div className='twoEM'>
                jne-solutions UG (haftungsbeschränkt)<br />
                Jan Jeschinski<br />
                Havemannstraße<br />
                D-12689 Berlin<br />
                E-Mail jeschinski.jan@t-online.de<br />
                    </div>
            mittels einer eindeutigen Erklärung (ausschließlich: Formular in Ihrem Nutzerprofil unter "Mein Konto" {'-->'} "Meine Events" {'-->'} "Event anklicken" {'-->'} "Vorstellungen" {'-->'} "Vorstellung absagen") über Ihren Entschluss,
            diesen Vertrag zu widerrufen, informieren. Widerrufe die auf anderen Wegen eingehen, können nicht berücksichtigt werden.<br/><br/>

            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Beginn der Gültigkeit der Tickets absenden.<br/><br/>


            <b>Widerrufsfolgen</b><br /><br/>
            Wenn Sie diesen Vertrag widerrufen, haben wir den Verbrauchenden alle Zahlungen, die wir erhalten haben,
            einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben,
            dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
            unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
            Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel,
            das bei der ursprünglichen Transaktion eingesetzt wurde;<br/>

            Für die Verbrauchenden fallen dafür in keinem Fall Gebühren an.<br/><br/>
            Handelt es sich bei Ihrem Event um ein Event mit Umsatzabsicht (Tickets sind kostenpflichtig), fallen für Sie Gebühren in Höhe von 1,00 € pro verkaufem Ticket an.
            Sie werden über die exakte Höhe der Forderung unverzüglich per E-Mail informiert. Im Normalfall verrechnen wir diesen Betrag in zukünftigen Auszahlungen und weisen dies so aus.
            Falls Sie den Betrag auf anderen Wegen begleichen möchten, kontaktieren Sie bitte unseren Support. Genauere Anweisungen dazu werden Ihnen mit der Miteilung über die 
            Höhe der Forderung zugesandt.<br/><br/>
            
            <b>Ende der Widerrufsbelehrung</b><br/><br/>

            <p className='paragraphHeader'>§8 Ergänzende Informationen für Unternehmenden</p>
            (1) Es werden zwei verschiedene Angebotsmodi bereitgestellt.<br/>
                <div className='twoEM'>
                1 - Kostenlos ("Free-Tier")<br/>
                    <div className='twoEM'>
                        Dieser Modus steht jedem Unternehmenden zur Verfügung. Bei Nutzung dieses Modus erklären Sie sich damit einverstanden, dass wir auf ihren Angebotsseiten, 
                        Werbung über unsere Werbepartner anzeigen. Für Sie entstehen in keinem Fall Kosten.<br/><br/>
                    </div> </div>
                <div className='twoEM'>
                    2 - Mit Gewinnabsicht ("Paid-Tier")<br/>
                    <div className='twoEM'>
                        In diesem Modus haben Tickets einen Mindestpreis in Höhe von 0,50 €. Es wird keine Werbung angezeigt. Es fallen Gebühren (siehe §8 Nr. 2) an.
                    </div>
                </div><br/><br/>

            (2) Gebühren: <br/>
            <div className='twoEM'>
            1. Verkauf <br/>
            <div className='twoEM'>
                Für von uns verkaufe Tickets fallen Gebühren pro verkaufem Ticket an. Die Gebühren setzen sich sofern nicht anders vereinbart wie folgt zusammen:<br/><br/>
                
                Für einen Preis bis einschließlich 7,00 € pro Ticket:<br/>
                2,5% vom Gesamtpreis + 0,50 €<br/><br/>
                Für einen Preis ab ausschließlich 7,00 € pro Ticket:<br/>
                2,5% vom Gesamtpreis + 1,00 €<br/><br/>
                </div>

            2. Absage <br/>
            <div className='twoEM'>
                Bei Absage einer Vorstellung mit Gewinnabsicht (Tickets sind kostenpflichtig), fallen für Sie Gebühren in Höhe von 1,00 € pro verkaufem Ticket an.
                Diese Forderung wird Ihnen unverzüglich per Mail gesendet und im Normalfall bei Ihrer nächsten Auszahlung verrechnet. Falls Sie den Betrag auf anderen 
                Wegen begleichen möchten, kontaktieren Sie bitte unseren Support. Genauere Anweisungen dazu werden Ihnen mit der Miteilung über die 
                Höhe der Forderung zugesandt.<br/><br/>
            </div>
            </div>


            (3) Auszahlung:<br/>
            <div className='twoEM'>
                Die Auszahlung ihrer Umsätze exklusive Gebühren erfolgt nach Anforderung dieser über Ihr Profil ("Mein Konto" {'-->'} "Meine Events"). 
                Sie können die Auszahlung nach jeder Vorstellung anfordern. Es werden dann alle noch nicht ausgezahlen Beträge ausgezahlt. Sie erhalten einen Beleg über die Auszahlung per Mail.<br/>
                Bestehen noch offene Forderungen, werden diese mit Ihrer Auszahlung verrechnet und auf dem Beleg ausgewiesen.<br/>
                Die Auszahlung erfolgt in der Regel innerhalb von 14 Tagen auf Ihre bei der Registrierung angegebene Bankverbindung.
            </div>
            <br/>

            <p className='paragraphHeader'>§9 Gewährleistung</p>
            Es gelten die gesetzlichen Gewährleistungsregelungen.
            <br/><br/>

            <p className='paragraphHeader'>§10 Vertragssprache</p>
            Als Vertragssprache steht ausschließlich Deutsch zur Verfügung.
            <br/><br/>

            <br/>
            <p>Stand der AGB Apr.2023</p>
        </div>
    )
}

export default AGB