import React from 'react';
import { useSearchParams } from 'react-router-dom';
import loginToken from '../functionality/loginToken';
import { toNormalDateAndTime, toNormalNumber, replacePaymentNames } from '../functionality/normalizer';
import TMapper from './TicketItems';
import ConfirmationDialogue from './ConfirmationDialogue';
import LoadingAnimation from '../loading_components/LoadingAnimation';

//starting function
function Ticket(){
    const [searchParams] = useSearchParams();
    return(
        <TicketController bill={searchParams.get('b')}/>
    )
}

//class definitions
class TicketController extends React.Component{
    constructor(props){
        super();
        this.state = {
            BID: props.bill,
            paid: 0,
            paymentMethod: '',
            price: 0,
            eventTitle: '',
            eventDateTime: '',
            dateOfBilling: '',
            street: '',
            number: '',
            postal: '',
            city: '',
            tickets: [],
        }
        this.returnTickets = this.returnTickets.bind(this)
        this.toggleConfirmationDialogue = this.toggleConfirmationDialogue.bind(this)
        this.updateSelectedTickets = this.updateSelectedTickets.bind(this)
    }
    
    componentDidMount(){
        fetch(process.env.REACT_APP_TICKETVIEWER_API+process.env.REACT_APP_STAGE+"/ticket", {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                billID: this.state.BID
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var data = JSON.parse(text)
            console.log(data)
            this.setState({
                paid: data[0][0],
                paymentMethod: data[0][1],
                price: data[0][2],
                eventTitle: data[0][3],
                eventDateTime: data[0][4],
                dateOfBilling: data[0][5],
                street: data[0][6],
                number: data[0][7],
                postal: data[0][8],
                city: data[0][9],
                tickets: data[1],
                selectedTickets: [],
                numOfselectedTickets: 0,
                confirmationVisible: false,
                returnTarget: '',
                errorVisible: false,
            })
        })
    }

    async returnTickets(event, tar){
        console.log(tar)

        if(tar==='all') tar = this.state.tickets
        else tar = this.state.selectedTickets

        event.preventDefault();
        try{
            await fetch(process.env.REACT_APP_REFUND_API+process.env.REACT_APP_STAGE+'/initiaterefund', {
                method: 'POST',
                body: JSON.stringify({
                    key: loginToken.GetToken(),
                    tickets: tar,
                    paymentMethod: this.state.paymentMethod,
                    BID: this.state.BID,
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then (resp => resp.text())
            .then(text => {   
                if (text==='true'){
                    window.location.reload(false)
                } else {
                    this.setState({
                        errorVisible: true,
                    })
                }
            })
        } catch (err){
        }
    }

    toggleConfirmationDialogue(tar){
        this.setState({
            confirmationVisible: !this.state.confirmationVisible,
            returnTarget: tar, 
        })
    }

    updateSelectedTickets(tid, returnAmount, price, priceClassNumber){
        var temp = this.state.selectedTickets
        var updated = false
        if (temp.length !== 0){
            for (var i = 0; i < temp.length; i+=1){
                if (temp[i][0] === tid){
                    if (parseInt(returnAmount)!== 0){temp[i] = [tid, price, parseInt(returnAmount), priceClassNumber]}
                    else {temp.splice(i,1)}
                    updated = true
                }
            }
        }

        if(!updated && parseInt(returnAmount)!== 0){
            temp[temp.length] = [tid, price, parseInt(returnAmount), priceClassNumber]
        }

        this.setState({
            selectedTickets: temp,
            numOfselectedTickets: temp.length,
        })
    }

    render(){
        if (this.state.eventTitle === '') return <LoadingAnimation/>
        return(
            <div className='relPos'>
                <ConfirmationDialogue errorVisible={this.state.errorVisible} returnTicketHandler={this.returnTickets} keepTickets={this.toggleConfirmationDialogue} display={this.state.confirmationVisible} returnTarget={this.state.returnTarget} returnTickets={(this.state.returnTarget === 'all')? this.state.tickets:this.state.selectedTickets} />
                <div className='content centered'>
                    <h2 className='compensateH'>Informationen zur Veranstaltung</h2>
                    <div className='secColOutline'>
                        <div className="centerHeight"><b>{this.state.eventTitle}</b></div> <br/>
                        <div className="centerHeight"><span className="icon calenderIcon">Datum,Uhrzeit</span> {toNormalDateAndTime(this.state.eventDateTime)}</div><br/>
                        <div className="centerHeight"><span className="icon pointIcon">Ort </span> {this.state.street+' '+this.state.number+', '+this.state.postal+' '+this.state.city}</div><br/>
                    </div>
                    <div className='spacer'/>
                    <h2 className='compensateH'>Informationen zum Ticket</h2>
                    <div className='secColOutline'>
                        {(this.state.paymentMethod === 'free')?'':
                            (this.state.paid===0)?<>noch nicht bezahlt<br/></>:<>bezahlt mit {replacePaymentNames(this.state.paymentMethod)}<br/></>
                        }
                        {(this.state.paymentMethod === 'free')?'angefordert':'gekauft'} am: {toNormalDateAndTime(this.state.dateOfBilling)} <br/>
                        Ticketpreis gesamt: {toNormalNumber(this.state.price)} €<br/>
                    </div>
                    <div className='spacer'/>
                    <div className='autoWrap'>
                        <TMapper data={this.state.tickets} update={this.updateSelectedTickets}/>
                    </div>
                    <div>
                        <button className='btnClass2 normalPropotions' onClick={() => this.toggleConfirmationDialogue('all')} >Alle Tickets stornieren</button>
                        <button className='btnClass2 normalPropotions' disabled={(this.state.numOfselectedTickets === 0)?true:false} onClick={() => this.toggleConfirmationDialogue('selected')} >Ausgewählte Tickets stornieren</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default Ticket