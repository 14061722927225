import React from 'react';
import loginToken from '../functionality/loginToken'

function EditAddress(){
    loadData()

    return(
        <div className="content">
            <EditForm/>
        </div>
    )
}

//API calls
async function loadData(){
    await fetch(process.env.REACT_APP_ADDRESSHANDLER_API+process.env.REACT_APP_STAGE+'/address', {
        method: 'POST',
        body: JSON.stringify({
            key: loginToken.GetToken()
        }),
        headers: {
            'Content-Type': 'application/json'
        },
    }).then (resp => resp.text())
    .then (text =>{
        var obj = JSON.parse(text);
        document.getElementById("street").value = obj[1][2]
        document.getElementById("number").value = obj[1][3]
        document.getElementById("postal").value = obj[1][0]
        document.getElementById("city").value = obj[1][1]
    })
}

//class definitions
class EditForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            street: '',
            number: '',
            postal: '',
            city: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    async handleSubmit(event){
        event.preventDefault();
            try{
                await fetch(process.env.REACT_APP_ADDRESSHANDLER_API+process.env.REACT_APP_STAGE+'/changeaddress', {
                    method: 'POST',
                    body: JSON.stringify({
                        key: loginToken.GetToken(),
                        street: this.state.street,
                        number: this.state.number,
                        postal: this.state.postal,
                        city: this.state.city,
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then (resp => resp.text())
                .then(text => {
                    console.log(text)
                    var element = document.getElementById('result')
                    if (text !== "True"){
                        element.textContent = text
                        element.classList.add('errorVisible')
                    } else {
                        element.classList.remove('errorVisible')
                        element.textContent = "Änderungen gespeichert!"
                    }
                })
                
                event.preventDefault();
            } catch (err){
                var element = document.getElementById('error')
                element.classList.add('errorVisible')
                element.classList.remove('errorInvisible')
            }
    }

    render (){
        return(
        <form onSubmit={this.handleSubmit}>
            <h3>Adresse ändern</h3>
            Straße<br/> <input className='inputText' type="text" name="street" id="street" value={this.state.value} onChange={this.handleInputChange}/><br/> <br/> 
            Hausnummer<br/> <input className='inputText' type="text" name="number" id="number" value={this.state.value} onChange={this.handleInputChange}/><br/> <br/> <br/> 

            Postleitzahl <br/><input className='inputText' name="postal" id="postal" type="text" value={this.state.value} onChange={this.handleInputChange}/><br/><br/>
            Ort <br/><input className='inputText' type="text" name="city" id="city" value={this.state.value} onChange={this.handleInputChange}/>            
            <br/>     
            <center>
                <button type='submit' className='btnClass1 normalPropotions'>Änderungen speichern</button>
            </center>
            <div id='result'/>
        </form>
        )
    }

}


export default EditAddress