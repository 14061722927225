import '../siteContent/Slider.css'
import { toNormalDateAndTime, toNormalNumber } from '../functionality/normalizer'
import loginToken from '../functionality/loginToken'
import React from 'react'

class TopSlider extends React.Component{
    constructor(){
        super();
        this.state = {
            sliderData: [],
            def: './images/coverplaceholder.png',
            cardClasses:['card current-card',
                         'card next-card',
                         'card queue-card',
                         'card queue-card',
                         'card previous-card'
                        ],
            dotClasses:['dot extended-dot',
                        'dot small-dot',
                        'dot small-dot',
                        'dot small-dot',
                        'dot small-dot'
                        ],
            sliderStarted: false,
            slideIntervalId: 0,
        }
        this.nextCard = this.nextCard.bind(this)
        this.previousCard = this.previousCard.bind(this)
        this.startSlide = this.startSlide.bind(this)
        this.stopSlide = this.stopSlide.bind(this)
    }

    async componentDidMount(){
        await fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/getnearevents', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                distance: null,
                start: 0,
                end: 5,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var data = JSON.parse(text)
            this.setState({
                sliderData: data,
            });
        })
        if(!this.state.sliderStarted){
            this.startSlide()
        }
    } 

    nextCard(){
        let helper = this.state.cardClasses
        helper.unshift(helper.pop())

        let helper2 = this.state.dotClasses
        helper2.unshift(helper2.pop())

        this.setState({
            cardClasses: helper,
            dotClasses: helper2
        })
    }

    previousCard(){
        let helper = this.state.cardClasses
        helper.push(helper.shift())

        let helper2 = this.state.dotClasses
        helper2.push(helper2.shift())

        this.setState({
            cardClasses: helper,
            dotClasses: helper2
        })
    }

    startSlide(){
        var slideIntervalId = setInterval(this.nextCard, 10800);
        this.setState({
            sliderStarted: true,
            slideIntervalId: slideIntervalId,
        })
    }

    stopSlide(){
        clearInterval(this.state.slideIntervalId)
    }

    render(){
        return(
            <div className="slider">
                <button className="arrow arrow-left" onClick={() => {this.previousCard(); this.stopSlide()}}>
                    <span className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                <button className='invisibleBtnNext' onClick={() => {this.nextCard(); this.stopSlide()}}/>
                <button className='invisibleBtnPrev' onClick={() => {this.previousCard(); this.stopSlide()}}/>
                <div className="cardList">
                    <div className="cards_wrapper">
                        <a className={this.state.cardClasses[0]} href={(!this.state.sliderData[0])?  '':'/event?pid='+this.state.sliderData[0][0]+'&eid='+this.state.sliderData[0][6]}>
                            <div className="card_image">
                                <img src={(!this.state.sliderData[0])?this.state.def:this.state.sliderData[0][4]} className="image-card"/>
                                {(!this.state.sliderData[0])? '':
                                <div className="card_text">
                                    <h3 className='max3Lines'>{this.state.sliderData[0][1]}</h3>
                                    <p>{toNormalDateAndTime(this.state.sliderData[0][5])}<br/>
                                    ab nur <span className="green">{toNormalNumber(this.state.sliderData[0][3])} €</span></p>
                                </div>
                                }
                            </div>
                        </a>
                        <a className={this.state.cardClasses[1]} href={(!this.state.sliderData[1])?  '':'/event?pid='+this.state.sliderData[1][0]+'&eid='+this.state.sliderData[1][6]}>
                            <div className="card_image">
                                <img src={(!this.state.sliderData[1])?this.state.def:this.state.sliderData[1][4]} className="image-card"/>
                                {(!this.state.sliderData[1])? '':
                                <div className="card_text">
                                    <h3 className='max3Lines'>{this.state.sliderData[1][1]}</h3>
                                    <p>{toNormalDateAndTime(this.state.sliderData[1][5])}<br/>
                                    ab nur <span className="green">{toNormalNumber(this.state.sliderData[1][3])} €</span></p>
                                </div>
                                }
                            </div>
                        </a>
                        <a className={this.state.cardClasses[2]} href={(!this.state.sliderData[2])?  '':'/event?pid='+this.state.sliderData[2][0]+'&eid='+this.state.sliderData[2][6]}>
                            <div className="card_image">
                                <img src={(!this.state.sliderData[2])?this.state.def:this.state.sliderData[2][4]} className="image-card"/>
                                {(!this.state.sliderData[2])? '':
                                    <div className="card_text">
                                        <h3 className='max3Lines'>{this.state.sliderData[2][1]}</h3>
                                        <p>{toNormalDateAndTime(this.state.sliderData[2][5])}<br/>
                                        ab nur <span className="green">{toNormalNumber(this.state.sliderData[2][3])} €</span></p>
                                    </div>
                                }
                            </div>
                        </a>
                        <a className={this.state.cardClasses[3]} href={(!this.state.sliderData[3])?  '':'/event?pid='+this.state.sliderData[3][0]+'&eid='+this.state.sliderData[3][6]}>
                            <div className="card_image">
                                <img src={(!this.state.sliderData[3])?this.state.def:this.state.sliderData[3][4]} className="image-card"/>
                                {(!this.state.sliderData[3])? '':
                                    <div className="card_text">
                                        <h3 className='max3Lines'>{this.state.sliderData[3][1]}</h3>
                                        <p>{toNormalDateAndTime(this.state.sliderData[3][5])}<br/>
                                        ab nur <span className="green">{toNormalNumber(this.state.sliderData[3][3])} €</span></p>
                                    </div>
                                }
                            </div>
                        </a>
                        <a className={this.state.cardClasses[4]} href={(!this.state.sliderData[4])?  '':'/event?pid='+this.state.sliderData[4][0]+'&eid='+this.state.sliderData[4][6]}>
                            <div className="card_image">
                                <img src={(!this.state.sliderData[4])?this.state.def:this.state.sliderData[4][4]} className="image-card"/>
                                {(!this.state.sliderData[4])? '':
                                    <div className="card_text">
                                        <h3 className='max3Lines'>{this.state.sliderData[4][1]}</h3>
                                        <p>{toNormalDateAndTime(this.state.sliderData[4][5])}<br/>
                                        ab nur <span className="green">{toNormalNumber(this.state.sliderData[4][3])} €</span></p>
                                    </div>
                                }
                            </div>
                        </a>
                    </div>
                    <div className='dotwrapper'>
                        <div className={this.state.dotClasses[0]}/>
                        <div className={this.state.dotClasses[1]}/>
                        <div className={this.state.dotClasses[2]}/>
                        <div className={this.state.dotClasses[3]}/>
                        <div className={this.state.dotClasses[4]}/>
                    </div>
                </div>
                <button className="arrow arrow-right" onClick={() => {this.nextCard(); this.stopSlide()}}>
                    <span className="material-symbols-outlined">arrow_forward_ios</span>
                </button>

            </div>
        )
    }
}

export default TopSlider