import Switch from '@mui/material/Switch';
import { toNormalDate } from '../../functionality/normalizer';

//site components
function Bank(props){
    return(
        <div className="higherLine secColOutline">
            <h3 className="smallHeading">Bankverbindung
            <span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">angelehnt an: §14 (2) Nr. 6-7 / §14 (3) Nr. 7 PStTG</span><br/>
            </div></h3>
            <b>IBAN</b><br/>
            <input className="inputText" value={props.data.iban} onChange={(e) => props.updateValue('iban', e.target.value)} />
            <b>BIC</b><br/>
            <input className="inputText" value={props.data.bic} onChange={(e) => props.updateValue('bic', e.target.value)} />

            <b>Kontoinhaber:</b><br/>
            <Switch onChange={() => props.updateValue('bankOwnerNotName', !props.data.bankOwnerNotName)}/>Abweichender Kontoinhaber<br/>
            {(props.data.bankOwnerNotName)?<ReceiverNotEqual data={props.data} updateValue={props.updateValue} />:<ReceiverEqual data={props.data}/>}
        </div>
    )
}

function ReceiverEqual(props){
    return(
        <>
        Name: {props.data.name}<br/>
        {(props.data.type==='naturalPerson')?<>Geburtsdatum: {toNormalDate(props.data.personDateOfBirth)}<br/></>:''}
        Adresse: {props.data.street} {props.data.number}, {props.data.postal} {props.data.city}
        </>
    )
}

function ReceiverNotEqual(props){
    return(
        <>
        <b>Name</b><br/>
        <input className="inputText" value={props.data.bankOwnerName} onChange={(e) => props.updateValue('bankOwnerName', e.target.value)} placeholder='Zuname, Vorname'/><br/>
        <b>Geburtsdatum</b><br/>
        <input type='date' className="inputText" value={props.data.bankOwnerDateOfBirth} onChange={(e) => props.updateValue('bankOwnerDateOfBirth', e.target.value)}/><br/>
        <b>Adresse:</b><br/>
        Straße, Hausnummer <br/>
            <div className="parallel">
                <input className="inputText inputText_narrow" type='text' onChange={(e) => props.updateValue('bankOwnerStreet', e.target.value)} placeholder='Musterweg'/>
                <div className="spacer"/>
                <input className="inputText number" type='text' onChange={(e) => props.updateValue('bankOwnerNumber', e.target.value)}  placeholder='1'/>
            </div> 
            PLZ, Stadt<br/>
            <div className="parallel">
            <input className="inputText number" type='number' onChange={(e) => props.updateValue('bankOwnerPostal', e.target.value)}  placeholder='12345'/>
            <div className="spacer"/>
            <input className="inputText inputText_narrow" type='text' onChange={(e) => props.updateValue('bankOwnerCity', e.target.value)}  placeholder='Musterort'/>
            </div>
        </>
    )
}

export default Bank