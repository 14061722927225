//Delete Token when "Logoff" is pressed
function DeleteToken() {
    sessionStorage.removeItem('token')
}

//Get Token from localStorage
function GetToken() {
    return sessionStorage.getItem('token')
}

//Store Token with encryption of obvious signs in sessionStorage
function SetToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

export default {DeleteToken, GetToken, SetToken}