//site components 
function Address(props){
    return(
        <div id='address' className='profileInvisible centered positionOverlap'>
            <div className='twoRows'>
                <AddressItems/>
                <div className='profileSpacer'/>
                <AddressData baseDat={props.baseDat}/>
            </div> <br/>
            <EditBtn/>
        </div>
    )
}

function AddressData(props){
    return(
        <div>
            {props.baseDat[5]}<br/>
            {props.baseDat[6]}<br/>
            <br/>
            {props.baseDat[3]}<br/>
            {props.baseDat[4]}<br/>
        </div>
    )
}

function AddressItems(){
    return(
        <div>
            Straße:<br/>
            Hausnummer:<br/>
            <br/>
            Postleitzahl:<br/>
            Ort:<br/>
        </div>
    )
}

function EditBtn(){
    return(
        <center>
            <a href='/editAddress'>
                <button className='btnClass2 normalBtnWidth' text>Anschrift bearbeiten</button> 
            </a>
        </center>
    )
}

export default Address