//site componnets
const EventItems= ({data, toggleStatistics}) =>(
    <>
    {data.map(data => (
        <>
        <div className="eventPane leftAndRight relPos" >
                <a className='eventPane noBG noUnderline' href={'/viewEvent?eid='+data[0]}>
                    <img className="imgContainer" alt="Event Cover Bild" src={data[3]}/>
                    <div className='paneText'>
                        <h3>{data[1]}</h3>
                        <p>{data[2]}</p>
                    </div>
                </a>
                <div className='statisticShowerBtn'>
                    <button className='btnClass1 normalPropotions' onClick={() => toggleStatistics(data[0], data[1])}>Statistiken</button>
                </div>
        </div>
        <br/>
        </>
    ))}
    </>
)

function ProfileHostOverview(props){
    return(
        <div className='positionOverlap' id='profileOverview'>
            <center><h3 id='ticketTitle'>Meine aktuellen Events</h3></center>
            <EventItems data={props.data} toggleStatistics={props.toggleStatistics}/>
        </div>
    )
}

export default ProfileHostOverview