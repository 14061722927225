function Copyright(){
    return(
        <div className="content">
            <h1>Urheberrecht</h1>
            <div className="twoEM">
                Inhalte auf dieser Website sind nach §2 UrhG urherberrechtlich geschützt.<br/>
                Dazu zählen unter anderem:
                <ul>
                    <li>Logos</li>
                    <li>Quellcode</li>
                    <li>Nutzergenerierte Inhalte wie Coverbilder, Tickethintergründe und Saalpläne</li>
                    <li>Standardcover und Standardtickethintergründe von Skalar.</li>
                </ul>
                Für urheberrechtlich geschütze nutzergenerierte Inhalte bleiben alle Rechte bei den Urhebern. Skalar verwendet diese nur im Sinne der Leistungserbringung in Annahme
                einer impliziten Zustimmung zur Verwendung dieser zu genanntem Zweck.
            </div>
        </div>
    )
}

export default Copyright