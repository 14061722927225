import React from "react"

//class definition
class IDNumbers extends React.Component{
    constructor(){
        super()
        this.state={
            taxID_valid: '',
            valAddedTaxID_valid: true,
        }
        this.checkIDs = this.checkIDs.bind(this)
    }

    checkIDs(idType, id){
        if (id==='' && idType === 'valAddedTaxID'){
            this.setState({
                valAddedTaxID_valid: '',
            })
            return
        } else if (id==='' && idType === 'taxID'){
            this.setState({
                taxID_valid: '',
            })
            return
        }

        fetch(process.env.REACT_APP_TAX_API+process.env.REACT_APP_STAGE+'/paidCheckID', {
            method: 'POST',
            body: JSON.stringify({
                idType: idType,
                id: id,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            if(text === 'True') var bool = true
            else var bool = false
            this.setState({
                [idType+'_valid']: bool,
            })
            if (this.state.taxID_valid && this.state.taxID_valid!=='' && this.state.valAddedTaxID_valid){
                this.props.updateValue('IDsValid', true)
            } else {this.props.updateValue('IDsValid', false)}
        })
    }

    render(){
        return(
            <div className="secColOutline">
                <h3 className="smallHeading">Identifikationsnummern
                <span className="icon iconAccentCol smallicon infoIcon">Infos</span>
                <div className="helpText">
                    <span className="smallText">angelehnt an: §14 (2) Nr. 3-4 / §14 (3) Nr. 3-5 PStTG</span><br/>
                </div></h3>
                <b>Steueridentifikationsnummer</b><br/>
                <span className={(this.state.taxID_valid || this.state.taxID_valid === '')?'errorInvisible':'errorVisible'} >Sie müssen eine gültige Steuer-ID angeben!</span>
                <input className={(this.state.taxID_valid)?'inputText':'inputText inputWarning'} value={this.props.data.taxID} onChange={(e) => this.props.updateValue('taxID', e.target.value)} onBlur={(e) => this.checkIDs('taxID', e.target.value)} />
                <span className={(this.state.valAddedTaxID_valid || this.state.valAddedTaxID_valid === '')?'errorInvisible':'errorVisible'} >Sie müssen eine gültige Umsatzsteuer-ID angeben!</span>
                <b>Umsatzsteuernummer (falls vorhanden)</b><br/>
                <input className={(this.state.valAddedTaxID_valid)?'inputText':'inputText inputWarning'} value={this.props.data.valAddedTaxID} onChange={(e) => this.props.updateValue('valAddedTaxID', e.target.value)} onBlur={(e) => this.checkIDs('valAddedTaxID', e.target.value)} />
                {(this.props.type==='naturalPerson')?'':
                <>
                <b>Handelsregisternummer</b><br/>
                <input className="inputText" value={this.props.data.compRegNumber} onChange={(e) => this.props.updateValue('compRegNumber', e.target.value)} />
                </>
                }
            </div>
        )
    }
}

export default IDNumbers