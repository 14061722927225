import { toNormalNumber } from "../functionality/normalizer"

//starting function
function ConfirmationDialogue(props){
    return(
        <>
        {(!props.display)? '':
        <div className="eventStatisticsBackground fullCenter">
            <center>
                <h3> {(props.returnTarget === 'all')?'Alle':'Folgende'} Tickets stornieren?</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Preisklasse</td>
                            <td>Anzahl</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ItemMapper returnTickets={props.returnTickets}/>
                    </tbody>
                </table><br/>
                Deine Rückerstattung beträgt: <span className="emphasized">{calculateReturnValue(props.returnTickets)} € </span>
                <span className="icon iconAccentCol smallicon infoIcon">Infos</span>
                <div className="helpText">
                    <span className="smallText">
                        Dies ist ein Wert der zum aktuellen Zeitpunkt berechnet wurde.<br/>
                        Die endgültige Erstattung wird bei der technischen Stornierung berechnet.<br/>
                        Im Ausnahmefall kann der tatsächliche Erstattungsbetrag abweichen.<br/> <br/> 
                        Dies passiert aber nur, wenn der kostenlose Erstattungszeitraum in der<br/> 
                        Zeit zwischen Pop-Up und Bestätigung abgelaufen ist und als Zahlungsart<br/> 
                        eine von PayPal verwaltete Methode gewählt wurde.
                    </span><br/>
                </div>
                <div>
                <button className="btnClass1 normalPropotions" onClick={() => props.keepTickets('')}>Tickets behalten</button> 
                <button className="btnClass2 normalPropotions" onClick={(e) => props.returnTicketHandler(e, props.returnTarget)}>Tickets stornieren</button>
                </div>
                {(props.errorVisible)?
                <b className="errorVisible">
                    Der Service ist vorübergehend nicht verfügbar.<br/> Bitte versuche es später erneut.
                </b>:''}
                <div className="smallText">
                    Mit Klick auf "Tickets stornieren" erklären Sie sich mit den <a className='noUnderline' href='/AGB' target='_blank'>allgemeinen Geschäftsbedingungen</a> einverstanden.<br/> 
                    Stornierte Tickets werden umgehend wieder in den Verkauf gebracht.
                </div>
            </center>
        </div>
        }
        </>
    )
}

//site components
const ItemMapper = ({returnTickets}) => (
    <>
    {returnTickets.map(returnTickets => (
        <tr>
            <td>{returnTickets[3]}</td>
            <td>{returnTickets[2]}</td>
        </tr>
    ))}
    </>
)

//functionality
function calculateReturnValue(returnTickets){
    var price = 0
    for (var i = 0; i<returnTickets.length; i+=1){
        price += returnTickets[i][1] * returnTickets[i][2]
    }

    return toNormalNumber(price)
}

export default ConfirmationDialogue