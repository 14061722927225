import React from 'react';
import { useSearchParams } from 'react-router-dom';

//starting function
function Activate(){
    const [searchParams] = useSearchParams();
    return(
        <ActState k={searchParams.get('key')}/>
    )
}

//functions called via user actions
function NoSuccess(){
    return(
        <div className='centered'>
            <h1 className='errorVisible'>Aktivierung fehlgeschlagen</h1>
            <h3>Vielleicht ist dein Link schon älter als 48 Stunden. Dann kannst du dich einfach nochmal registrieren.</h3>
            <a href="/register">
                <button className="btnClass1 normalBtnWidth">Zur Registrierung</button>
            </a>
        </div>
    )
}

function Success(){
    return(
        <div className='centered'>
            <h1>Dein Account ist aktiviert</h1>
            <h3>Du kannst dich jetzt Einloggen und alle Funktionen nutzen.</h3>
            <a href="/">
                <button className="btnClass1 normalBtnWidth">Zur Startseite</button>
            </a>
        </div>
    )
}

//class definitions
class ActState extends React.Component{
    constructor(){
        super();
        this.state = {
            text: ''
        }
    }
    
    componentDidMount(){
        fetch(process.env.REACT_APP_REGISTRATION_API+process.env.REACT_APP_STAGE+"/activate?key="+this.props.k)
        .then (resp => resp.text())
        .then(text => {
            this.setState({
                text: text
            })
        })
    }

    render(){
        if (this.state.text === '')
            return 
        else if (this.state.text === "True")
            return <Success/>
        else
            return <NoSuccess/>
    }
}

export default Activate