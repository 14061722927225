import React from "react"
import loginToken from "../../functionality/loginToken"

//clas definition
class SendBtn extends React.Component{
    constructor(){
        super()
        this.state={
            agb: false,
            liability: false,
            errMessage: '',
        }
    }

    sendData(){
        fetch(process.env.REACT_APP_REGISTRATION_API+process.env.REACT_APP_STAGE+'/regforpaid', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                data: this.props.data
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            if(text!== 'True'){
                this.setState({
                    errMessage: text
                })
            } else {
                window.location.replace('/myProfile')
            }
        })
    }

    toggleBool(tar, val){
        this.setState({
            [tar]: !val
        })
    }

    render(){
        return(
            <div>
                <input type='checkbox' checked={this.state.agb} onChange={() => this.toggleBool('agb',this.state.agb)}/>Ich habe die <a className='noUnderline' href='/AGB' target='_blank'>allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiere diese. <br/>
                <input type='checkbox' checked={this.state.liability} onChange={() => this.toggleBool('liability',this.state.liability)}/>Ich bin mir bewusst, dass ich für hier angegebene Informationen sowie deren Aktualität verantwortlich bin und für falsche Angaben hafte.<br/>
                <center>
                    <span className="errorVisible">{this.state.errMessage}</span>
                    <button className="btnClass1 normalPropotions oneLine largerText" disabled={!(this.state.agb && this.state.liability)} onClick={() => this.sendData()}>Jetzt freischalten</button>
                </center>
            </div>
        )
    }
}

export default SendBtn