import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import './loading_components/loader.css'
import Navigation from './navAndFoot/Nav';
import Activate from './siteContent/Activate';
import Landing from './siteContent/Landing';
import Register from './register_components/Register';
import RegisterHost from './register_components/RegisterHost';
import RegSuccess from './register_components/RegSucess';
import Profile from './profile_components/Profile';
import NotFound from './siteContent/NotFound';
import EditOwnData from './siteContent/EditOwnData';
import EditAddress from './siteContent/EditAddress';
import AddEvent from './siteContent/AddEvent';
import Event from './siteContent/Event';
import AllEvents from './siteContent/AllEvents';
import ViewEvent from './siteContent/ViewEvent';
import Admin from './admin_components/Admin';
import PWReset from './siteContent/PWReset';
import PaidRegister from './register_components/PaidRegister';
import ResetPassword from './siteContent/ResetPassword';
import Footer from './navAndFoot/Foot';
import Ticket from './ticket_components/Ticket';
import AGB from './infos/AGB';
import Datenschutz from './infos/Datenschutz';
import Impressum from './infos/Impressum';
import Contact from './infos/Contact';
import Copyright from './infos/Copyright';

function App() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <Navigation/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/activate' element={<Activate />} />
          <Route path='/admfunctions' element={<Admin />} />
          <Route path='/agb' element={<AGB />} />
          <Route path='/allEvents' element={<AllEvents />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/copyright' element={<Copyright />} />
          <Route path='/dsgvo' element={< Datenschutz />} />
          <Route path='/editAddress' element={<EditAddress />}/>
          <Route path='/editOwnData' element={<EditOwnData />}/>
          <Route path='/event' element={<Event />}/>
          <Route path='/hostRegister' element={<RegisterHost />} />
          <Route path='/impressum' element={<Impressum />} />
          <Route path='/myProfile' element={<Profile />}/>
          <Route path='/newEvent' element={<AddEvent />}/>
          <Route path="/register" element={<Register />} />
          <Route path='/regSuccess' element={<RegSuccess />}/>
          <Route path='/registerForPaid' element={<PaidRegister />}/>
          <Route path="/requestpwreset" element={<PWReset />} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path='/viewEvent' element={<ViewEvent />}/>
          <Route path='/*' element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </React.StrictMode>
);}

App()
