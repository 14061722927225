import React from "react"

function Contact(){
    return(
        <div className="content">
            <h1>Kontakt</h1>
            <div className="twoEM">
                <b>Bitte nutzen Sie für die Kontaktaufnahme ausschließlich unser Kontaktformular:</b><br/><br/>
                <ContactForm/>
            </div>
        </div>
    )
}

//site components
function Detailed(props){
    return(
        <>
        <b>Details</b><br/>
        <textarea id='description' className='inputText' type='text' placeholder='Beschreiben Sie ihr Anliegen in ein paar Sätzen (maximal 2500 Zeichen)' maxLength='2500' rows='10' value={props.description}  onChange={(e) => props.updateData('description',e.target.value)}/>
        </>
    )
}

function Mail(props){
    return(
        <>
        <b>E-Mail für Rückfragen und Updates</b><br/>
        <input type="text" placeholder={props.mail} className="inputText" value={props.mail} onChange={(e) => props.updateData('mail',e.target.value)}/>
        </>
    )
}

function ReasonSelector(props){
    return(
        <>
        <b>Grund</b><br/>
        <select onChange={(e) => props.updateData('reason', e.target.value)} className='inputText'>
            <option value=""></option>
            <option value="ticket">Ich habe mein Ticket nicht erhalten.</option>
            <option value="return">Ich habe eine Rückerstattung nicht erhalten.</option>
            <option value="question">Ich habe eine allgemeine Frage.</option>
            <option value="report">Ich möchte ein Event melden (Grund bitte angeben).</option>
            <option value="other">Etwas anderes (bitte angeben)</option>
        </select>
        </>
    )
}

function SendBtn(props){
    return(
        <center>
            <button id="sendBtn" className='btnClass1 normalPropotions' onClick={() => props.send()}>Absenden</button>
        </center>
    )
}

function Summary(props){
    return(
        <>
        <b>Zusammenfassung</b><br/>
        <input type="text" placeholder={''} className="inputText" value={props.summary} onChange={(e) => props.updateData('summary',e.target.value)}/>
        </>
    )
}

//class definition
class ContactForm extends React.Component{
    constructor(){
        super();
        this.state = {
            reason: '',
            summary: '',
            description: '',
            mail: '',
        }
        this.send = this.send.bind(this)
        this.updateData = this.updateData.bind(this)
    }

    send(){
        fetch(process.env.REACT_APP_CONTACT_API+process.env.REACT_APP_STAGE+'/openTicket', {
            method: 'POST',
            body: JSON.stringify({
                origin: 'skalar',
                category: this.state.reason,
                shortDescription: this.state.summary,
                longDescription: this.state.description,
                openerMail: this.state.mail,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            if (text!=='True'){
                window.alert('Ihre Anfrage konnte nicht abgesendet werden! Bitte versuchen Sie es später erneut.')
            } else {
                var elem = document.getElementById('sendBtn')
                elem.textContent = "Anfrage gesendet!"
            }
        })
    }

    updateData(tar, val){
        this.setState({
            [tar]: val,
        })
    }

    render(){
        return(
            <>
                <ReasonSelector reason={this.state.reason} updateData={this.updateData}/>
                {(this.state.reason === '')? '':
                <>
                <Summary updateData={this.updateData} summary={this.state.summary} />
                {this.state.reasonShort}
                <Detailed updateData={this.updateData} description={this.state.description} />
                <Mail updateData={this.updateData} mail={this.state.mail} />
                {(this.state.mail !== '' && this.state.description !== '')?<SendBtn send={this.send} />:'' }
                </>
                }
            </>
        )
    }
}

export default Contact