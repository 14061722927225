//Delete order data when buy is done or aborted
function deleteOrderCache() {
    sessionStorage.removeItem('orderCache')
}

//Get order data from localStorage
function getOrderCache() {
    return JSON.parse(sessionStorage.getItem('orderCache'))
}

//Set order data to local storage
function setOrderCache(data){
    sessionStorage.setItem('orderCache', JSON.stringify(data));
}

export {deleteOrderCache, getOrderCache, setOrderCache}