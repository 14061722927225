import React from "react"

//site components
function Cash(c){
    return(
        <div>
            <input type='checkbox' id='cash_box' defaultChecked={(c.checked==='False')?false:true}/>Bar an der Abendkasse
        </div>
    )
}

function PayPal(c){
    return(
        <div>
            <input type='checkbox' id='paypal_box' defaultChecked={(c.checked==='False')?false:true}/>PayPal-Zahlungsarten
        </div>
    )
}

export {Cash, PayPal}