import React from "react"

class PWReset extends React.Component{
    constructor(){
        super()
        this.state = {
            email: "",
            response:''
        }
        this.send = this.send.bind(this)
        this.updateState = this.updateState.bind(this)
    }

    send(){
        fetch(process.env.REACT_APP_PASSWORDHANDLER_API+process.env.REACT_APP_STAGE+'/requestpwreset', {
            method: 'POST',
            body: JSON.stringify({
                email: this.state.email
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            this.setState({
                response: text
            })
        })
    }

    updateState(tar, val){
        this.setState({
            [tar]: val
        })
    }

    render() {
        return(
            <div className="content centered">
                <h1>Passwort zurücksetzen</h1>
                <p>E-Mail eingeben:<br/></p>
                <input type='text' className="inputText inputText_narrow" placeholder="beispiel@email.de" onChange={(e) => this.updateState('email',e.target.value)}/>
                <span className="errorVisible">{this.state.response}</span>
                <button className="btnClass1 normalPropotions" onClick={() => this.send()} >Absenden</button>
            </div>
        )
    }
}

export default PWReset