import React from "react"
import loginToken from "../functionality/loginToken"

//starting function
function Check(props){
    return(
        <div>
            <h1>Daten überprüfen</h1>
            Sie können über die Schritte oben zu den einzelnen Eingabeseiten springen.<br/><br/>
            <LoadError/>
            <Step1and5 baseData={props.baseData} coverImage={props.coverImage} paymentInfo={props.paymentInfo} />
            <Step4 ticketData={props.ticketData} bgImage={props.bgImage} />
            <CreateEventBtn baseData={props.baseData} paymentInfo={props.paymentInfo} ticketData={props.ticketData} />
        </div>
    )
}

//site components
function CreateEventBtn(props){
    return(
        <div>
            <center>
                <span id='submitError' className="errorInvisible">Beim übermitteln der Daten ist ein Fehler aufgetreten!</span>
                <p>
                    <span><button onClick={(e) => commit(e, props.baseData, props.paymentInfo, props.ticketData)} className="btnClass1 normalPropotions largerText">Event erstellen und zur Übersichtsseite</button></span>
                </p>   
                <br/>
            </center> 
        </div>
    )
}

function LoadError(){
    return(
        <div className="errorInvisible" id='loadError'>
            Es ist ein Fehler beim Laden der Daten aufgetreten!
        </div>
    )
}

function Step1and5(props){
    return(
        <div>
            <div className="parallel">
                <div className="third_screen">
                    <h2>Grunddaten</h2>
                </div>
                <div className="third_screen"/>
                <div className="third_screen">
                    <h2>Zahlungsarten</h2>
                </div>
            </div>
            <div className="parallel">
                <div className="third_screen">
                    <p><span className="specialCol">Coverbild:</span><br/> </p>
                    <center><img id='base_cover' className="coverPicture" src={props.coverImage} alt='Coverbild'/></center>
                </div>
                <div className="third_screen">
                    <p><span className="specialCol">Titel:</span><br/><br/> {props.baseData.title} <br/><br/></p>
                    <p><span className="specialCol">Beschreibung:</span><br/><br/> {props.baseData.description} <br/><br/></p>
                </div>
                <div className="third_screen">
                    <p>
                        <span className="specialCol">Zugelassene Zahlungsarten sind:</span><br/><br/>
                        Bar an der Abendkasse<br/>
                        PayPal<br/>
                        Überweisung<br/><br/>
                    </p>
                    <p>
                        <span className="specialCol">Nicht zugelassen sind:</span><br/><br/>
                        keine (Sie können das im nächsten Schritt ändern)<br/><br/>
                    </p>
                </div>
            </div>
        </div>
    )
}

class Step4 extends React.Component{

    componentDidMount(){
        if(this.props.ticketData === null) return
        document.getElementById('qrBox1').style = this.props.ticketData[4]['qrPos']
        document.getElementById('ticketPicture1').style = this.props.ticketData[4]['imgPos']
        document.getElementById('ticketEventTitle1').style = this.props.ticketData[0]['eventTitle_style']
        document.getElementById('ticketEventPlace1').style = this.props.ticketData[2]['eventPlace_style']
        document.getElementById('ticketEventPrice1').style = this.props.ticketData[3]['eventPrice_style']
        document.getElementById('ticketEventTime1').style = this.props.ticketData[1]['eventTime_style']
    }

    render(){
        if(this.props.ticketData === null) return
        return(
            <div>
                <h2>Ticketdesign</h2>
                <div className="centered">
                    <div id='ticketBG'className="ticketOutline">
                        <div id='qrBox1' className='qrBox'>
                            <img src='./images/jne-events-ticket.png' className='qrCodeJNETitle' draggable="false" alt='Skalar Schriftzug'/>
                            <img src='./images/qrcode_placeholder.png' className='qrCodePlaceholder' draggable="false" alt='QRCode'/>
                        </div>
                        <div>
                            <img id='ticketPicture1' className='ticketPicture' src={this.props.bgImage} draggable="false" alt='Coverbild'/>
                        </div>
                        <div className='ticketEventText ticketMaxWidth' id='ticketEventTitle1'>{this.props.ticketData[0]['eventTitle']}</div>
                        <div className='ticketEventText ticketMaxWidth' id='ticketEventPlace1'>{this.props.ticketData[2]['eventPlace']}</div>
                        <div className='ticketEventText ticketMaxWidth' id='ticketEventPrice1'>{this.props.ticketData[3]['eventPrice']}</div>
                        <div className='ticketEventText ticketMaxWidth' id='ticketEventTime1'>{this.props.ticketData[1]['eventTime']}</div>
                    </div>
                </div>
            </div>
        )
    }
}

async function submitData(fullData,event){
    try{
        await fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/createnewevent', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                data:fullData
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var element = document.getElementById('submitError')
            if (text.substring(0,4) !== "EID:"){
                element.classList.add('errorVisible')
                element.classList.remove('errorInvisible')
                event.target.disabled = false;
            } else {
                element.classList.add('errorInvisible')
                element.classList.remove('errorVisible')
                document.location.replace('/viewEvent?eid='+text.substring(4))
            }
        })
    } catch (err){
        var element = document.getElementById('error')
        event.target.disabled = false;
        element.classList.add('errorVisible')
        element.classList.remove('errorInvisible')
    }
}

//functions called by user actions
function commit(event, baseData, paymentData, ticketData){
    event.preventDefault()

    var fullData={
        base:baseData, 
        payment: paymentData, 
        ticket: ticketData
    }
    submitData(fullData,event)
}

export default Check