import React from "react"
import { toNormalNumber } from '../functionality/normalizer';

//site components
const FeeMapper = ({fees,codes}) => (
    <>
       {fees.map(fee => (
        <div className="allEventPane">
            <FeeClass fee={fee} codes={codes} />
        </div>
       ))} 
    </>
)

function FeeClassEdit(props){
    return(
        <div>
            Mindestgebühren:<br/>
            <input type='number' className='inputText number' step={0.01} min={0} Value={props.fee[2]} onChange={(e) => props.edit(2, e.target.value)} /><br/>
            Maximalgebühren:<br/>
            <input type='number' className='inputText number' step={0.01} min={0} defaultValue={props.fee[3]} onChange={(e) => props.edit(3, e.target.value)} /><br/>
            Grenze:<br/>
            <input type='number' className='inputText number' step={0.01} min={0} defaultValue={props.fee[1]} onChange={(e) => props.edit(1, e.target.value)} />
        </div>
    )
}

function FeeClassViewer(props){
    return(
        <div>
            Gebühren unter einschl. {toNormalNumber(props.fee[1])}€: <span className="">{toNormalNumber(props.fee[2])}€ </span><br/>
            darüber: <span className="">{toNormalNumber(props.fee[3])}€</span> pro Ticket
        </div>
    )
}

function NewFee(){
    return(
        <></>
    )
}

//class definitions
class FeeClasses extends React.Component{
    constructor(props){
        super()
        this.state = {
            fees: props.fees,
            code: props.codes,
        }
    }

    render(){
        console.log(this.props)
        return(
            <details>
                <summary><b>Gebührenklassen und Keys</b></summary>
                <FeeMapper fees={this.state.fees} codes={this.state.codes} />
                <NewFee/>
            </details>
        )
    }
}

class FeeClass extends React.Component{
    constructor(props){
        super()
        this.state = {
            fee: props.fee,
            code: props.codes,
            editable: false,
            edited: false,
        }
        this.commit = this.commit.bind(this)
        this.edit = this.edit.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    commit(){
        this.setState({
            edited:false,
        })
        //fetch
    }

    edit(i, val){
        var temp = this.state.fee
        temp[i] = val
        this.setState({
            fee: temp,
            edited: true,
        })
    }

    toggle(){
        this.setState({
            editable: !this.state.editable
        })  
    }

    render(){
        return(
            <div>
                <span className="higherLine">
                    <b className="emphasized"><i>Gebührenklasse - ID: {this.state.fee[0]}</i></b>
                    <span className="btnClass3 eventIconWrapper">{(this.state.editable)?<><span><span onClick={(e) => {this.toggle(); this.commit()}} className="icon tickIcon iconGreenCol">Speichern</span><span className="icon cancelIcon iconWarningCol" onClick={(e) => {this.toggle()}}>Abbrechen </span></span></>:<span onClick={(e) => {this.toggle();}} className="icon editIcon iconAccentCol">Bearbeiten </span>}</span>
                </span>
                {(this.state.edited)?<span className="errorVisible">Ungespeicherte Änderungen!</span>:''}
                {(this.state.editable)?<FeeClassEdit fee={this.state.fee} edit={this.edit}/>:<FeeClassViewer fee={this.state.fee}/>}
            </div>
        )
    }
}


export default FeeClasses