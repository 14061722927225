import React from "react"
import loginToken from '../functionality/loginToken'

//starting function
function EditOwnData(){
    loadData()

    return(
        <div className="content">
            <EditForm/>
        </div>
    )
}

//API calls
async function loadData(){
    await fetch(process.env.REACT_APP_PROFILEHANDLER_API+process.env.REACT_APP_STAGE+'/profile', {
        method: 'POST',
        body: JSON.stringify({
            key: loginToken.GetToken()
        }),
        headers: {
            'Content-Type': 'application/json'
        },
    }).then (resp => resp.text())
    .then (text =>{
        var obj = JSON.parse(text);
        document.getElementById("firstName").value = obj[1][1]
        document.getElementById("lastName").value = obj[1][2]
        document.getElementById("email").value = obj[1][0]
        
        if (obj[0] === "hid"){
            document.getElementById('firstNameSpan').textContent = 'Anzeigename'
            document.getElementById('lastNameSpan').textContent = 'Ansprechpartner/-in'
        }

        email = obj[1][0]
    })
}

//variables
var email = ''

//class definitions
class EditForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            newEmail: '',
            newEmail2: '',
            newPw: '',
            newPw2: '',
            oldPw: '',
            disabledEmail2: true,
            disabledPw2:true
        };
        this.checkPwCompletion = this.checkPwCompletion.bind(this);
        this.checkMailCompletion = this.checkMailCompletion.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPW = this.checkPW.bind(this)
    }

    checkEMail(){
        var elemnt = document.getElementById('mailErr')
        var elemnt2 = document.getElementById('mails')
        if (this.state.newEmail !== this.state.newEmail2 && this.state.newEmail !== ''){
            elemnt.classList.add("errorVisible")
            elemnt.classList.remove("errorInvisible")
            elemnt2.style.color = 'red'
            return false
        } else {
            elemnt.classList.remove("errorVisible")
            elemnt.classList.add("errorInvisible")
            elemnt2.style.color = ''
            return true
        }
    }

    checkMailCompletion(){
        var elemnt = document.getElementById('invalidMail')
        var elemnt2 = document.getElementById('mails')

        if(this.state.newEmail !== '' && this.state.newEmail !== email){
            const re = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,}')
            this.setState( {disabledEmail2: false} )
            if (!re.test(this.state.newEmail)){
                elemnt.classList.add("errorVisible")
                elemnt.classList.remove("errorInvisible")
                elemnt2.style.color = 'red'
                return false
            } else {
                elemnt.classList.remove("errorVisible")
                elemnt.classList.add("errorInvisible")
                return true
            }
        } else {
            elemnt.classList.remove("errorVisible")
            elemnt.classList.add("errorInvisible")
            this.setState( {disabledEmail2: true} )
            return true
        }
    }

    checkPW(){
        var elemnt = document.getElementById('pwErr')
        var elemnt2 = document.getElementById('pws')
        if (this.state.newPw !== this.state.newPw2 && this.state.newPw !== ''){
            elemnt.classList.add("errorVisible")
            elemnt.classList.remove("errorInvisible")
            elemnt2.style.color = 'red'
            return false
        } else {
            elemnt.classList.remove("errorVisible")
            elemnt.classList.add("errorInvisible")
            elemnt2.style.color = ''
            return true
        }
    }

    checkPwCompletion(){
        var elemnt = document.getElementById('PwRequirements')
        var elemnt2 = document.getElementById('pws')

        if (this.state.newPw !== ''){
            const re = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*'§/=+-?_:.,<>])(?=.{8,})")
            this.setState( {disabledPw2: false} )
            if (!re.test(this.state.newPw)){
                elemnt.classList.add("errorVisible")
                elemnt.classList.remove("errorInvisible")
                elemnt2.style.color = 'red'
                return false
            } else {
                elemnt.classList.remove("errorVisible")
                elemnt.classList.add("errorInvisible")
                elemnt2.style.color = ''
                return true
            }    
        } else {
            elemnt.classList.remove("errorVisible")
            elemnt.classList.add("errorInvisible")
            elemnt2.style.color = ''
            this.setState( {disabledPw2: true} )
            return true
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    async handleSubmit(event){
        event.preventDefault();
        if (this.checkPwCompletion() && this.checkMailCompletion() && this.checkEMail() && this.checkPW()){
            try{
                await fetch(process.env.REACT_APP_PROFILEHANDLER_API+process.env.REACT_APP_STAGE+'/changeudata', {
                    method: 'POST',
                    body: JSON.stringify({
                        key: loginToken.GetToken(),
                        fname: this.state.firstName,
                        lname: this.state.lastName,
                        oldMail: email,
                        mail: this.state.newEmail,
                        checkmail: this.state.newEmail2,
                        oldPw: this.state.oldPw,
                        pw: this.state.newPw,
                        checkpw: this.state.newPw2
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then (resp => resp.text())
                .then(text => {
                    console.log(text)
                    var element = document.getElementById('result')
                    if (text !== "True"){
                        element.textContent = text
                        element.classList.add('errorVisible')
                    } else {
                        element.classList.remove('errorVisible')
                        element.textContent = "Änderungen gespeichert!"
                    }
                })
                
                event.preventDefault();
            } catch (err){
                var element = document.getElementById('error')
                element.classList.add('errorVisible')
                element.classList.remove('errorInvisible')
            }
        } else {
            console.log('Da fehlt was!')
            event.preventDefault();
        }
        
    }

    render (){
        return(
        <form onSubmit={this.handleSubmit}>
            <h3>Stammdaten ändern</h3>
            <span id='firstNameSpan'>Vorname</span><br/> <input className='inputText' type="text" name="firstName" id="firstName" value={this.state.value} onChange={this.handleInputChange} /><br/> <br/> 
            <span id='lastNameSpan'>Nachname</span><br/> <input className='inputText' type="text" name="lastName" id="lastName" value={this.state.value} onChange={this.handleInputChange} /><br/> <br/> 
            <div id='mails'>
                E-Mail <br/><input className='inputText' name="newEmail" id="email" type="text" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkMailCompletion} /><br/><br/>
                <p id='invalidMail' className='errorInvisible'>Die E-Mail ist nicht gültig!</p>
                E-Mail bestätigen <br/><input className='inputText' type="text" name="newEmail2" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkMailCompletion} disabled = {(this.state.disabledEmail2)? "disabled" : ""} />
                <p id='mailErr' className='errorInvisible'>Die E-Mails müssen übereinstimmen!</p>
            </div>
            <br/>
            <h3>Passwort ändern (Leer lassen für keine Änderung)</h3>
            <div id='pws' className='pwContainer'>
                Neues Passwort <br/><input className='inputText pw1' name="newPw" type="password" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkPwCompletion} />
                <div className="requirementsPopUp">Passwörter müssen mindestens folgende Anforderungen erfüllen:
                    <ul>
                        <li>min. 8 Zeichen</li>
                        <li>min. 1 Großbuchstabe</li>
                        <li>min. 1 Kleinbuchstabe</li>
                        <li>min. 1 Zahl</li>
                        <li>min. 1 Sonderzeichen</li>
                    </ul>
                </div>
                <p id='PwRequirements' className='errorInvisible'>Das Passwort erfüllt nicht die Anforderungen!</p>
                <br/><br/> 
                Neues Passwort bestätigen <br/><input className='inputText' name="newPw2" type="password" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkPW} disabled =  {(this.state.disabledPw2)? "disabled" : ""} />
                <p id='pwErr' className='errorInvisible'>Die Passwörter müssen übereinstimmen!</p>

                Altes Passwort<br/><input className='inputText' name="oldPw" type="password" value={this.state.value} onChange={this.handleInputChange} disabled =  {(this.state.disabledPw2)? "disabled" : ""} />

            </div>
            <center>
                <button type='submit' className='btnClass1 normalPropotions'>Änderungen speichern</button>
            </center>
            <div id='result'/>
        </form>
        )
    }
}

export default EditOwnData