import './Foot.css'
//starting function
function Footer(){
    return(
        <div className='footer centered'>
            <div className='parallel'>
                <div>
                    <Logo/>
                    <ByJNE/>
                </div>
                <div className='verticalLine'/>
                <div>
                    <a className='noUnderline' href='/AGB' target='_blank'>Allgemeine Geschäftsbedingungen</a><br/>
                    <a className='noUnderline' href='/Contact' target='_blank'>Kontakt</a>
                </div>
                <div className='verticalLine'/>
                <div>
                    <a className='noUnderline' href='/'>Startseite</a><br/>
                    <a className='noUnderline' href='/allEvents?s=1' >Alle Veranstaltungen</a><br/>
                    <a className='noUnderline' href='/myProfile'>Mein Konto</a><br/>
                    <a className='noUnderline' href='/Contact'>Event melden</a><br/>
                </div>
            </div>
            <br/>
            <div>
                <a className='noUnderline' href='/copyright' target='_blank'>Copyright JNE</a> |
                <a className='noUnderline' href='/impressum' target='_blank'>Impressum</a> |
                <a className='noUnderline' href='/dsgvo' target='_blank'>Datenschutz</a>
            </div>
        </div>
    )
}

//site components
function ByJNE(){
    return(
        <div className='rightItems'>
            by
            <picture>
                <source srcSet="images/jne_light_1.svg" media="(prefers-color-scheme:dark)"/>
                <img src="images/jne_dark.png" alt="JNE" className="footerJNE" id="logo" draggable={false}/>
            </picture>
        </div>
    )
}

function Logo(){
    return(
        <div>
            <picture>
                <source srcSet="images/jne-events-ticket-light.svg" media="(prefers-color-scheme:dark)"/>
                <img src="images/jne-events-ticket-dark.svg" alt="SKALAR" className="footerLogo" id="logo" draggable={false}/>
            </picture>
        </div>
    )
}

export default Footer