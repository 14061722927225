import React from "react"

//starting function
function RegSuccess(){
    return(
        <div className="centered">
            <h1>Registrierung erfolgreich</h1>
            <h2>Bitte prüfe deine Mails, um deine Registrierung abzuschließen!</h2>
        </div>
    )
}

export default RegSuccess