import React from "react"
import loginToken from '../functionality/loginToken'
import { toNormalDateAndTime, toNormalNumber } from '../functionality/normalizer';
import Tickets from "../addEvent_components/Tickets";

//site components
function TicketViewer(props){
    return(
        <>
        <h3>Ticketdesign: </h3>
        <div className="centered">
            <div id='ticketBG'className="ticketOutline">
                <div id='qrBox1' className='qrBox'>
                    <img src='./images/jne-events-ticket.png' className='qrCodeJNETitle' draggable="false" alt='JNE Events'/>
                    <img src='./images/qrcode_placeholder.png' className='qrCodePlaceholder' draggable="false" alt='QR Code'/>
                </div>
                <div>
                    <img id='ticketPicture1' className='ticketPicture' src='' draggable="false" alt='background'/>
                </div>
                <div className='ticketEventText ticketMaxWidth' id='ticketEventTitle1'>{props.title}</div>
                <div className='ticketEventText ticketMaxWidth' id='ticketEventPlace1'>{(props.presData)?props.presData[3]+' '+props.presData[4]+', '+props.presData[5]+' '+props.presData[6]:'Musterstraße 1, 12345 Musterstadt'}</div>
                <div className='ticketEventText ticketMaxWidth' id='ticketEventPrice1'>{(props.price)?'Preis: '+toNormalNumber(props.price)+' €':'Preis: 5,00 €'}</div>
                <div className='ticketEventText ticketMaxWidth' id='ticketEventTime1'>{(props.presData)?toNormalDateAndTime(props.presData[1]):'am 21.01.2023 um 16:09 Uhr'}</div>
            </div>
        </div>
        </>
    )
}

//class definitions
class TicketDesign extends React.Component{
    constructor(){
        super()
        this.state = {
            ticketData: null,
            editable: false,
            ticketInfo: null,
            ticketImage: './images/ticket-background-placeholder.png'
        }
        this.commit = this.commit.bind(this)
        this.setStyles = this.setStyles.bind(this)
        this.toggleEditable = this.toggleEditable.bind(this)
        this.updateData = this.updateData.bind(this)
    }

    commit(){
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/updateticketdesign', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                eid:this.props.baseData[0],
                ticket: this.state.ticketInfo,
                image: this.state.ticketImage
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        if (this.state.editable) window.location.reload()
    }

    componentDidMount(){
        if(this.state.ticketData === null){ 
            fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/getticketdesign', {
                method: 'POST',
                body: JSON.stringify({
                    eid: this.props.baseData[0]
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then (resp => resp.text())
            .then (text =>{
                var obj = JSON.parse(text);
                this.setState({
                    ticketData: obj
                })
            }).then(() => {
                this.setStyles()
            })
        }  
    }

    setStyles(){
        if(this.state.ticketData === null) return
        document.getElementById('qrBox1').style = this.state.ticketData[0][5]
        document.getElementById('ticketPicture1').style = this.state.ticketData[0][4]
        document.getElementById('ticketPicture1').src = this.state.ticketData[0][6]
        document.getElementById('ticketEventTitle1').style = this.state.ticketData[0][0]
        document.getElementById('ticketEventPlace1').style = this.state.ticketData[0][2]
        document.getElementById('ticketEventPrice1').style = this.state.ticketData[0][3]
        document.getElementById('ticketEventTime1').style = this.state.ticketData[0][1]
    }

    toggleEditable(e){
        e.preventDefault()
        this.setState({
            editable: !this.state.editable,
        }, () => {return this.state.editable})
    }

    updateData(tar, val){
        this.setState({
            [tar]: val,
        })
    }

    render(){
        if(this.state.ticketData === null) return
        return(
            <>
            {(this.state.editable)?<Tickets eventname={this.props.baseData[1]} updateData={this.updateData} nextStep={this.commit} />:<TicketViewer title={this.props.baseData[1]} presData={this.props.presData} price={this.props.price} />}
            <center>
                <button className="btnClass2 normalPropotions" onClick={(e) =>this.toggleEditable(e)}>{(this.state.editable)?'Abbrechen':'Bearbeiten'}</button>
            </center>
            </>
        )
    }
}

export default TicketDesign