function Datenschutz(){
    return(
        <div className="content">
            <h1>Datenschutzerklärung</h1>
            <div className="twoEM">
                <h2 id='1'>Präambel</h2>
                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) 
                wir zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten. <br/><br/>
                Die verwendeten Begriffe sind nicht geschlechtsspezifisch.<br/><br/>
                Stand: 4. April 2023<br/>
                <h2>Inhaltsübersicht</h2>
                <ul>
                    <li><a href="#1" className="noUnderline">Präambel</a></li>
                    <li><a href="#2" className="noUnderline">Verantwortlicher</a></li>
                    <li><a href="#3" className="noUnderline">Übersicht der Verarbeitungen</a></li>
                    <li><a href="#4" className="noUnderline">Maßgebliche Rechtsgrundlagen</a></li>
                    <li><a href="#5" className="noUnderline">Sicherheitsmaßnahmen</a></li>
                    <li><a href="#6" className="noUnderline">Übermittlung von personenbezogenen Daten</a></li>
                    <li><a href="#7" className="noUnderline">Datenverarbeitung in Drittländern</a></li>
                    <li><a href="#8" className="noUnderline">Löschung von Daten</a></li>
                    <li><a href="#9" className="noUnderline">Einsatz von Cookies</a></li>
                    <li><a href="#10" className="noUnderline">Geschäftliche Leistungen</a></li>
                    <li><a href="#11" className="noUnderline">Zahlungsverfahren</a></li>
                    <li><a href="#12" className="noUnderline">Bereitstellung des Onlineangebotes und Webhosting</a></li>
                    <li><a href="#13" className="noUnderline">Registrierung, Anmeldung und Nutzerkonto</a></li>
                    <li><a href="#14" className="noUnderline">Kontakt- und Anfragenverwaltung</a></li>
                    <li><a href="#15" className="noUnderline">Onlinemarketing</a></li>
                    <li><a href="#16" className="noUnderline">Änderung und Aktualisierung der Datenschutzerklärung</a></li>
                    <li><a href="#17" className="noUnderline">Rechte der betroffenen Personen</a></li>
                </ul>
                <h2 id='2'>Verantwortlicher</h2>
                Jan, Jeschinski / JNE UG (haftungsbeschränkt)<br/>
                Musterweg 1<br/>
                12345 Musterstadt, Deutschland<br/><br/>
                Vertretungsberechtigte Personen:<br/>
                <ul>
                    <li>
                        Jan Jeschinski <br/>
                        E-Mail-Adresse:<br/>
                        <a href="mailto:jeschinski-jne@outlook.com">jeschinski-jne@outlook.com</a>
                    </li>
                </ul>
                 
                <h2 id='3'>Übersicht der Verarbeitungen</h2>
                Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.
                Arten der verarbeiteten Daten<br/>
                <ul>
                    <li>Bestandsdaten.</li>
                    <li>Zahlungsdaten.</li>
                    <li>Kontaktdaten.</li>
                    <li>Inhaltsdaten.</li>
                    <li>Vertragsdaten.</li>
                    <li>Nutzungsdaten.</li>
                    <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                </ul><br/>
                <b>Kategorien betroffener Personen</b><br/><br/>
                <ul>
                    <li>Kunden.</li>
                    <li>Interessenten.</li>
                    <li>Kommunikationspartner.</li>
                    <li>Nutzer.</li>
                    <li>Geschäfts- und Vertragspartner.</li>
                </ul><br/>
                <b>Zwecke der Verarbeitung</b><br/><br/>
                <ul>
                    <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
                    <li>Kontaktanfragen und Kommunikation.</li>
                    <li>Sicherheitsmaßnahmen.</li>
                    <li>Reichweitenmessung.</li>
                    <li>Tracking.</li>
                    <li>Büro- und Organisationsverfahren.</li>
                    <li>Konversionsmessung.</li>
                    <li>Verwaltung und Beantwortung von Anfragen.</li>
                    <li>Feedback.</li>
                    <li>Marketing.</li>
                    <li>Profile mit nutzerbezogenen Informationen.</li>
                    <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                    <li>Informationstechnische Infrastruktur.</li>
                </ul>
                <h2 id='4'>Maßgebliche Rechtsgrundlagen</h2>
                Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. 
                Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland 
                gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.<br/>
                <ul>
                    <li>
                        <b>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</b> - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen 
                        Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
                    </li>
                    <li>
                        <b>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)</b> - Die Verarbeitung ist für die Erfüllung eines Vertrags, 
                        dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.
                    </li>
                    <li>
                        <b>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)</b> - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, 
                        der der Verantwortliche unterliegt.
                    </li>
                    <li>
                        <b>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</b> - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten 
                        erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                    </li>
                </ul>
                Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz 
                zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere 
                Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener 
                Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. 
                Des Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, 
                Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der 
                einzelnen Bundesländer zur Anwendung gelangen. 

                <h2 id='5'>Sicherheitsmaßnahmen</h2>
                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, 
                der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und 
                Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.<br/><br/>
                Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und 
                elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer 
                Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die 
                Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, 
                Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
                
                <h2 id='6'>Übermittlung von personenbezogenen Daten</h2>
                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige 
                Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit IT-Aufgaben 
                beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen Fällen beachten wir die 
                gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
                
                <h2 id='7'>Datenverarbeitung in Drittländern</h2>
                Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung 
                im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, 
                erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. <br/><br/>
                Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern 
                mit einem anerkannten Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von Zertifizierungen 
                oder verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der 
                EU-Kommission: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de">
                    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a>).
                
                <h2 id='8'>Löschung von Daten</h2>
                Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen 
                werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind). 
                Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. 
                D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden 
                müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder 
                juristischen Person erforderlich ist. <br/><br/>
                Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.
                
                <h2 id='9'>Einsatz von Cookies</h2>
                Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern und Informationen aus den Endgeräten auslesen. 
                Z.B. um den Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete Funktionen eines Onlineangebotes 
                speichern. Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten 
                sowie der Erstellung von Analysen der Besucherströme.<br/><br/>
                
                <b>Hinweise zur Einwilligung:</b> Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung 
                ein, außer wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern und das Auslesen der Informationen, 
                also auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur 
                Verfügung zu stellen. Zu den unbedingt erforderlichen Cookies gehören in der Regel Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des Onlineangebotes, 
                dem Lastausgleich, der Sicherheit, der Speicherung der Präferenzen und Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der Bereitstellung der Haupt- und 
                Nebenfunktionen des von den Nutzern angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die widerrufliche Einwilligung wird gegenüber den Nutzern 
                deutlich kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.<br/><br/>
                
                <b>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:</b> Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten der 
                Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage 
                der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten 
                Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen 
                der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. 
                Zu welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- 
                und Verarbeitungsprozessen auf.<br/><br/>
                
                <b>Speicherdauer:</b> Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:
                <ul>
                    <li>
                        <b>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</b> Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot 
                        verlassen und sein Endgerät (z.B. Browser oder mobile Applikation) geschlossen hat.
                    </li>
                    <li>
                        <b>Permanente Cookies:</b> Permanente Cookies bleiben auch nach dem Schließen des Endgerätes gespeichert. So können beispielsweise der Login-Status 
                        gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe von Cookies 
                        erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies 
                        mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind und die Speicherdauer 
                        bis zu zwei Jahre betragen kann.
                    </li>
                </ul>
                
                <b>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):</b> Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen und zudem 
                einen Widerspruch gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen. Nutzer können ihren Widerspruch auch über die 
                Einstellungen ihres Browsers erklären, z.B. durch Deaktivierung der Verwendung von Cookies (wobei dadurch auch die Funktionalität unserer Online-Dienste 
                eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann auch über die 
                Websites <a href="https://optout.aboutads.info">https://optout.aboutads.info</a> und <a href="https://www.youronlinechoices.com/">https://www.youronlinechoices.com/</a>  
                erklärt werden.

                <h2 id='10'>Geschäftliche Leistungen</h2>
                Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten (zusammenfassend bezeichnet als "Vertragspartner") im Rahmen 
                von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen der Kommunikation mit den Vertragspartnern 
                (oder vorvertraglich), z.B., um Anfragen zu beantworten.<br/><br/>
                
                Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu gehören insbesondere die Verpflichtungen zur Erbringung der 
                vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe bei Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus verarbeiten wir die 
                Daten zur Wahrung unserer Rechte und zum Zwecke der mit diesen Pflichten verbundenen Verwaltungsaufgaben sowie der Unternehmensorganisation. Darüber hinaus verarbeiten 
                wir die Daten auf Grundlage unserer berechtigten Interessen an einer ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen 
                zum Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte 
                (z.B. zur Beteiligung von Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern, Zahlungsdienstleistern 
                oder Finanzbehörden). Im Rahmen des geltenden Rechts geben wir die Daten von Vertragspartnern nur insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder 
                zur Erfüllung gesetzlicher Pflichten erforderlich ist. Über weitere Formen der Verarbeitung, z.B. zu Marketingzwecken, werden die Vertragspartner im Rahmen dieser 
                Datenschutzerklärung informiert.<br/><br/>
                
                Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in Onlineformularen, 
                durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.<br/><br/>
                
                Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es sei denn, 
                dass die Daten in einem Kundenkonto gespeichert werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen. 
                Die gesetzliche Aufbewahrungsfrist beträgt bei steuerrechtlich relevanten Unterlagen sowie bei Handelsbüchern, Inventaren, Eröffnungsbilanzen, Jahresabschlüssen, 
                die zum Verständnis dieser Unterlagen erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen und Buchungsbelegen zehn Jahre sowie bei empfangenen 
                Handels- und Geschäftsbriefen und Wiedergaben der abgesandten Handels- und Geschäftsbriefe sechs Jahre. Die Frist beginnt mit Ablauf des Kalenderjahres, in dem die 
                letzte Eintragung in das Buch gemacht, das Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht aufgestellt, der Handels- oder Geschäftsbrief 
                empfangen oder abgesandt worden oder der Buchungsbeleg entstanden ist, ferner die Aufzeichnung vorgenommen worden ist oder die sonstigen Unterlagen entstanden sind.<br/><br/>
                
                Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und den Anbietern 
                die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen. <br/>

                <ul>
                
                    <li>
                        <b>Verarbeitete Datenarten:</b> Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten 
                        (z.B. E-Mail, Telefonnummern); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse 
                        an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                    </li>
                    <li><b>Betroffene Personen:</b> Kunden; Interessenten; Geschäfts- und Vertragspartner.</li>
                    <li>
                        <b>Zwecke der Verarbeitung:</b> Erbringung vertraglicher Leistungen und Kundenservice; Sicherheitsmaßnahmen; Kontaktanfragen und Kommunikation; Büro- und 
                        Organisationsverfahren; Verwaltung und Beantwortung von Anfragen; Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen); Profile mit 
                        nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
                    </li>
                    <li>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) 
                        DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                </ul>
                    <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
                <ul>
                    <li>
                        <b>Kundenkonto:</b> Vertragspartner können innerhalb unseres Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz "Kundenkonto"). 
                        Falls die Registrierung eines Kundenkontos erforderlich ist, werden Vertragspartner hierauf ebenso hingewiesen wie auf die für die Registrierung erforderlichen 
                        Angaben. Die Kundenkonten sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen der Registrierung sowie anschließender 
                        Anmeldungen und Nutzungen des Kundenkontos speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die Registrierung nachweisen und 
                        etwaigem Missbrauch des Kundenkontos vorbeugen zu können. Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das Kundenkonto betreffenden Daten gelöscht, 
                        vorbehaltlich, deren Aufbewahrung ist aus gesetzlichen Gründen erforderlich. Es obliegt den Kunden, ihre Daten bei erfolgter Kündigung des Kundenkontos zu 
                        sichern; <br/>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                    <li>
                        <b>Wirtschaftliche Analysen und Marktforschung:</b> Aus betriebswirtschaftlichen Gründen und um Markttendenzen, Wünsche der Vertragspartner und Nutzer 
                        erkennen zu können, analysieren wir die uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc., wobei in die Gruppe der betroffenen Personen 
                        Vertragspartner, Interessenten, Kunden, Besucher und Nutzer unseres Onlineangebotes fallen können. Die Analysen erfolgen zum Zweck betriebswirtschaftlicher 
                        Auswertungen, des Marketings und der Marktforschung (z.B. zur Bestimmung von Kundengruppen mit unterschiedlichen Eigenschaften). Dabei können wir, sofern 
                        vorhanden, die Profile von registrierten Nutzern samt ihrer Angaben, z.B. zu in Anspruch genommenen Leistungen, berücksichtigen. Die Analysen dienen alleine 
                        uns und werden nicht extern offenbart, sofern es sich nicht um anonyme Analysen mit zusammengefassten, also anonymisierten Werten handelt. Ferner nehmen wir 
                        Rücksicht auf die Privatsphäre der Nutzer und verarbeiten die Daten zu den Analysezwecken möglichst pseudonym und, sofern machbar, anonym (z.B. als 
                        zusammengefasste Daten); <br/>
                        <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                    <li>
                        <b>Shop und E-Commerce:</b> Wir verarbeiten die Daten unserer Kunden, um ihnen die Auswahl, den Erwerb, bzw. die Bestellung der gewählten Produkte, 
                        Waren sowie verbundener Leistungen, als auch deren Bezahlung und Zustellung, bzw. Ausführung zu ermöglichen. Sofern für die Ausführung einer Bestellung 
                        erforderlich, setzen wir Dienstleister, insbesondere Post-, Speditions- und Versandunternehmen ein, um die Lieferung, bzw. Ausführung gegenüber unseren 
                        Kunden durchzuführen. Für die Abwicklung der Zahlungsvorgänge nehmen wir die Dienste von Banken und Zahlungsdienstleistern in Anspruch. Die erforderlichen 
                        Angaben sind als solche im Rahmen des Bestell- bzw. vergleichbaren Erwerbsvorgangs gekennzeichnet und umfassen die zur Auslieferung, bzw. Zurverfügungstellung 
                        und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprache halten zu können; <br/>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche 
                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                    <li>
                        <b>Veranstaltungen und Events:</b> Wir verarbeiten die Daten der Teilnehmer der von uns angebotenen oder ausgerichteten Veranstaltungen, Events und 
                        ähnlichen Aktivitäten (nachfolgend einheitlich als "Teilnehmer" und „Veranstaltungen“ bezeichnet), um ihnen die Teilnahme an den Veranstaltungen und 
                        Inanspruchnahme der mit der Teilnahme verbundenen Leistungen oder Aktionen zu ermöglichen. Sofern wir in diesem Rahmen gesundheitsbezogene Daten, religiöse, 
                        politische oder sonstige besondere Kategorien von Daten verarbeiten, dann erfolgt diese im Rahmen der Offenkundigkeit (z.B. bei thematisch ausgerichteten 
                        Veranstaltungen oder dient der Gesundheitsvorsorge, Sicherheit oder erfolgt mit Einwilligung der Betroffenen). Die erforderlichen Angaben sind als solche 
                        im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung benötigten 
                        Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können. Soweit wir Zugang zu Informationen der Endkunden, Mitarbeitern oder anderer 
                        Personen erhalten, verarbeiten wir diese im Einklang mit den gesetzlichen und vertraglichen Vorgaben; <br/>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche 
                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                </ul>

                <h2 id='11'>Zahlungsverfahren</h2>
                Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten Interessen 
                bieten wir den betroffenen Personen effiziente und sichere Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten weitere 
                Dienstleister ein (zusammenfassend "Zahlungsdienstleister").<br/><br/>
                
                Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B. Kontonummern 
                oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind erforderlich, 
                um die Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen gespeichert. 
                D.h., wir erhalten keine konto- oder kreditkartenbezogenen Informationen, sondern lediglich Informationen mit Bestätigung oder Negativbeauskunftung der 
                Zahlung. Unter Umständen werden die Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die 
                Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die Datenschutzhinweise der Zahlungsdienstleister.<br/><br/>
                
                Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen 
                Webseiten bzw. Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung von Widerrufs-, 
                Auskunfts- und anderen Betroffenenrechten.<br/><br/>
                <ul>
                    <li>
                        <b>Verarbeitete Datenarten:</b> Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie); 
                        Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); 
                        Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                    </li>
                    <li><b>Betroffene Personen:</b> Kunden; Interessenten.</li>
                    <li><b>Zwecke der Verarbeitung:</b> Erbringung vertraglicher Leistungen und Kundenservice.</li>
                    <li><b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).</li>
                </ul><br/>
                <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b><br/>
                <ul>
                    <li>
                        <b>PayPal:</b> Zahlungsdienstleistungen (technische Anbindung von Online-Bezahlmethoden) (z.B. PayPal, PayPal Plus, Braintree);<br/>
                        <b>Dienstanbieter:</b> PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg; <br/>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); <br/>
                        <b>Website:</b> <a href="https://www.paypal.com/de">https://www.paypal.com/de; </a><br/>
                        <b>Datenschutzerklärung:</b> <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">https://www.paypal.com/de/webapps/mpp/ua/privacy-full.</a>
                    </li>
                </ul>

                <h2 id='12'>Bereitstellung des Onlineangebotes und Webhosting</h2>
                Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. 
                Zu diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser 
                oder das Endgerät der Nutzer zu übermitteln.<br/>
                <ul>
                    <li>
                        <b>Verarbeitete Datenarten: Nutzungsdaten</b> (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); <br/>
                        Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus); <br/>
                        Inhaltsdaten (z.B. Eingaben in Onlineformularen).
                    </li>
                    <li><b>Betroffene Personen:</b> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                    <li>
                        <b>Zwecke der Verarbeitung:</b> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit; <br/>
                        Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und technischen Geräten (Computer, Server etc.).); <br/>
                        Sicherheitsmaßnahmen.
                    </li>
                    <li><b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
                </ul><br/>
                <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b><br/>
                <ul>
                    <li>
                        <b>Bereitstellung Onlineangebot auf gemietetem Speicherplatz:</b> Für die Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz, 
                        Rechenkapazität und Software, die wir von einem entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig beziehen;<br/>
                        <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                    <li>
                        <b>Erhebung von Zugriffsdaten und Logfiles:</b> Der Zugriff auf unser Onlineangebot wird in Form von so genannten "Server-Logfiles" 
                        protokolliert. Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, 
                        übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) 
                        und im Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, 
                        z.B., um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum anderen, 
                        um die Auslastung der Server und ihre Stabilität sicherzustellen; <br/>
                        <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <br/>
                        <b>Löschung von Daten:</b> Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder anonymisiert. 
                        Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
                    </li>
                    <li>
                        <b>E-Mail-Versand und -Hosting:</b> Die von uns in Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang 
                        sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch weitere Informationen betreffend den 
                        E-Mailversand (z.B. die beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken 
                        der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht verschlüsselt versendet werden. 
                        Im Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) 
                        nicht auf den Servern, von denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der E-Mails zwischen dem Absender und 
                        dem Empfang auf unserem Server keine Verantwortung übernehmen; <br/>
                        <b>Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</b>
                    </li>
                    <li>
                        <b>Amazon Web Services:</b> Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer Infrastruktur und verbundenen Dienstleistungen 
                        (z.B. Speicherplatz und/oder Rechenkapazitäten); <br/>
                        <b>Dienstanbieter:</b> Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA 98109, United States; <br/>
                        <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <br/>
                        <b>Website:</b> <a href="https://aws.amazon.com">https://aws.amazon.com</a> ; <br/>
                        <b>Datenschutzerklärung:</b> <a href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_German_Translation.pdf.pdf">AWS_Privacy_Notice_German_Translation.pdf</a>; <br/>
                        <b>Auftragsverarbeitungsvertrag:</b> Wird vom Dienstanbieter bereitgestellt.
                    </li>
                </ul>

                <h2 id='13'>Registrierung, Anmeldung und Nutzerkonto</h2>
                Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken 
                der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die 
                Login-Informationen (Nutzername, Passwort sowie eine E-Mail-Adresse).<br/><br/>
                
                Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die IP-Adresse und den 
                Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als auch jener der Nutzer an einem 
                Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur 
                Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung hierzu.<br/><br/>
                
                Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B. technische Änderungen, per E-Mail informiert werden.<br/>
                <ul>
                    <li>
                        <b>Verarbeitete Datenarten:</b> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); 
                        Inhaltsdaten (z.B. Eingaben in Onlineformularen); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, 
                        Einwilligungsstatus).
                    </li>
                    <li><b>Betroffene Personen:</b> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                    <li>
                        <b>Zwecke der Verarbeitung:</b> Erbringung vertraglicher Leistungen und Kundenservice; Sicherheitsmaßnahmen; Verwaltung und Beantwortung von 
                        Anfragen; Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                    </li>
                    <li>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); 
                        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                </ul>
                <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b><br/>
                <ul>
                    <li>
                        Registrierung mit Klarnamen: Aufgrund der Natur unserer Community bitten wir die Nutzer unser Angebot nur unter Verwendung von 
                        Klarnamen zu nutzen. D.h. die Nutzung von Pseudonymen ist nicht zulässig;<br/>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                    <li>
                        <b>Keine Aufbewahrungspflicht für Daten:</b> Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu 
                        sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich zu löschen; <br/>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                </ul>

                <h2 id='14'>Kontakt- und Anfragenverwaltung</h2>
                Bei der Kontaktaufnahme mit uns (z.B. per Post, Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und 
                Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter 
                Maßnahmen erforderlich ist.<br/>
                <ul>
                    <li>
                        <b>Verarbeitete Datenarten:</b> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen); 
                        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, 
                        Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                    </li>
                    <li><b>Betroffene Personen:</b> Kommunikationspartner.</li>
                    <li>
                        <b>Zwecke der Verarbeitung:</b> Kontaktanfragen und Kommunikation; Verwaltung und Beantwortung von Anfragen; 
                        Feedback (z.B. Sammeln von Feedback via Online-Formular); Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                    </li>
                    <li>
                        <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); Vertragserfüllung und vorvertragliche 
                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                </ul>
                <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
                <ul>
                    <li>
                        <b>Kontaktformular:</b> Wenn Nutzer über unser Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in Kontakt treten, 
                        verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens;<br/>
                        <b>Rechtsgrundlagen:</b> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) 
                        DSGVO).
                    </li>
                </ul>

                <h2 id='15'>Onlinemarketing</h2>

                Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings, worunter insbesondere die Vermarktung von Werbeflächen oder Darstellung von werbenden 
                und sonstigen Inhalten (zusammenfassend als "Inhalte" bezeichnet) anhand potentieller Interessen der Nutzer sowie die Messung ihrer Effektivität fallen kann. <br/><br/>
                
                Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren genutzt, mittels 
                derer die für die Darstellung der vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert werden. Zu diesen Angaben können z.B. betrachtete Inhalte, 
                besuchte Webseiten, genutzte Onlinenetzwerke, aber auch Kommunikationspartner und technische Angaben, wie der verwendete Browser, das verwendete 
                Computersystem sowie Angaben zu Nutzungszeiten und genutzten Funktionen gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben, 
                können auch diese verarbeitet werden.<br/><br/>
                
                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir zur Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung durch 
                Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen 
                oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der Onlinemarketingverfahren kennen nicht die tatsächliche Identität der Nutzer, 
                sondern nur die in deren Profilen gespeicherten Angaben.<br/><br/>
                
                Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels ähnlicher Verfahren gespeichert. Diese Cookies können später generell auch auf anderen 
                Webseiten die dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der Darstellung von Inhalten analysiert als auch mit weiteren Daten ergänzt 
                und auf dem Server des Onlinemarketingverfahrensanbieters gespeichert werden.<br/><br/>
                
                Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen 
                Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der Nutzer mit den vorgenannten Angaben verbindet. Wir bitten darum, zu beachten, 
                dass Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch Einwilligung im Rahmen der Registrierung, treffen können.<br/><br/>
                
                Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen sogenannter 
                Konversionsmessungen prüfen, welche unserer Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h. z.B., zu einem Vertragsschluss 
                mit uns. Die Konversionsmessung wird alleine zur Analyse des Erfolgs unserer Marketingmaßnahmen verwendet.<br/><br/>
                
                Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert werden.<br/>
                <ul>
                    <li>
                        <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- 
                        und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                    </li>
                    <li><b>Betroffene Personen:</b> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                    <li>
                        <b>Zwecke der Verarbeitung:</b> Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking 
                        (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies); Marketing; Profile mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
                    </li>
                    <li><b>Sicherheitsmaßnahmen:</b> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
                    <li><b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</li>
                    <li>
                        <b>Widerspruchsmöglichkeit (Opt-Out):</b> Wir verweisen auf die Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern angegebenen 
                        Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die Möglichkeit, dass Sie Cookies 
                        in den Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes eingeschränkt werden. Wir empfehlen daher zusätzlich 
                        die folgenden Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet angeboten werden: <br/>
                        a) Europa: <a href="https://www.youronlinechoices.eu">https://www.youronlinechoices.eu</a> . <br/>
                        b) Kanada: <a href="https://www.youradchoices.ca/choices">https://www.youradchoices.ca/choices</a> . <br/>
                        c) USA: <a href="https://www.aboutads.info/choices">https://www.aboutads.info/choices</a> . <br/>
                        d) Gebietsübergreifend: <a href="https://optout.aboutads.info">https://optout.aboutads.info</a> .
                    </li>
                </ul>
                <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
                <ul>
                    <li>
                        <b>Google Adsense mit personalisierten Anzeigen:</b> Wir nutzen den Dienst Google Adsense mit personalisierten Anzeigen, mit dessen Hilfe innerhalb 
                        unseres Onlineangebotes Anzeigen eingeblendet werden und wir für deren Einblendung oder sonstige Nutzung eine Entlohnung erhalten;<br/>
                        <b>Dienstanbieter:</b> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland; <br/>
                        <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); <br/>
                        <b>Website:</b> <a href="https://marketingplatform.google.com">https://marketingplatform.google.com</a>; <br/>
                        <b>Datenschutzerklärung:</b> <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>; <br/>
                        <b>Weitere Informationen:</b> Arten der Verarbeitung sowie der verarbeiteten Daten: 
                        <a href="https://privacy.google.com/businesses/adsservices">https://privacy.google.com/businesses/adsservices</a>;
                        Datenverarbeitungsbedingungen für Google Werbeprodukte:Informationen zu den 
                        Diensten Datenverarbeitungsbedingungen zwischen Verantwortlichen und Standardvertragsklauseln für Drittlandtransfers von 
                        Daten: <a href="https://business.safety.google/adscontrollerterms">https://business.safety.google/adscontrollerterms</a>.
                    </li>
                    <li>
                        <b>Google Adsense mit nicht-personalisierten Anzeigen:</b> Wir nutzen den Dienst Google Adsense mit nicht-personalisierten Anzeigen, 
                        mit dessen Hilfe innerhalb unseres Onlineangebotes Anzeigen eingeblendet werden und wir für deren Einblendung oder sonstige Nutzung eine 
                        Entlohnung erhalten; <br/>
                        <b>Dienstanbieter:</b> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland; <br/>
                        <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Website: <a href="https://marketingplatform.google.com">https://marketingplatform.google.com</a>; <br/>
                        <b>Datenschutzerklärung:</b> <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>; <br/>
                        Weitere Informationen: Types of processing and data processed:<a href="https://privacy.google.com/businesses/adsservices">https://privacy.google.com/businesses/adsservices</a>;
                        Google Ads Controller-Controller Data Protection Terms and standard contractual clauses for data transfers to third countries: 
                        <a href="https://business.safety.google/adscontrollerterms">https://business.safety.google/adscontrollerterms</a>.
                    </li>
                </ul>

                <h2 id='16'>Änderung und Aktualisierung der Datenschutzerklärung</h2>
                Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, 
                sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen 
                eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.<br/><br/>
                
                Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu beachten, 
                dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
                
                <h2 id='17'>Rechte der betroffenen Personen</h2>
                Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
                <ul>
                    <li>
                        <b>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die 
                        Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; 
                        dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung 
                        zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung 
                        einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</b>
                    </li>
                    <li><b>Widerrufsrecht bei Einwilligungen:</b> Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.</li>
                    <li>
                        <b>Auskunftsrecht:</b> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf 
                        uskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
                    </li>
                    <li>
                        <b>Recht auf Berichtigung:</b> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten 
                        oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
                    </li>
                    <li>
                        <b>Recht auf Löschung und Einschränkung der Verarbeitung:</b> Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, 
                        dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung 
                        der Daten zu verlangen.
                    </li>
                    <li>
                        <b>Recht auf Datenübertragbarkeit:</b> Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe 
                        der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen 
                        anderen Verantwortlichen zu fordern.
                    </li>
                    <li>
                        <b>Beschwerde bei Aufsichtsbehörde:</b> Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs 
                        das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder 
                        des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben 
                        der DSGVO verstößt.
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Datenschutz