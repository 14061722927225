import React from 'react'
import './eventStatistics.css'
import { Chart } from "react-google-charts";
import loginToken from '../functionality/loginToken'
import { replacePaymentNames, replaceDateNames, toSalesPerDay,toNormalDateAndTime } from '../functionality/normalizer';

//siteComponents
function Grid(props){
    return(
        <>
        <div className='statPane'>
            <h4 className='statMarLeft'>Verkäufe je Tag</h4>
            <SalesPerDay data={props.data[2]}/>
        </div>
        <div className='statPane'>
            <h4 className='statMarLeft'>Relative Verkäufe je Veranstaltung</h4>
            <SalesPerPres data={props.data[3]}/>
        </div>
        <div className='statPane'>
            <h4 className='statMarLeft'>Genutzte Zahlungsarten</h4>
            <PieChart data={props.data[0]} type='usedPaymentMethods'/>
        </div>
        <div className='statPane'>
            <h4 className='statMarLeft'>Umsätze je Veranstaltung (inkl. Gebühren)</h4>
            <PieChart data={props.data[1]} type='salesPerPres'/>
        </div>
        </>
    )
}

function PieChart(props){
    if(props.type==='usedPaymentMethods'){ 
        var data=[['Zahlungsart','Anzahl']]
        if(props.data){
            for (var i=0; i<props.data.length; i+=1){
                data[i+1]= replacePaymentNames(props.data[i])
            }
            var options = {}
        } else {
            data=[['Zahlungsart','Anzahl'],['PayPal',1],['Bar an der Abendkasse',1],['Überweisung',1]]
        }
    }
    else if (props.type==='salesPerPres'){ 
        var data=[['Datum','Umsatz']]
        if(props.data){
            for (var j=0; j<props.data.length; j+=1){
                data[j+1]= replaceDateNames(props.data[j][0])
            }
        } else {
            data=[['Datum','Umsatz'],['1.2.2023, 19:23 Uhr',1]]
        }

        var options = {
            pieSliceText: "value",
        }
    }

    return(
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width={"100%"}
            height={"400px"}
        />
    )
}

function SalesPerDay(props){
    var data=[['Datum','Verkaufte Tickets']]
    if(props.data){
        for (var i=0; i<props.data.length; i+=1){
            data[i+1]= toSalesPerDay(props.data[i])
        }
    } else {
        data = [['Datum','Verkaufte Tickets'], ['lade Daten', 1]]
    }

    var options = {
        animation:{
            duration: 250,
            easing: 'inAndOut',
            "startup": true
        },
    };

    return(
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    )

}

function SalesPerPres(props){

    var data=[['Datum/Preisklasse','Verkaufte Tickets in %']]
    if(props.data){
        for (var i=0; i<props.data.length; i+=1){
            data[i+1]= convertData(props.data[i])
        }
    } else {
        data = [['Datum/Preisklasse','Verkaufte Tickets'], ['lade Daten', 1]]
    }

    var options={
        vAxis: {minValue:100},
        animation:{
            duration: 250,
            easing: 'inAndOut',
            "startup": true
        },
        }

    return (
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      );
}

//functions
function convertData(data){
    return[(toNormalDateAndTime(data[0])+' - '+data[1]), Number(data[2])]
}

//class definition
class EventStatistics extends React.Component{
    constructor(){
        super()
        this.state = {
            data: "",
        }
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_HOSTINSIGHTS_API+process.env.REACT_APP_STAGE+'/getstatistics', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                eid: this.props.eid
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            var obj = JSON.parse(text);
            this.setState({
                data: obj
            })
        })
    }

    render(){
        return(
            <div className='eventStatisticsBackground'>
                <center><h3>{this.props.title}</h3></center>
                <div className='statRelPos'>
                    <div className='statAbsPos' onClick={() => this.props.toggleStatistics()}>
                        <span className='icon iconWarningCol closeIcon'>Schließen</span>
                    </div>
                </div>
                <div className='statisticsGridWrapper'>
                    <div className='statisticsGrid'>
                        <Grid data={this.state.data}/>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default EventStatistics