import React from "react"
import loginToken from '../functionality/loginToken'
import { useSearchParams } from 'react-router-dom';
import VEventBox from "../viewEvent_components/Box";
import TicketDesign from "../viewEvent_components/TicketDesign";
import {EventPresAdd, PresNPriceMapper} from "../viewEvent_components/PresNPrice";
import './ViewEvent.css'
import LoadingAnimation from "../loading_components/LoadingAnimation";

//starting function
function ViewEvent(){
    const [searchParams] = useSearchParams();
    return <EventViewerWrapper event={searchParams.get("eid")}/>
}

//class definition
class EventViewer extends React.Component{
    constructor(){
        super()
        this.state = {
            data: "",
        }
        this.changeKeyProp = this.changeKeyProp.bind(this)
    }

    changeKeyProp(){
        this.props.updateKey()
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/getevent', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                eid: this.props.event
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            var obj = JSON.parse(text);
            this.setState({
                data: obj
            })
        })
    }

    render(){
        if(this.state.data==='') return <LoadingAnimation/>
        return(
            <div className="content">
                <VEventBox baseData={this.state.data[0]}/>
                <h3>Veranstaltungen: </h3>
                <div className="parallel justifySpace">
                    <PresNPriceMapper fees={this.state.data[4]} reloadState={this.changeKeyProp} presData={this.state.data[1]} pricData={this.state.data[2]} paidAvailable={this.state.data[3]} />
                    <EventPresAdd reloadState={this.changeKeyProp} eid={this.props.event} presData={this.state.data[1][0]}/>
                </div>
                <TicketDesign baseData={this.state.data[0][0]} presData={this.state.data[1][0]} price={(this.state.data[2][0])?this.state.data[2][0][3]:'5.00'} />
            </div>
        )
    }
}

class EventViewerWrapper extends React.Component{
    constructor(){
        super()
        this.state = {
            key: 0
        }
        this.updateKeyProp = this.updateKeyProp.bind(this)
    }

    updateKeyProp(){
        this.setState({
            key: this.state.key+1,
        })
    }

    render(){
        return <EventViewer event={this.props.event} key={this.state.key} updateKey={this.updateKeyProp}/>
    }
}

export default ViewEvent