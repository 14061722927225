import React from "react";

//starting function
class LoadingAnimation extends React.Component{
    constructor(props){
        super();
        this.state = {
            errVisible: false,
            ran: false,
            waitText: '',
        }
        this.loadingTimer = this.loadingTimer.bind(this)
    }

    async loadingTimer(){
        this.setState({
            ran: true,
        })
        await sleep(3000);
        this.setState({
            waitText: 'Das dauert länger als üblich.',
        })
        await sleep(7000);
        this.setState({
            errVisible: true,
            waitText: '',
        })
        return
    }   

    render(){
        if (!this.state.ran) this.loadingTimer()
        return(
            <div className="centered fullHeight">
                <div className='loaderContainer'>
                    <div className='circle-loader'/>
                </div>
                <center>
                    <div  className="fullCenter loaderText">
                        <span id='loaderText'>{this.state.waitText}</span>
                        <span id='errorText' className={(this.state.errVisible)?"errorVisible":"errorInvisible"}>
                            Wahrscheinlich ist ein Fehler aufgetreten.<br/>
                            Bitte gehe auf die letzte Seite zurück.
                        </span>
                    </div>
                </center>
            </div>
        )
    }
}

function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export default LoadingAnimation