import './Register.css'
import React from 'react'

//starting function
function Register(){
    return(
        <div className='content registerPane'>
            <h1>Registrieren</h1>
            <LinkToHost/>
            <div className='registerContainer'>
                <RegForm/>
            </div>
        </div>
    )
}

//site components
function LinkToHost(){
    return(
        <a href='/hostRegister'>
            <div className='btnClass3'>
                stattdessen als Veranstalter regisieren
            </div>
        </a>
    )
}

//class definitions
class RegForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            email2: '',
            street: '',
            number: '',
            postal: '',
            city: '',
            pw: '',
            pw2:''
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkPwCompletion = this.checkPwCompletion.bind(this);
        this.checkMailCompletion = this.checkMailCompletion.bind(this);
        this.checkPW = this.checkPW.bind(this);
        this.checkEMail = this.checkEMail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    checkEMail(){
        var elemnt = document.getElementById('mailErr')
        var elemnt2 = document.getElementById('mails')
        if (this.state.email !== this.state.email2){
            elemnt.classList.add("errorVisible")
            elemnt.classList.remove("errorInvisible")
            elemnt2.style.color = 'red'
            return false
        } else {
            elemnt.classList.remove("errorVisible")
            elemnt.classList.add("errorInvisible")
            elemnt2.style.color = ''
            return true
        }
    }

    checkMailCompletion(){
        if(this.state.email !== ''){
            const re = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,}')
            var elemnt = document.getElementById('invalidMail')
            var elemnt2 = document.getElementById('mails')
            if (!re.test(this.state.email)){
                elemnt.classList.add("errorVisible")
                elemnt.classList.remove("errorInvisible")
                elemnt2.style.color = 'red'
                return false
            } else {
                elemnt.classList.remove("errorVisible")
                elemnt.classList.add("errorInvisible")
                elemnt2.style.color = ''
                return true
            }
        } else {
            return false
        }
    }

    checkPW(){
        var elemnt = document.getElementById('pwErr')
        var elemnt2 = document.getElementById('pws')
        if (this.state.pw !== this.state.pw2){
            elemnt.classList.add("errorVisible")
            elemnt.classList.remove("errorInvisible")
            elemnt2.style.color = 'red'
            return false
        } else {
            elemnt.classList.remove("errorVisible")
            elemnt.classList.add("errorInvisible")
            elemnt2.style.color = ''
            return true
        }
    }

    checkPwCompletion(){
        if (this.state.pw !== ''){
            const re = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*'§/=+-?_:.,<>])(?=.{8,})")
            var elemnt = document.getElementById('PwRequirements')
            var elemnt2 = document.getElementById('pws')
            if (!re.test(this.state.pw)){
                elemnt.classList.add("errorVisible")
                elemnt.classList.remove("errorInvisible")
                elemnt2.style.color = 'red'
                return false
            } else {
                elemnt.classList.remove("errorVisible")
                elemnt.classList.add("errorInvisible")
                elemnt2.style.color = ''
                return true
            }    
        } else {
            return false
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });

    }

    async handleSubmit(event) {
        event.preventDefault();
        if (this.checkPwCompletion() && this.checkMailCompletion() && this.checkEMail() && this.checkPW() && this.state.firstName!=='' && this.state.lastName!=='' && this.state.street!=='' && this.state.number!=='' && this.state.postal!=='' && this.state.city!==''){
            try{
                await fetch(process.env.REACT_APP_REGISTRATION_API+process.env.REACT_APP_STAGE+'/register', {
                    method: 'POST',
                    body: JSON.stringify({
                        fname: this.state.firstName,
                        lname: this.state.lastName,
                        mail: this.state.email,
                        checkmail: this.state.email2,
                        street: this.state.street,
                        num: this.state.number,
                        plz: this.state.postal,
                        city: this.state.city,
                        pw: this.state.pw,
                        checkpw: this.state.pw2
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then (resp => resp.text())
                .then(text => {
                    var element = document.getElementById('regError')
                    console.log(text)
                    if (text !== "True"){
                        element.textContent = text
                        element.classList.add('errorVisible')
                        element.classList.remove('errorInvisible')
                    } else {
                        element.classList.add('errorInvisible')
                        element.classList.remove('errorVisible')
                        document.location.replace('/regSuccess')
                    }
                })
                
                event.preventDefault();
            } catch (err){
                var element = document.getElementById('error')
                element.classList.add('errorVisible')
                element.classList.remove('errorInvisible')
            }
        } else {
            event.preventDefault();
        }
        event.preventDefault();
    }

    render (){
        return(
        <form onSubmit={this.handleSubmit}>
            Vorname<br/> <input className='inputText' type="text" name="firstName" value={this.state.value} onChange={this.handleInputChange}></input><br/> <br/> 
            Nachname<br/> <input className='inputText' type="text" name="lastName" value={this.state.value} onChange={this.handleInputChange}></input><br/> <br/> 
            <div id='mails'>
                E-Mail <br/><input className='inputText' name="email" type="text" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkMailCompletion}></input><br/><br/> 
                <p id='invalidMail' className='errorInvisible'>Die E-Mail ist nicht gültig!</p>
                E-Mail bestätigen <br/><input className='inputText' type="text" name="email2" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkEMail}></input>
                <p id='mailErr' className='errorInvisible'>Die E-Mails müssen übereinstimmen!</p>
                <br/><br/>
            </div>
            <div>
                Straße/Hausnummer 
                <div className='multiInputperLine'>
                    <input className='inputText' type="text" name="street" value={this.state.value} onChange={this.handleInputChange}/>
                    <input className='inputText number' type="text" name="number" value={this.state.value} onChange={this.handleInputChange}/>
                </div><br/>
                PLZ/Stadt 
                <div className='multiInputperLine'>
                    <input className='inputText plz' type="text" name="postal" value={this.state.value} onChange={this.handleInputChange}/> 
                    <input className='inputText' type="text" name="city" value={this.state.value} onChange={this.handleInputChange}/>
                </div><br/>
            </div>
            <div id='pws' className='pwContainer'>
                Passwort <br/><input className='inputText pw1' name="pw" type="password" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkPwCompletion}></input>
                <div className="requirementsPopUp">Passwörter müssen mindestens folgende Anforderungen erfüllen:
                    <ul>
                        <li>min. 8 Zeichen</li>
                        <li>min. 1 Großbuchstabe</li>
                        <li>min. 1 Kleinbuchstabe</li>
                        <li>min. 1 Zahl</li>
                        <li>min. 1 Sonderzeichen</li>
                    </ul>
                </div>
                <p id='PwRequirements' className='errorInvisible'>Das Passwort erfüllt nicht die Anforderungen!</p>
                <br/><br/> 
                Passwort bestätigen <br/><input className='inputText' name="pw2" type="password" value={this.state.value} onChange={this.handleInputChange} onBlur={this.checkPW}></input>
                <p id='pwErr' className='errorInvisible'>Die Passwörter müssen übereinstimmen!</p>
                <br/><br/><br/>
            </div>
            <p id='regError' className='errorInvisible'>Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut!</p>
            <button type='submit' className='btnClass1'>Account jetzt erstellen</button>
        </form>
        )
    }
}

export default Register