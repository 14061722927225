import React from "react"
import loginToken from '../functionality/loginToken'
import { toNormalDateAndTime, convertToTime, convertToDate, convertToGMTDate } from '../functionality/normalizer';
import { EventPriceAdd, PricData } from "./Price";
import LoadingAnimation from "../loading_components/LoadingAnimation";
import { compareDate } from "../functionality/normalizer";

//variables
var date = new Date()
date.setDate(date.getDate() + 1);
var now = date.toISOString().split("T")[0];

//site components
const Plans = ({plans, setPlanFromDB}) => (
    <>
    {plans.map(plans => (
        <div key={plans[0]} onClick={() => setPlanFromDB(plans[0], plans[1])}>
            <img src={plans[1]} className='nanoPrevPic' alt="Vorschaubild"/>
        </div>
    ))}
    </>
)

const PresNPriceMapper = ({presData, pricData, reloadState, paidAvailable, fees}) =>(
    <>
    {presData.map(presData => (
        <div key={presData[0]}>
            <VEventPresNPrice fees={fees} reloadState={reloadState} presData={presData} pricData={pricData} paidAvailable={paidAvailable}/>
        </div>
    ))}
    </>
)

function DeleteBtn(props){
    var newDate = new Date()
    return(
        <>
        {(compareDate(props.data.startDayTime,newDate))?
        (props.data.deletion)?<DeleteConfirmation toggleDeletion={props.toggleDeletion} delete={props.delete} />:<button className="btnClass3 normalPropotion smallText errorVisible" onClick={() => props.toggleDeletion()}>Veranstaltung löschen</button>
        :<span className="smallText errorVisible">Diese Veranstaltung liegt in der Vergangenheit<br/> und kann nicht mehr geändert werden.</span>}</>
    )
}

function DeleteConfirmation(props){
    return(
        <div className="smallText">
            Wirklich unwiederruflich löschen?<br/>
            Alle verkauften Tickets werden automatisch erstattet.<span className="icon iconAccentCol smallicon infoIcon">Infos</span>
            <div className="helpText">
                <span className="smallText">
                    Kund*innen wird der volle Preis erstattet.<br/><br/>
                    Kosten Tickets Geld werden Ihnen, dem/der Veranstaltenden,<br/> Gebühren gemäß den AGBs berechnet.
                </span><br/>
            </div>
            <br/>
            <div className="parallel">
                <button className="btnClass3 smallText errorVisible secColOutline" onClick={() => props.delete()}>Ja</button>
                <button className="btnClass3 smallText secColOutline" onClick={() => props.toggleDeletion()}>Nein</button>
            </div>
        </div>
    )
}

function EventPresForm(props){
    if(!props.data.editable){
        return <button className="addEventPresButton margTop"><div className="button-viewEventBG" onClick={() => props.edit()}/><div className="plusStyle fullCenter">+</div></button>
    }else{
        return(
            <VEventPresNPriceViewer data={props.data} alterData={props.alterData} toggle={props.edit} commit={props.commit}/>
        )
    }
}

function PlanAdd(props){
    return(
        (props.pid==null)?'':
        <div>
            {(props.planEditable)?<PlanAdder deletePlan={props.deletePlan} setPlanFromDB={props.setPlanFromDB} plans={props.plans} chooserVisible={props.chooserVisible} loadPlans={props.loadPlans} plan={props.plan} setPlan={props.setPlan} planAddress={props.planAddress}/>:''}
            <br/>
            <button className="btnClass2 normalPropotions" onClick={() => {props.togglePlanEditable(); if(props.planEditable) props.savePlan()}}> {(props.planEditable)?'Speichern':(props.type==='new')?'Plan hinzufügen':'Plan ändern'} </button>
        </div>
    )
}

function PlanAdder(props){
    return(
        <div className="relPos">
            <button className="btnClass3 smallBtn" onClick={() => props.loadPlans()}>Vorhandenen Plan auswählen</button> oder <br/>
            <input className='pictureInput' type='file' accept='image/*' onChange={(e) => props.setPlan(e)}/><br/>
            <img src={props.plan} className="littlePrevPic" alt="Vorschaubild"/><br/>

            {(props.plan!==null)?<button className="btnClass3 smallBtn" onClick={() => props.deletePlan()}>Plan entfernen</button>:''}

            {(props.chooserVisible)?<PlanChooser setPlanFromDB={props.setPlanFromDB} plans={props.plans}/>:''}
        </div>
    )
}

function PlanChooser(props){
    return(
        <div className="absPos vEvAbsChooser">
            <Plans plans={props.plans} setPlanFromDB={props.setPlanFromDB} />
        </div>
    )
}

function PlanInfo(props){
    return(
        <>
        <div className="smallText">
            Sie haben noch keinen Lageplan hinterlegt<br/>
            Wir empfehlen einen Lageplan zu hinterlegen,<br/> 
            damit dich Ihre Gäste besser orientieren können.
        </div>
        <PlanAdd deletePlan={props.deletePlan} setPlanFromDB={props.setPlanFromDB} plans={props.plans} chooserVisible={props.chooserVisible} loadPlans={props.loadPlans} savePlan={props.savePlan} plan={props.plan} setPlan={props.setPlan} pid={props.pid} togglePlanEditable={props.togglePlanEditable} planEditable={props.planEditable} planAddress={props.planAddress} type='new'/>
        </>
    )
}

function PlanViewer(props){
    return(
        <>
            {(props.planEditable)?'':<img src={props.plan} className='microPrevPic' alt="Vorschaubild"/>}
            <PlanAdd deletePlan={props.deletePlan} setPlanFromDB={props.setPlanFromDB} plans={props.plans} chooserVisible={props.chooserVisible} loadPlans={props.loadPlans} savePlan={props.savePlan} plan={props.plan} setPlan={props.setPlan} pid={props.pid} togglePlanEditable={props.togglePlanEditable} planEditable={props.planEditable} planAddress={props.planAddress} type='change'/>
        </>
    )
}

function PresDatEdit(props){
    return(
        <>
           <input defaultValue={props.data.startDay} min={now} type='date' className="vEvInp" onChange={(e) => props.alterData(e.target.value, 'startDay')}/> um <input defaultValue={props.data.startTime} type='time' className="vEvInp" onChange={(e) => props.alterData(e.target.value, 'startTime')}/><br/>
           <input defaultValue={props.data.endDay} min={now} type='date' className="vEvInp" onChange={(e) => props.alterData(e.target.value, 'endDay')}/> um <input defaultValue={props.data.endTime} type='time' className="vEvInp" onChange={(e) => props.alterData(e.target.value, 'endTime')}/><br/>
           <input placeholder="Musterstraße" type='text' className="vEvInp" defaultValue={props.data.street} onChange={(e) => props.alterData(e.target.value, 'street')}/> <input placeholder="1" type='number' className="vEvInp numSmall" defaultValue={props.data.nr} step="1" min='1' onChange={(e) => props.alterData(e.target.value, 'nr')}/> <input placeholder="12345" type='number' step="1" min='1' className="vEvInp number" defaultValue={props.data.plz} onChange={(e) => props.alterData(e.target.value, 'plz')}/> <input placeholder="Musterort" type='text' className="vEvInp" defaultValue={props.data.city} onChange={(e) => props.alterData(e.target.value, 'city')}/><br/>
           <input defaultValue={props.data.saleStartDay} type='date' className="vEvInp" onChange={(e) => props.alterData(e.target.value, 'saleStartDay')}/> um <input defaultValue={props.data.saleStartTime} type='time' className="vEvInp" onChange={(e) => props.alterData(e.target.value, 'saleStartTime')}/><br/>
        </>
    )
}

function PresDatView(props){
    return(
        <>
        {toNormalDateAndTime(convertToGMTDate(props.data.startDay, props.data.startTime))}<br/>
        {toNormalDateAndTime(convertToGMTDate(props.data.endDay, props.data.endTime))}<br/>
        {props.data.street+' '+props.data.nr+', '+props.data.plz+' '+props.data.city}<br/>
        {toNormalDateAndTime(convertToGMTDate(props.data.saleStartDay, props.data.saleStartTime))}
        </>
    )
}

function VEventPresNPriceViewer(props){
    var temp = []
    for (var i = 0; i< props.data.pricData.length; i+=1){
        if (String(props.data.pricData[i][0]) === String(props.data.pid)) temp[temp.length] = props.data.pricData[i]  
    }
    return(
        <div className="secColOutline margTop">
            <div className="parallel vEventMarg">
                <div>
                    <div className="parallel">
                        <div className={(props.data.editable)?'minTwoEm vEventMarg':'vEventMarg'}>
                            Startzeit:<br/>
                            Endzeit:<br/>
                            Adresse:<br/>
                            Vorverkaufsstart:<br/>
                        </div>
                        <div className="vEventMarg">
                            {(props.data.editable)?<PresDatEdit data={props.data} alterData={props.alterData}/>:<PresDatView data={props.data}/>}
                        </div>
                    </div>
                    <center>
                    <button className="btnClass2 normalPropotions" onClick={() => {props.toggle(); if(props.data.editable){props.commit()}}}>{(props.data.editable)?'Speichern':'Bearbeiten'}</button>
                        {(props.data.alreadySaved)?
                        <DeleteBtn data={props.data}toggleDeletion={props.toggleDeletion} delete={props.delete}/>:<button className="btnClass3 normalPropotion smallText" onClick={() => props.toggle()}>Abbrechen</button>}
                    </center>
                    {(props.presData === undefined)?'':
                    <div>
                        <br/><b>Lageplan</b><br/><br/>
                        {(props.data.presData[8] === null)? <PlanInfo deletePlan={props.deletePlan} setPlanFromDB={props.setPlanFromDB} plans={props.plans} chooserVisible={props.chooserVisible} loadPlans={props.loadPlans} savePlan={props.savePlan} setPlan={props.setPlan} plan={props.plan} pid={props.data.pid} togglePlanEditable={props.togglePlanEditable} planEditable={props.data.planEditable} planAddress={props.data.presData[10]}/> : <PlanViewer deletePlan={props.deletePlan} setPlanFromDB={props.setPlanFromDB} plans={props.plans} chooserVisible={props.chooserVisible} loadPlans={props.loadPlans} savePlan={props.savePlan} plan={props.plan} setPlan={props.setPlan} pid={props.data.pid} togglePlanEditable={props.togglePlanEditable} planEditable={props.data.planEditable} planAddress={props.data.presData[10]} />}
                    </div>
                    }
                </div>
                <div>
                    <PricData fees={props.fees} reloadState={props.reloadState} data={temp} paidAvailable={props.paidAvailable}/>
                    {(props.data.alreadySaved)?<EventPriceAdd fees={props.fees} reloadState={props.reloadState} newClassNum={temp.length} pid={props.data.pid} paidAvailable={props.paidAvailable}/>:<></>}
                </div>
            </div>
        </div>
    )
}

//class definitions
class EventPresAdd extends React.Component{
    constructor(props){
        super()
        this.state = {
            editable: false,
            eid: props.eid,
            startDay: '',
            startTime: '',
            endDay: '',
            endTime: '',
            street: '',
            nr: '',
            plz: '',
            city: '',
            saleStartDay: '',
            saleStartTime: '',
            pricData:[[-1]],
            alreadySaved: false,
        }
        this.alterData = this.alterData.bind(this)
        this.commitChanges = this.commitChanges.bind(this)
        this.toggleViewerEdit = this.toggleViewerEdit.bind(this)
    }

    alterData(e,tar){
        this.setState({
            [tar]: e,
            changesMade: true,
        })
    }

    commitChanges(){
        if (!this.state.changesMade | this.state.street===''| this.state.nr===''| this.state.plz===''| this.state.city==='' | this.state.startDay==='' | this.state.startTime==='' | this.state.endDay==='' | this.state.endTime==='' | this.state.saleStartDay==='' | this.state.saleStartTime==='') return
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/presentationtoexistingevent', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                eid: this.state.eid,
                start: convertToGMTDate(this.state.startDay, this.state.startTime),
                end: convertToGMTDate(this.state.endDay, this.state.endTime),
                saleStart: convertToGMTDate(this.state.saleStartDay, this.state.saleStartTime),
                address:{
                    street: this.state.street,
                    number: this.state.nr,
                    postal: this.state.plz,
                    city: this.state.city
                }
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {
            this.setState({
                changesMade: false
            })
        })
        .then(() => {this.props.reloadState()})
    }

    toggleViewerEdit(){
        this.setState({
            editable: !this.state.editable
        })
    }

    render(){
        return <EventPresForm data={this.state} edit={this.toggleViewerEdit} alterData={this.alterData} commit={this.commitChanges}/>
    }
}

class VEventPresNPrice extends React.Component{
    constructor(props){
        super()
        this.state = {
            presData: props.presData,
            pricData: props.pricData,
            editable: false,
            pid: props.presData[0],
            
            startDay: convertToDate(props.presData[1]),
            startTime: convertToTime(props.presData[1]),
            endDay: convertToDate(props.presData[2]),
            endTime: convertToTime(props.presData[2]),
            street: props.presData[3],
            nr: props.presData[4],
            plz: props.presData[5],
            city: props.presData[6],
            saleStartDay: convertToDate(props.presData[7]),
            saleStartTime: convertToTime(props.presData[7]),
            changesMade: false,
            alreadySaved: true,
            deletion: false,
            planEditable: false,
            plan: props.presData[9],
            sid: props.presData[8],
            planChanged: false,
            chooserVisible: false,
            plans: [],
            startDayTime: props.presData[1],
        }
        this.alterData = this.alterData.bind(this)
        this.commitChanges = this.commitChanges.bind(this)
        this.delete = this.delete.bind(this)
        this.deletePlan = this.deletePlan.bind(this)
        this.loadPlans = this.loadPlans.bind(this)
        this.savePlan = this.savePlan.bind(this)
        this.setPlan = this.setPlan.bind(this)
        this.setPlanFromDB = this.setPlanFromDB.bind(this)
        this.toggleDeletion = this.toggleDeletion.bind(this)
        this.togglePlanEditable = this.togglePlanEditable.bind(this)
        this.toggleViewerEdit = this.toggleViewerEdit.bind(this)
    }

    alterData(e,tar){
        this.setState({
            [tar]: e,
            changesMade: true,
        })
    }

    commitChanges(){
        if (!this.state.changesMade) return
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/updateeventpresentationdata', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                PID: this.state.pid,
                start: convertToGMTDate(this.state.startDay, this.state.startTime),
                end: convertToGMTDate(this.state.endDay, this.state.endTime),
                saleStart: convertToGMTDate(this.state.saleStartDay, this.state.saleStartTime),
                address:{
                    street: this.state.street,
                    number: this.state.nr,
                    postal: this.state.plz,
                    city: this.state.city
                }
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
       .then(() => {
            this.setState({
                changesMade: false
            })
        })
        .then(() => {this.props.reloadState()})
    }

    delete(){
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/deleteeventpresentation', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                PID: this.state.pid,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {this.props.reloadState()})
    }

    deletePlan(){
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/removeplan', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                PID: this.state.pid,
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {
            this.setState({
                plan: null,
                sid: null,
                planChanged: false,
            })
        })
    }

    loadPlans(){
        if(this.state.plans.length === 0){
            fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/getplans', {
                method: 'POST',
                body: JSON.stringify({
                    key: loginToken.GetToken(),
                    AID: this.state.presData[10],
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then (resp => resp.text())
            .then (text =>{
                var obj = JSON.parse(text);
                this.setState({
                    plans: obj
                })
            })
        }

        this.setState({
            chooserVisible: !this.state.chooserVisible
        })
    }

    savePlan(){
        if(!this.state.planChanged) return
        fetch(process.env.REACT_APP_EVENTMASTER_API+process.env.REACT_APP_STAGE+'/updatepresentationplan', {
            method: 'POST',
            body: JSON.stringify({
                key:loginToken.GetToken(),
                AID: this.state.presData[10],
                PID: this.state.pid,
                SID: this.state.sid,
                plan: this.state.plan
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {
            this.setState({
                planChanged: false,
            })
        })
    }

    setPlan(e){
        const file = e.target.files[0]
        var maxSize = 3145728
        //max 3 MB
        if(file.size > maxSize){
            alert("Pläne dürfen maximal 3 Megabyte groß sein")
            return
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            // convert image file to base64 string
            this.setState({
                plan: reader.result,
                planChanged: true,
                sid: null,
            }) 
        }, false);
        
        if (file) {
            reader.readAsDataURL(file)
        }
        return true   
    }

    setPlanFromDB(sid, plan){
        if(sid=== this.state.sid) return

        this.setState({
            plan: plan,
            planChanged: true,
            sid: sid,
            chooserVisible: false,
        }) 
    }

    toggleDeletion(){
        this.setState({
            deletion: !this.state.deletion
        })
    }

    togglePlanEditable(){
        this.setState({
            planEditable: !this.state.planEditable
        })
    }

    toggleViewerEdit(){
        this.setState({
            editable: !this.state.editable
        })
    }

    render(){
        return <VEventPresNPriceViewer deletePlan={this.deletePlan} setPlanFromDB={this.setPlanFromDB} plans={this.state.plans} chooserVisible={this.state.chooserVisible} loadPlans={this.loadPlans} savePlan={this.savePlan} setPlan={this.setPlan} plan={this.state.plan} togglePlanEditable={this.togglePlanEditable} fees={this.props.fees} paidAvailable={this.props.paidAvailable} reloadState={this.props.reloadState} delete={this.delete} toggleDeletion={this.toggleDeletion} data={this.state} alterData={this.alterData} commit={this.commitChanges} toggle={this.toggleViewerEdit}/>
    }
}

export {PresNPriceMapper, EventPresAdd}