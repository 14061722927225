import './event.css'
import './allEvents.css'
import React, { useRef, useEffect } from "react"
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useSearchParams } from 'react-router-dom';
import loginToken from "../functionality/loginToken";
import { getDuration } from '../functionality/duration';
import { toNormalDateAndTime, toNormalNumber, compareDate } from '../functionality/normalizer';
import TicketBuy from './TicketBuy'
import { getOrderCache, setOrderCache } from '../functionality/orderCache'
import Countdown from 'react-countdown';
import Ad from '../monetization/GoogleAds';
import LoadingAnimation from '../loading_components/LoadingAnimation';
//import AdSense from 'react-adsense';


//starting function
function Event() {
    const [searchParams] = useSearchParams();
    return(
        <div className='event_centered'>
            <div className='event_bg'>
                <div className='event_bg_gradient'/>
            </div>
            <div className="event_content centered" id='content'>
                <EventView eid={searchParams.get('eid')} pid={searchParams.get('pid')}/>
                <div className='event_whitespace_wrapper'>
                    <div className='event_whitespace'/>
                </div>
            </div>
        </div>
    )
}

//variables
var totalSum = 0
var ticketSum = 0

//functions
function adjustOrder(e, tID, price, max){
    var val = e.target.value
    val = checkValue(e, max)
    var element = document.getElementById('order_pcid_'+tID)
    var old = element.textContent
    element.textContent = val
    adjustSum((val-old)*price, (val-old))
}

function adjustSum(change, tickChange){
    totalSum += change
    ticketSum += tickChange
    document.getElementById('orderSum').textContent = toNormalNumber(totalSum)+' €'
    try {
        var elem = document.getElementById('buyBtn')
        if(ticketSum!==0){
            elem.style.opacity = 1
        } else {
            elem.style.opacity = '0.6'
        }
    } catch (error) {}
    
}

function checkValue(e, max){
    var pcs = e.target.value 
    if (max>20){max=20}
    if (pcs < 0) pcs = 0
    if (pcs > max){ 
        pcs = max
        displayMaxInformation()
    }
    e.target.value = Math.round(pcs)
    return pcs
}

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

function displayLoggedWarning(){
    warningToggler(document.getElementById('loggedWarning'))
}

function displayMaxInformation(){
    warningToggler(document.getElementById('ticketWarning'))
}

function getMinPrice(priceData, pid){
    var min = null
    for(var i = 0; i<priceData.length; i+=1){
        if((min===null | parseFloat(priceData[i][3])<parseFloat(min)) && pid===priceData[i][0]) min=priceData[i][3]
    }
    return min
}

function initiateBuy(pcData, payData, setOrderData){
    var data = []
    if(ticketSum===0) return
    data[0] = {
        sum: document.getElementById('orderSum').textContent,
        cash: payData[4],
        paypal: payData[5],
    }
    data[1]=[]
    for (var i = 0; i< pcData.length; i+=1){
        data[1][i] = {
            pcid: pcData[i][1],
            pcNum: pcData[i][2]+1,
            price: pcData[i][3],
            reqQua: document.getElementById('pcid_'+pcData[i][1]).value
        }
    }
    if (loginToken.GetToken()===null){
        displayLoggedWarning()
        return
    }

    setOrderCache(data)
    setOrderData()
    
    var elem = document.getElementById('ticketBuyOverlay')
    elem.style.zIndex = 100
    elem.style.opacity = 1
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}

function warningToggler(elem){
    elem.style.zIndex = 10
    elem.style.opacity = 1
    delay(5000)
    .then(() =>
    elem.style.opacity = 0)
    .then(() =>
    delay(300).then(() =>
    elem.style.zIndex = -3))
}

//site components
const PBuyInp = (props) =>(
    <div className=''>
        <input className='presentationInp eventNumber' defaultValue='0' min={0} type='number' id={'pcid_'+props.id} onBlur={(e) => adjustOrder(e, props.id, props.price, props.max)} onInput={(e) => adjustOrder(e, props.id, props.price, props.max)} onClick={(e) => adjustOrder(e, props.id, props.price, props.max)}/>
    </div>
)

const ThesePrices = ({i}) => (
    <>
    {i.map(i => (
        <div key={i[1]}>
            <h3 className='event_compensateH3'>{i[2]}</h3>
            <div className='pricC_parallel'>
                <div className='pricC_oneLine'>
                    Verfügbare Plätze: {i[4]-i[5]}<br/>
                    Preis: {toNormalNumber(i[3])+' €'}<br/>
                </div>
                <PBuyInp id={i[1]} max={i[4]-i[5]} price={i[3]}/>
            </div>
            <br/><br/>
        </div>
    ))}
    </>
)

const OrderElement = ({i}) =>(
    <>
    {i.map(i => (
        <div key={i[1]} className='pricC_parallel'>
            {i[2]}: 
            <span><span id={'order_pcid_'+i[1]}>0</span> * {toNormalNumber(i[3])+' €'}</span>
        </div>
    ))}
    </>
)

const  OtherPresentations = ({data,eid}) => (
    <>
    {data.map(data => (
        <>
        <a className='noUnderline allEventPane' key={data[0][0]} href={'/event?pid='+data[0][0]+'&eid='+eid}>
            <div className='eventPaddingAdjust'>
                <div className="centerHeight"><span className="icon calenderIcon">Datum,Uhrzeit </span> {toNormalDateAndTime(data[0][1])}</div><br/>
                <div className="centerHeight"><span className="icon timelapseIcon">Dauer </span> {getDuration(data[0][1],data[0][2])}</div><br/>
                <div className="centerHeight"><span className="icon pointIcon">Ort </span> {data[0][3]+' '+data[0][4]+', '+data[0][5]+' '+data[0][6]}</div><br/>
            </div>
            <div className='allEventPane_right'>
                <div className='allEventsCenterHeight'>
                    <span className='green'>Ab {toNormalNumber(getMinPrice(data[1], data[0][0]))} €</span>
                </div>
            </div>
        </a>
        <div className='spacer'/>
        </>
    ))}
    </>
)

function BuyBox(props){   
    return(
        <div className='secColOutline relPos'>
            <MaxInformation/>
            <LoggedIn/>
            <ThesePrices i={props.presData[1]}/>
            <OrderList pcids={props.presData[1]}/>
            {(compareDate(props.presData[0][2],props.sTime))?
                (compareDate(props.sTime, props.presData[0][7]))?<BuyBtn pcData={props.presData[1]} payData={props.payData} setOrderData={props.setOrderData}/>:<RemainingTime startTime={props.startTime}/>
                :<div className='bold secColOutline'>Dieses Event ist vorbei.<br/>Du kannst keine<br/> Karten mehr kaufen.</div>}
        </div>
    )
}

function BuyBtn(props){
    return(
        <div className='eventActionBtnSpace'>
            <center>
                <button id='buyBtn' className='btnClass1 normalPropotions oneLine largerText' style={{opacity: '0.6'}} onClick={() => initiateBuy(props.pcData, props.payData, props.setOrderData)}>Tickets kaufen</button>
            </center>
        </div>
    )
}

function Dist (props) {
    return (<div className="centerHeight"><span className="icon distanceIcon">Entfernung </span> {props.num} km</div>)
}

function GoogleAd(){
    /*
    return(
        <div>
            <Ad/>
        </div>
    )
    */
}

function InfoBox(props){
    return(
        <div>
            <PresInfo presData={props.presData[0]}/>
            <div className='spacer'/>
            <PamentInfo payData={props.payData}/>
            {(props.presData[0][9]===null)?'':
            <>
                <div className='spacer'/>
                <Plan plan={props.presData[0][9]}/>
            </>
            }

        </div>
    )
}

function LocMap(props){
    const mapContainer = useRef(null);

    mapboxgl.accessToken = 'pk.eyJ1IjoiYXZhenoiLCJhIjoiY2xkZDE5bXV0MGNlYjNwbzFrbDRybHpjeiJ9.49M1j-k0irNDDlgHu-rKPw';
    useEffect(() => {
        fetch(process.env.REACT_APP_ADDRESSHANDLER_API+process.env.REACT_APP_STAGE+'/getcoords', {
            method: 'POST',
            body: JSON.stringify({
                pid: props['presData'][0]
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var data = JSON.parse(text)
            const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [data[0][1], data[0][0]],
            zoom: 12
            });
            new mapboxgl.Marker().setLngLat([data[0][1], data[0][0]]).addTo(map)
        })
    });

    return(
        <div ref={mapContainer} className="map-container" />
    )
}

function MaxInformation(){
    return(
        <div className='eventWarning' id='ticketWarning'>
            Zum Schutz vor Missbrauch ist die maximale Anzahl an Tickets je 
            klasse pro Kauf auf maximal 20 beschränkt.
        </div>
    )
}

function LoggedIn(){
    return(
        <div className='eventWarning' id='loggedWarning'>
            Sie müssen angemeldet sein, um Tickets kaufen zu können!
        </div>
    )
}

function OrderList(props){
    return(
        <div>
            <h3 className='event_compensateH3'>Tickets</h3>
            <OrderElement i={props.pcids}/>
            <hr/>
            <div className='pricC_parallel'>
                Summe: <span id='orderSum'>0,00 €</span>
            </div>
        </div>
    )
}

function OtherPresentation(props){
    return(
        <div>
            <h2>Weitere Termine</h2>
            <div className='furtherEventContainer'>
                <OtherPresentations data={props.data} eid={props.eid}/>
            </div>
        </div>
    )
}

function PamentInfo(props){
    return(
        <div className='secColOutline'>
            <div className="centerHeight">Akzeptierte Zahlungsarten:</div><br/>
            <PaymentOption type='cash' bool={props.payData[4]}/>
            <PaymentOption type='paypal' bool={props.payData[5]}/>
        </div>
    )
}

function PaymentOption(props){
    var opt = ['cash', 'paypal']
    var text= ['Bar an der Abendkasse', 'PayPal']
    for (var i = 0; i<opt.length; i+=1){
        if(props.type === opt[i] && props.bool === 'True'){
            return(
                <>
                <div className="centerHeight eventSpaceLeft">{text[i]}</div><br/>
                </>
            )
        }
    }
}

function Plan(props){
    return(
        <div className='secColOutline'>
            <div className='higherLine'>Ortsplan:</div>
            <center>
                <img src={props.plan} alt='Lageplan' className='eventSitePlan'/>
            </center>
        </div>
    )
}

function PresInfo(props){
    return(
        <div className='secColOutline'>
            <div className="centerHeight"><span className="icon calenderIcon">Datum,Uhrzeit </span> {toNormalDateAndTime(props.presData[1])}</div><br/>
            <div className="centerHeight"><span className="icon timelapseIcon">Dauer </span> {getDuration(props.presData[1],props.presData[2])}</div><br/>
            <div className="centerHeight"><span className="icon pointIcon">Ort </span> {props.presData[3]+' '+props.presData[4]+', '+props.presData[5]+' '+props.presData[6]}</div><br/>
            {(props.presData.length!==12)?'':<Dist num={props.presData[props.presData.length-1].toFixed()}/>}
        </div>
    )
}

function RemainingTime(props){
    return(
        <div className='eventActionBtnSpace'>
            <center>
                <div className='btnClass1Dummy normalPropotions oneLine largerText'>
                    Vorverkauf startet in<br/>
                    <Countdown date={props.startTime} />
                </div>
            </center>
        </div>
    )
}

function ThisPresentation(props){
    return(
        <div className='pres_parallel'>
            <BuyBox startTime={props.startTime} sTime={props.sTime} presData={props.presData} payData={props.payData} setOrderData={props.setOrderData}/>
            <div className='spacer'/>
            <InfoBox presData={props.presData} payData={props.payData}/>
        </div>
    )
}

function Top(props){
    return(
        <div className='event_parallel'>
            <center>
                <img className="event_card event_parallel_space" src={props.data[3]} draggable='false' alt='Eventcoverbild'/>
            </center>
            <div className='event_centered'>
                <div className='event_centered eventFitWidthSec'>
                    <h1 className='eventMaxWidth'>{props.data[1]}</h1>
                    <div className='eventFitWidth eventMaxWidth'>
                        <p>{props.data[2]}</p>
                    </div>
                    <ThisPresentation startTime={props.startTime} sTime={props.sTime} presData={props.presData} payData={props.data} setOrderData={props.setOrderData}/>
                </div>
            </div>
        </div>
    )
}

//class definitions
class EventView extends React.Component{
    constructor(){
        super();
        this.state = {
            data: [],
            pclasses: [],
            pid: 0,
            eid: 0,
            ordData: []
        }
        this.setOrderData = this.setOrderData.bind(this)
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_EVENTGETTER_API+process.env.REACT_APP_STAGE+'/getevent', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken(),
                eid: this.props.eid
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then(text => {
            var data = JSON.parse(text)
            this.setState({
                data: data,
                pid: this.props.pid,
                eid: this.props.eid,
                pclasses: this.getCorrectPriceclasses(data),
                paid: data[3][0]
            });
        })
    }   

    getCorrectPresentation(){
        for (var i = 0; i<this.state.data[1].length; i+=1){ 
            if (String(this.state.data[1][i][0]) === this.state.pid) return [this.state.data[1][i], this.state.pclasses]
        }
    }

    getCorrectPriceclasses(data){
        var temp = []
        for (var i = 0; i<data[2].length; i+=1){
            if (String(data[2][i][0]) === this.props.pid) temp[temp.length]= data[2][i]
        }
        return temp
    }

    getOtherPresentations(){
        var temp = []
        for (var i = 0; i<this.state.data[1].length; i+=1){
            if (String(this.state.data[1][i][0]) !== this.state.pid) temp[temp.length]= [this.state.data[1][i], this.getOtherPriceclasses()]
        }
        return temp
    }

    getOtherPriceclasses(){
        var temp = []
        for (var i = 0; i<this.state.data[2].length; i+=1){
            if (this.state.data[2][i][0] !== this.state.pid) temp[temp.length]= this.state.data[2][i]
        }
        return temp
    }

    setOrderData(){
        var dat = getOrderCache()
        this.setState({
            ordData: dat
        })
    }
    
    render(){
        if(!this.state.data.length) return <LoadingAnimation/>
        return(
            <div className='event_wrapper'>
                {(this.state.paid===1)?'':<GoogleAd/>}
                {(this.getCorrectPresentation().length===0)? '': <Top data={this.state.data[0][0]} startTime={this.getCorrectPresentation()[0][7]} sTime={this.state.data[0][1]} presData={this.getCorrectPresentation()} setOrderData={this.setOrderData} />}
                {(this.getCorrectPresentation().length===0)? '': <LocMap presData={this.getCorrectPresentation()[0]} />}
                {(this.state.paid===1)?'':<GoogleAd/>}
                {(this.getOtherPresentations().length===0)?'': <OtherPresentation data={this.getOtherPresentations()} eid={this.state.eid}/>}
                {(compareDate(this.state.data[0][1], this.getCorrectPresentation()[0][7]))?<TicketBuy l={this.state.ordData.length} data={this.state.ordData}/>:''}
            </div>
        )
    }
}

export default Event
