//site components
function BottomBar(props){
    return(
        <div className='navBottom'>
            <NavElements type={props.type} LoadAddress={props.LoadAddress} LoadTickets={props.LoadTickets}/>
        </div>
    )
}

function NavBtn(props){
    return(
        <a href='*
        ' onClick={(e) => props.action(e, props.text)} className="silentLink">
            <div className="navItem" id={props.id}>
                {props.text}
            </div>
        </a>
    )
}

function NavBtn2(props){
    return(
        <a href='*
        ' onClick={(e) => props.action(e, props.type)} className="silentLink">
            <div className="navItem" id={props.id}>
                {props.text}
            </div>
        </a>
    )
}

function NavElements(props){
    return(
        <div className="navSlave">
            <NavBtn id = '' text="Anschrift" action={props.LoadAddress}/>
            <NavBtn2 id='allMyTickets' text={(props.type === 'uid')?"Meine Tickets":"Meine Events"} action={props.LoadTickets} type={props.type}/>
        </div>
    )
}

export default BottomBar