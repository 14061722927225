import React from "react"
import loginToken from "../functionality/loginToken"
import TypeSelector from "./paidRegister_components/TypeSelector"
import NameDateAndAddress from "./paidRegister_components/NameDateAndAddress"
import Bank from "./paidRegister_components/Bank"
import IDNumbers from "./paidRegister_components/IDNumbers"
import SendBtn from "./paidRegister_components/SendBtn"

//starting function
function PaidRegister(){
    return(
        <div className="content centered">
            <InfoBox/>
            <PRegisterController/>
        </div>
    )
}

//site component
function InfoBox(){
    return(
        <div className="secColOutline">
            Wir erheben im Laufe der Registrierung verschiedene Daten zur Umsetzung des PStTG sowie zur automatischen Überweisung Ihrer Einnahmen exkl. Plattformgebühren.<br/>
            Den genauen Verwendungszweck und weitere Erklärungen können Sie auf <span className="icon iconAccentCol smallicon infoIcon">Infos</span><div className="helpText">Weitere Erklärungen und Infotext</div> anzeigen lassen. 
        </div> 
    )
}

//class definition
class PRegisterController extends React.Component{
    constructor(){
        super()
        this.state={
            //relevant for PStTG
            type: '',
            name: '',
            personDateOfBirth: '',
            postal: '',
            city: '',
            street: '',
            number: '',
            bankOwnerNotName: false,
            bankOwnerName: '',
            bankOwnerDateOfBirth: '',
            bankOwnerPostal: '',
            bankOwnerCity: '',
            bankOwnerStreet: '',
            bankOwnerNumber: '',
            iban: '',
            bic: '',
            taxID: '',
            valAddedTaxID: '',
            compRegNumber: '',
            IDsValid: false,
        }
        this.updateValue = this.updateValue.bind(this)
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_PROFILEHANDLER_API+process.env.REACT_APP_STAGE+'/profile', {
            method: 'POST',
            body: JSON.stringify({
                key: loginToken.GetToken()
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then (resp => resp.text())
        .then (text =>{
            var obj = JSON.parse(text);
            this.setState({
                name: obj[1][1],
                postal: obj[1][3],
                city: obj[1][4],
                street: obj[1][5],
                number: obj[1][6],
            })
        })
    }

    updateValue(tar, val) {
        this.setState({
            [tar]:val,
        })
    }

    render(){
        return(
            <>
            <div className="spacer"/>
            <TypeSelector updateValue={this.updateValue} />
            <div className="spacer"/>
            <NameDateAndAddress type={this.state.type} data={this.state} updateValue={this.updateValue}/>
            {((this.state.type==='naturalPerson' && this.state.personDateOfBirth !== '') || (this.state.type !== 'naturalPerson' && this.state.type!== ''))?
                <>
                <div className="spacer"/>
                <Bank data={this.state} updateValue={this.updateValue}/>

                {((this.state.iban !== '' && this.state.bic !== '' && !this.state.bankOwnerNotName)||(this.state.iban !== '' && this.state.bic !== '' && this.state.bankOwnerNotName && this.state.bankOwnerCity !== '' && this.state.bankOwnerDateOfBirth !== '' && this.state.bankOwnerName !== '' && this.state.bankOwnerNumber !== '' && this.state.bankOwnerPostal !== '' && this.state.bankOwnerCity !== ''))?
                    <>
                    <div className="spacer"/>
                    <IDNumbers type={this.state.type} data={this.state} updateValue={this.updateValue}/>
                    {((this.state.type==='naturalPerson' && this.state.taxID!=='' && this.state.IDsValid) || (this.state.type!=='naturalPerson' && this.state.IDsValid &&  this.state.taxID!=='' && this.state.compRegNumber!==''))?
                        <>
                        <div className="spacer"/>
                        <SendBtn data={this.state}/>
                        </>
                        :'' 
                    }
                    </>
                    :''
                }
                </>
            :''}
            
            
            </>
        )
    }
}

export default PaidRegister